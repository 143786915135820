'use strict';
angular.module('kerp-forms.forms').factory('HB_CTR_FSM_FORM', [
  'fieldDefinitionService', '$state', '$stateParams', 'HB_CTR_FSM_CONDITIONS', 'dateUtils', 'schemaFormHelperService',
  '$injector', '$translate', 'FormUI', 'formModelService', 'htmlService',
  function (fieldDefinitionService, $state, $stateParams, conditions, dateUtils, schemaFormHelperService, $injector,
            $translate, FormUI, formModelService, htmlService) {

    var formUI = new FormUI();

    var form = [
      {
        type: "fieldset",
        condition: "model.page === 1",
        page: 1,
        title: "Tell us who you are",
        items: [
          {
            key: "applicant_title",
            type: "selectWithInput",
            title: "Title",
            radios: true,
            options: ["Mr", "Ms", "Mrs", "Miss", "Mx", "Other"],
            other: "Other",
            comment: "If other, please specify"
          },
          {
            key: "applicant_forename",
            title: "Forename(s)"
          },
          {
            key: "applicant_surname",
            title: "Surname"
          },
          {
            key: "applicant_othername",
            type: "radios",
            title: "Do you use, or have you ever used, any other names?"
          },
          {
            key: "applicant_othername_other",
            title: "Please specify",
            condition: conditions.toString('applicant_othername_other')
          },
          {
            key: "applicant_gender",
            title: "Are you Male or Female?",
            type: "select"
          },
          {
            key: "applicant_nationality",
            title: "What is your nationality?"
          },
          {
            key: "applicant_dob",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            title: "Date of birth",
            validationMessage: "The date must be in the format dd/mm/yyyy and the age must be between 16 and 120.",
            maxDate: dateUtils.getYearsBeforeNow(16),
            minDate: dateUtils.getYearsBeforeNow(120)
          },
          {
            key: "applicant_ni",
            title: "National insurance number",
            description: "<p>You can find this on payslips, letters about your benefits or letters from the tax office.</p><p>If you do not know your National Insurance Number you can continue with this claim but until you can provide proof of it your claim will not be processed.</p><p>If you do not have a National Insurance Number you can continue with this claim but you must make an application for a National Insurance Number and supply sufficient information or evidence to allow a National Insurance Number to be allocated. You can make an application by calling the Jobcentre Plus application line: 0345 600 0643.</p>"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 2",
        title: "Where can we get in touch with you?",
        page: 2,
        items: [
          {
            key: "applicant_daytel",
            type: "validationExternal",
            title: "Daytime telephone number",
            watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
            hasErrorFn: function (value, model) {
              if (!model.applicant_mob && !model.applicant_email && !value) {
                return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
              }
            }
          },
          {
            key: "applicant_mob",
            type: "validationExternal",
            title: "Mobile telephone number",
            watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
            hasErrorFn: function (value, model) {
              if (!model.applicant_daytel && !model.applicant_email && !value) {
                return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
              }
            }
          },
          {
            key: "applicant_email",
            type: "validationExternal",
            title: "Email address",
            watchFields: ["applicant_daytel", "applicant_mob", "applicant_email"],
            hasErrorFn: function (value, model) {
              if (!model.applicant_mob && !model.applicant_daytel && !value) {
                return 'One of "Daytime telephone number", "Mobile telephone number" and "Email address" is required';
              }
            }
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/applicantAddressLookup.html"
          },
          {
            key: "applicant_uprn",
            title: "Property reference",
            type: "hidden"
          },
          {
            key: "applicant_address1",
            title: "Address line 1",
            type: "hidden"
          },
          {
            key: "applicant_address2",
            title: "Address line 2",
            type: "hidden"
          },
          {
            key: "applicant_address3",
            title: "Address line 3",
            type: "hidden"
          },
          {
            key: "applicant_postcode",
            title: "Postcode",
            type: "hidden"
          },
          {
            key: "applicant_mainhome",
            title: "Is this your permanent main home now, or are you going to move into it within the next few weeks?",
            type: "radios"
          },
          {
            key: "applicant_mainhome_movedin",
            title: "What date did you move into this property?",
            type: "kerpdate",
            condition: conditions.toString('applicant_mainhome_movedin'),
            dateFormat: "YYYY-MM-DD",
            maxDate: new Date(),
            validationMessage: "The date must be in the format dd/mm/yyyy and the date cannot be a future date."
          },
          {
            key: "applicant_knownmovedate",
            title: "Do you know the date you are due to move into the address you are claiming for?",
            type: "radios",
            condition: conditions.toString('applicant_knownmovedate')
          },
          {
            key: "applicant_movedate",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            title: "When will you move into the address you are claiming for?",
            condition: conditions.toString('applicant_movedate')
          },
          {
            key: "applicant_contactmethod",
            title: "Preferred method of contact",
            type: "select"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 3",
        page: 3,
        title: "Your current address",
        items: [
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/applicantCurrentAddressLookup.html",
            condition: conditions.toString('template_applicant_address')
          },
          {
            key: "applicant_current_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('applicant_current_uprn')
          },
          {
            key: "applicant_current_address1",
            title: "Address line 1",
            type: "hidden",
            condition: conditions.toString('applicant_current_address1')
          },
          {
            key: "applicant_current_address2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('applicant_current_address2')
          },
          {
            key: "applicant_current_address3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('applicant_current_address3')
          },
          {
            key: "applicant_current_postcode",
            title: "Postcode",
            type: "hidden",
            condition: conditions.toString('applicant_current_postcode')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 4",
        page: 4,
        title: "Your previous address",
        items: [
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/applicantPreviousAddressLookup.html",
            condition: conditions.toString('template_applicant_previous_address')
          },
          {
            key: "applicant_previous_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('applicant_previous_uprn')
          },
          {
            key: "applicant_previous_address1",
            title: "Address line 1",
            type: "hidden",
            condition: conditions.toString('applicant_previous_address1')
          },
          {
            key: "applicant_previous_address2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('applicant_previous_address2')
          },
          {
            key: "applicant_previous_address3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('applicant_previous_address3')
          },
          {
            key: "applicant_previous_postcode",
            title: "Postcode",
            type: "hidden",
            readonly: false,
            condition: conditions.toString('applicant_previous_postcode')
          },
          {
            key: "applicant_previous_address_status",
            type: "selectWithInput",
            title: "What was your status at this address?",
            options: [
              "Homeowner",
              "Private or Housing Association tenant",
              "Council tenant",
              "Lodger or staying with friends or family",
              "Living with parents", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('applicant_previous_address_status')
          },
          {
            key: "applicant_previous_address_homeowner",
            title: "Tell us why you moved or why you are planning to move",
            condition: conditions.toString('applicant_previous_address_homeowner')
          },
          {
            key: "applicant_previous_address_sold",
            title: "Has the property been sold?",
            type: "radios",
            condition: conditions.toString('applicant_previous_address_sold')
          },
          {
            key: "applicant_previous_address_soldvalue",
            title: "How much was it sold for?",
            condition: conditions.toString('applicant_previous_address_soldvalue')
          },
          {
            key: "applicant_previous_address_soldreceived",
            title: "How much did you receive?",
            description: "Enter the amount you received after the fees had been paid.",
            condition: conditions.toString('applicant_previous_address_soldreceived')
          },
          {
            key: "applicant_previous_address_forsale",
            title: "Is the property up for sale?",
            type: "radios",
            condition: conditions.toString('applicant_previous_address_forsale')
          },
          {
            key: "applicant_previous_address_empty",
            title: "Is the property empty?",
            type: "radios",
            condition: conditions.toString('applicant_previous_address_empty')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 5",
        page: 5,
        title: "Tell us about you",
        items: [
          {
            key: "applicant_arrived_in_last_five",
            title: "Have you come to live in the United Kingdom in the last five years?",
            type: "radios"
          },
          {
            key: "applicant_residentsincedate",
            title: "When did you come to live in the United Kingdom?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "The United Kingdom (UK) is England, Northern Ireland, Scotland and Wales",
            condition: conditions.toString('applicant_residentsincedate')
          },
          {
            key: "applicant_righttostay",
            title: "Do you have the legal right to stay in the UK for as long as you want?",
            type: "radios",
            condition: conditions.toString('applicant_righttostay'),
            description: "If you answer No to this question you may not be entitled to any help, you will need to contact us to discuss your circumstances"
          },
          {
            key: "applicant_asylum",
            title: "Are you receiving support under Part VI of the Immigration and Asylum Act 1999?",
            type: "radios",
            condition: conditions.toString('applicant_asylum')
          },
          {
            key: "applicant_care",
            title: "Are you, or have you been under a care order or looked after under the Children Act?",
            type: "radios",
            condition: conditions.toString('applicant_care'),
            description: "You may be entitled to more Housing Benefit. You must provide proof of your care order or the fact that you were looked after under the Children Act (or both)."
          },
          {
            key: "applicant_mappa",
            title: "Are you being managed under Multi-Agency Public Protection Arrangements (MAPPA), to be rehabilitated back into the community?",
            type: "radios"
          },
          {
            key: "applicant_hostel",
            title: "Have you spent at least 3 months in a hostel or hostels for the rehabilitation or resettlement of homeless people?",
            type: "radios",
            description: "You may be entitled to more Housing Benefit. If you answer Yes, you will need to provide the name and address of the hostel you last stayed. Please provide the name and address of any other hostels that you have lived in and the dates that you lived there on page 43 of this form. We may need to write to you about this."
          },
          {
            key: "applicant_hostel_name",
            title: "What was the name of the last hostel you stayed in?",
            condition: conditions.toString('applicant_hostel_name')
          },
          {
            type: "help",
            helpvalue: "<label>What was the address?</label>",
            condition: conditions.toString('help_applicant_hostel_address')
          },
          {
            key: "applicant_hostel_addressline1",
            title: "Address line1 ",
            condition: conditions.toString('applicant_hostel_addressline1')
          },
          {
            key: "applicant_hostel_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_hostel_addressline2')
          },
          {
            key: "applicant_hostel_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_hostel_addressline3')
          },
          {
            key: "applicant_hostel_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_hostel_postcode')
          },
          {
            key: "applicant_previousclaim",
            title: "Have you claimed Housing Benefit, Council Tax Benefit, Council Tax Reduction or Second Adult Rebate before?",
            type: "radios"
          },
          {
            key: "applicant_previousclaim_name",
            type: "selectWithInput",
            title: "What name did you use for the claim?",
            options: ["The same name", "Another name"],
            radios: true,
            other: "Another name",
            comment: "If 'Another' please state",
            condition: conditions.toString('applicant_previousclaim_name')
          },
          {
            key: "applicant_previousclaim_council",
            title: "Which Council did you claim from?",
            condition: conditions.toString('applicant_previousclaim_council')
          },
          {
            key: "applicant_previousclaim_address",
            title: "If you have changed your address since your last claim, have you told the Council you claimed from?",
            type: "radios",
            condition: conditions.toString('applicant_previousclaim_address')
          },
          {
            key: "applicant_student",
            title: "Are you a student, apprentice or on youth training?",
            type: "radios",
            description: "<p>By student we mean anyone who is attending a course of study at an educational establishment, including student nurses.</p><p>You count as a student if you are studying:</p><ul><li>a first degree, postgraduate or higher degree; or</li><li>courses for the further training of teachers and youth or community workers; or</li><li>courses for the Diploma of Higher Education, the BTEC/SVEC Higher National Diploma (HND) or Higher National Certificate (HNC), the Diploma in Management Studies, or the Certificate in Education; or</li><li>any other course at a higher level than GCSE, A Level or BTEC/SVEC Ordinary National Diploma (OND) or Ordinary National Certificate (ONC), whether or not leading to a qualification.</li></ul>"
          },
          {
            key: "applicant_fulltime_student",
            title: "Are you a full-time student?",
            type: "select",
            condition: conditions.toString('applicant_fulltime_student'),
            description: "<p>Answer Yes even if in vacation between terms.</p><p>You count as a full time student if:</p><ul><li>No one is in receipt of Child Benefit for you; and</li><li>Your course is classed as full time or part time by your place of education, and not necessarily by the number of hours you attend. eg studying for a GCE, A Level, BTEC National Diploma, National Certificate, first degree, postgraduate or higher degrees, teacher training courses, BTEC Higher National Diploma or Higher National Certificate</li></ul>"
          },
          {
            key: "applicant_custody",
            title: "Are you in legal custody?",
            type: "radios",
            description: "Examples of legal custody include being in prison, a young offender institution, or on remand."
          },
          {
            key: "applicant_remandcustody",
            title: "Have you been sentenced or are you on remand?",
            type: "radios",
            condition: conditions.toString('applicant_remandcustody')
          },
          {
            key: "applicant_sentence",
            title: "Are you serving a sentence of more than 13 weeks?",
            type: "radios",
            condition: conditions.toString('applicant_sentence')
          },
          {
            key: "applicant_released",
            title: "Are you due to be released within the next 13 weeks?",
            type: "radios",
            condition: conditions.toString('applicant_released'),
            description: "<p>You cannot claim for Housing Benefit or Council Tax Reduction if you have told us that you are in prison and will not be released in the next 13 weeks.</p><p>Claims can only be made 13 weeks in advance. If your release date is within 13 weeks and you know what your circumstances and income will be when you are released, then continue to answer the rest of this form as if you had already been released. Your benefit cannot start until the Monday after the day you are released. If you do not know these details, don't continue - instead claim when you know the details.</p>"
          },
          {
            key: "applicant_custodyweeks",
            title: "Have you been in custody for more than 52 weeks (one year)?",
            type: "radios",
            condition: conditions.toString('applicant_custodyweeks'),
            description: "<p>You cannot claim for Housing Benefit or Council Tax Reduction if you have been in custody for more than 52 weeks a year.</p><p>If you have been in custody for less than 52 weeks a year, please answer the rest of this form based on your current situation. When you are released, or when you are sentenced, please do a new calculation and claim based on your new circumstances.</p>"
          },
          {
            key: "applicant_hospital",
            title: "Are you in hospital at the moment?",
            type: "radios"
          },
          {
            key: "applicant_hospitalin",
            title: "When did you go into hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_hospitalin')
          },
          {
            key: "applicant_hospitalout",
            title: "When will you come out of hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_hospitalout'),
            description: "If you don't know when you will be discharged from hospital then leave this blank"
          },
          {
            key: "couple",
            title: "Do you live with your partner as a couple?",
            type: "radios",
            description: "<p> A partner is:</p><ul><li>your husband or wife; or</li><li>the person you live with as if you are husband or wife; or</li><li>the person you have a same sex civil partnership with; or</li><li>the person you live with as if you have a same sex civil partnership.</li></ul><p>You should also answer Yes if your partner is currently living or working away from home.</p><p>You should answer No if your partner has been in legal custody for more than 52 weeks a year.</p>"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 6",
        title: "Tell us who your partner is",
        page: 6,
        items: [
          {
            key: "partner_title",
            type: "selectWithInput",
            title: "Title",
            options: ["Mr", "Ms", "Mrs", "Miss", "Other"],
            other: "Other",
            comment: "If other, please specify",
            condition: conditions.toString('partner_title')
          },
          {
            key: "partner_forename",
            title: "Their Forename(s)",
            condition: conditions.toString('partner_forename')
          },
          {
            key: "partner_surname",
            title: "Their Surname",
            condition: conditions.toString('partner_surname')
          },
          {
            key: "partner_othername",
            title: "Does your partner use, or have they ever used, any other names?",
            type: "radios",
            condition: conditions.toString('partner_othername')
          },
          {
            key: "partner_othername_other",
            title: "Please specify",
            condition: conditions.toString('partner_othername_other')
          },
          {
            key: "partner_gender",
            title: "Are they Male or Female",
            type: "select",
            condition: conditions.toString('partner_gender')
          },
          {
            key: "partner_dob",
            title: "Their date of birth",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            validationMessage: "The date must be in the format dd/mm/yyyy and the age must be between 16 and 120.",
            maxDate: dateUtils.getYearsBeforeNow(16),
            minDate: dateUtils.getYearsBeforeNow(120),
            condition: conditions.toString('partner_dob')
          },
          {
            key: "partner_nationality",
            title: "What is their nationality?",
            condition: conditions.toString('partner_nationality')
          },
          {
            key: "partner_ni",
            title: "Their National insurance number",
            condition: conditions.toString('partner_ni'),
            description: "You can find this on payslips, letters about your benefits or letters from the tax office."
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 7",
        title: "Where can we get in touch with your partner?",
        page: 7,
        items: [
          {
            key: "partner_mainhome",
            title: "Is the address you are making a claim for your partner's permanent main home now, or are they moving into the property within the next few weeks?",
            type: "radios",
            condition: conditions.toString('partner_mainhome')
          },
          {
            key: "partner_mainhome_movedin",
            title: "What date did your partner move into this property?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            maxDate: new Date(),
            validationMessage: "The date must be in the format dd/mm/yyyy and the date cannot be a future date.",
            condition: conditions.toString('partner_mainhome_movedin')
          },
          {
            key: "partner_knownmovedate",
            title: "Does your partner know the date they are due to move into the address you are claiming for?",
            type: "radios",
            condition: conditions.toString('partner_knownmovedate')
          },
          {
            key: "partner_movedate",
            title: "When will your partner move into the address you are claiming for?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_movedate')
          },
          {
            key: "partner_contact",
            title: "Partner's daytime phone number",
            condition: conditions.toString('partner_contact')
          },
          {
            key: "partner_email",
            title: "What is your partner's e-mail?",
            condition: conditions.toString('partner_email')
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 8",
        page: 8,
        title: "Your partner's current address",
        items: [
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/partnerCurrentAddressLookup.html",
            condition: conditions.toString('template_partner_mainhome')
          },
          {
            key: "partner_current_uprn",
            title: "Property reference",
            condition: conditions.toString('partner_current_uprn'),
            type: "hidden"
          },
          {
            key: "partner_current_address1",
            title: "Address line 1",
            condition: conditions.toString('partner_current_address1'),
            type: "hidden"
          },
          {
            key: "partner_current_address2",
            title: "Address line 2",
            condition: conditions.toString('partner_current_address2'),
            type: "hidden"
          },
          {
            key: "partner_current_address3",
            title: "Address line 3",
            condition: conditions.toString('partner_current_address3'),
            type: "hidden"
          },
          {
            key: "partner_current_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_current_postcode'),
            type: "hidden"
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 9",
        page: 9,
        title: "Your partner's previous address",
        items: [
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/partnerPreviousAddressLookup.html",
            condition: conditions.toString('template_partner_previous_address')
          },
          {
            key: "partner_previous_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('partner_previous_uprn')
          },
          {
            key: "partner_previous_address1",
            title: "Address line 1",
            type: "hidden",
            condition: conditions.toString('partner_previous_address1')
          },
          {
            key: "partner_previous_address2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('partner_previous_address2')
          },
          {
            key: "partner_previous_address3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('partner_previous_address3')
          },
          {
            key: "partner_previous_postcode",
            title: "Postcode",
            type: "hidden",
            condition: conditions.toString('partner_previous_postcode')
          },
          {
            key: "partner_previous_address_status",
            type: "selectWithInput",
            title: "What was your partner's status at this address?",
            options: ["Homeowner",
              "Private or Housing Association tenant",
              "Council tenant",
              "Lodger or staying with friends or family",
              "Living with parents", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('partner_previous_address_status')
          },
          {
            key: "partner_previous_address_homeowner",
            title: "Tell us why your partner moved or why they are planning to move",
            condition: conditions.toString('partner_previous_address_homeowner')
          },
          {
            key: "partner_previous_address_sold",
            title: "Has the property been sold?",
            type: "radios",
            condition: conditions.toString('partner_previous_address_sold')
          },
          {
            key: "partner_previous_address_soldvalue",
            title: "How much was it sold for",
            condition: conditions.toString('partner_previous_address_soldvalue')
          },
          {
            key: "partner_previous_address_soldreceived",
            title: "How much did they receive?",
            condition: conditions.toString('partner_previous_address_soldreceived')
          },
          {
            key: "partner_previous_address_forsale",
            title: "Is the property up for sale?",
            type: "radios",
            condition: conditions.toString('partner_previous_address_forsale')
          },
          {
            key: "partner_previous_address_empty",
            title: "Is the property empty?",
            type: "radios",
            condition: conditions.toString('partner_previous_address_empty')
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 10",
        page: 10,
        title: "Tell us about your partner",
        items: [
          {
            key: "partner_arrived_in_last_five",
            title: "Has your partner come to live in the United Kingdom in the last five years?",
            type: "radios",
            condition: conditions.toString('partner_arrived_in_last_five')
          },
          {
            key: "partner_residentsincedate",
            title: "When did your partner come to live in the United Kingdom?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_residentsincedate'),
            description: "The United Kingdom (UK) is England, Northern Ireland, Scotland and Wales"
          },
          {
            key: "partner_righttostay",
            title: "Does your partner have the legal right to stay in the UK for as long as they want?",
            type: "radios",
            condition: conditions.toString('partner_righttostay'),
            description: "If you answer No to this question you may not be entitled to any help, you will need to contact us to discuss your circumstances"
          },
          {
            key: "partner_asylum",
            title: "Is your partner receiving support under Part VI of the Immigration and Asylum Act 1999?",
            type: "radios",
            condition: conditions.toString('partner_asylum')
          },
          {
            key: "partner_care",
            title: "Has your partner been under a care order or looked after under the Children Act?",
            type: "radios",
            condition: conditions.toString('partner_care'),
            description: "You may be entitled to more Housing Benefit. You must provide proof of your partner's care order or the fact that they were looked after under the Children Act (or both)."
          },
          {
            key: "partner_mappa",
            title: "Is your partner being managed under Multi-Agency Public Protection Arrangements (MAPPA), to be rehabilitated back into the community",
            type: "radios",
            condition: conditions.toString('partner_mappa')
          },
          {
            key: "partner_hostel",
            title: "Has your partner spent at least 3 months in a hostel or hostels for the rehabilitation or resettlement of homeless people?",
            type: "radios",
            condition: conditions.toString('partner_hostel'),
            description: "You may be entitled to more Housing Benefit. If you answer Yes, you will need to provide the name and address of the hostel your partner last stayed. Please also provide the name and address of any other hostels that your partner has lived in and the dates they lived there on page 43 of this form. We may need to write to you about this."
          },
          {
            key: "partner_hostel_name",
            title: "What was the name of the last hostel they stayed in?",
            condition: conditions.toString('partner_hostel_name')
          },
          {
            key: "partner_hostel_addressline1",
            title: "What was the address? Address line1 ",
            condition: conditions.toString('partner_hostel_addressline1')
          },
          {
            key: "partner_hostel_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_hostel_addressline2')
          },
          {
            key: "partner_hostel_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_hostel_addressline3')
          },
          {
            key: "partner_hostel_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_hostel_postcode')
          },
          {
            key: "partner_previousclaim",
            title: "Has your partner claimed Housing Benefit, Council Tax Benefit, Council Tax Reduction or Second Adult Rebate before?",
            type: "radios",
            condition: conditions.toString('partner_previousclaim')
          },
          {
            key: "partner_previousclaim_name",
            type: "selectWithInput",
            title: "What name did they use for the claim?",
            options: ["The same name", "Another name"],
            radios: true,
            other: "Another name",
            comment: "Please specify",
            condition: conditions.toString('partner_previousclaim_name')
          },
          {
            key: "partner_previousclaim_council",
            title: "Which Council did they claim from?",
            condition: conditions.toString('partner_previousclaim_council')
          },
          {
            key: "partner_previousclaim_address",
            title: "If they have changed their address since their last claim, have they told the Council they claimed from?",
            type: "radios",
            condition: conditions.toString('partner_previousclaim_address')
          },
          {
            key: "partner_student",
            title: "Is your partner a student, an Apprentice or on Youth Training?",
            type: "radios",
            condition: conditions.toString('partner_student'),
            description: "<p>By student we mean anyone who is attending a course of study at an educational establishment, including student nurses..</p> <p>You count as a student if you are studying:</p> <ul> <li>a first degree, postgraduate or higher degree; or</li> <li>courses for the further training of teachers and youth or community workers; or</li> <li>courses for the Diploma of Higher Education, the BTEC/SVEC Higher National Diploma (HND) or Higher National Certificate (HNC), the Diploma in Management Studies, or the Certificate in Education; or</li> <li>any other course at a higher level than GCSE, A Level or BTEC/SVEC Ordinary National Diploma (OND) or Ordinary National Certificate (ONC), whether or not leading to a qualification.</li> </ul>"
          },
          {
            key: "partner_fulltime_student",
            title: "Is your partner a full-time student?",
            type: "select",
            condition: conditions.toString('partner_fulltime_student'),
            description: "<p>Answer Yes even if in vacation between terms.</p><p>You count as a full time student if:</p><ul><li>No one is in receipt of Child Benefit for you; and</li><li>Your course is classed as full time or part time by your place of education, and not necessarily by the number of hours you attend. eg studying for a GCE, A Level, BTEC National Diploma, National Certificate, first degree, postgraduate or higher degrees, teacher training courses, BTEC Higher National Diploma or Higher National Certificate</li></ul>"
          },
          {
            key: "partner_hospital",
            title: "Is your partner in hospital at the moment?",
            type: "radios",
            condition: conditions.toString('partner_hospital')
          },
          {
            key: "partner_hospitalin",
            title: "When did your partner go into hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_hospitalin')
          },
          {
            key: "partner_hospitalout",
            title: "When will your partner come out of hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_hospitalout'),
            description: "If you don't know when your partner will be discharged from hospital then leave this blank"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 11",
        page: 11,
        title: "Universal Credit",
        items: [
          {
            key: "applicant_uc",
            title: "Do you get Universal Credit",
            type: "radios",
            condition: conditions.toString('applicant_uc'),
            description: "<strong>Please note:</strong> If you answer Yes to this question, your Housing Benefit entitlement will not be calculated and a Housing Benefit claim will not be accepted because housing costs are already included in Universal Credit (unless you are in temporary or supported accommodation). However, you can still claim for Council Tax Reduction using this application."
          },
          {
            key: "couple_uc",
            title: "Do you or your partner get Universal Credit?",
            type: "select",
            condition: conditions.toString('couple_uc'),
            description: "<strong>Please note:</strong> If you answer Yes to this question, your Housing Benefit entitlement will not be calculated and a Housing Benefit claim will not be accepted because housing costs are already included in Universal Credit (unless you are in temporary or supported accommodation). However, you can still claim for Council Tax Reduction using this application."
          },
          {
            key: "applicant_pendingucdate",
            title: "When did you claim this benefit?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pendingucdate')
          },
          {
            key: "couple_pendingucdate",
            title: "When did you claim this benefit?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('couple_pendingucdate')
          },
          {
            key: "applicant_ucvalue",
            title: "How much Universal Credit do you get?",
            condition: conditions.toString('applicant_ucvalue'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total before adjustments'."
          },
          {
            key: "applicant_ucnonworkincome",
            title: "How much 'non-work income and other benefits' do you receive, as well as savings and capital?",
            condition: conditions.toString('applicant_ucnonworkincome'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total we take off for other benefits'."
          },
          {
            key: "applicant_uctakehomepay",
            title: "What is the amount of take-home pay?",
            condition: conditions.toString('applicant_uctakehomepay'),
            description: "You can find this on your Universal Credit Statement. This figure follows the sentence 'Your total take-home pay for this period is'."
          },
          {
            key: "applicant_uctakenoff",
            title: "What is the total amount taken off for take-home pay by the DWP?",
            condition: conditions.toString('applicant_uctakenoff'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'The total we take off for take-home pay is'."
          },
          {
            key: "partner_ucvalue",
            title: "What is the total amount of Universal Credit that applies to your partner?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total before adjustments'.",
            condition: conditions.toString('partner_ucvalue')
          },
          {
            key: "partner_nonworkincome",
            title: "How much 'non-work income and other benefits' does your partner receive, as well as savings and capital?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total we take off for other benefits'.",
            condition: conditions.toString('partner_nonworkincome')
          },
          {
            key: "partner_takehomepay",
            title: "What is the amount of take-home pay that applies to your partner?",
            description: "You can find this on your Universal Credit Statement. This figure follows the sentence 'Your total take-home pay for this period is'.",
            condition: conditions.toString('partner_takehomepay')
          },
          {
            key: "partner_takenoff",
            title: "What is the total amount taken off for take-home pay by the DWP that applies to your partner?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'The total we take off for take-home pay is'.",
            condition: conditions.toString('partner_takenoff')
          },
          {
            key: "couple_ucvalue",
            title: "What is the total amount of Universal Credit that applies to you and your partner?",
            condition: conditions.toString('couple_ucvalue'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total before adjustments'."
          },
          {
            key: "couple_nonworkincome",
            title: "How much 'non-work income and other benefits' do you or your partner receive, as well as savings and capital?",
            condition: conditions.toString('couple_nonworkincome'),
            description: "You can find this on your Universal Credit Statement. This figure is named 'Total we take off for other benefits'."
          },
          {
            key: "couple_takehomepay",
            title: "What is the amount of take-home pay that applies to you and your partner?",
            condition: conditions.toString('couple_takehomepay'),
            description: "You can find this on your Universal Credit Statement. This figure follows the sentence 'Your total take-home pay for this period is'."
          },
          {
            key: "couple_takenoff",
            title: "What is the total amount taken off for take-home pay by the DWP that applies to you or your partner?",
            description: "You can find this on your Universal Credit Statement. This figure is named 'The total we take off for take-home pay is'.",
            condition: conditions.toString('couple_takenoff')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 12",
        page: 12,
        title: "Benefits - Income Support",
        items: [
          {
            key: "applicant_incomesupport",
            title: "Do you get Income Support?",
            type: "radios",
            condition: conditions.toString('applicant_incomesupport')
          },
          {
            key: "applicant_incomesupport_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you are not sure about the exact date please put an approximate date.",
            condition: conditions.toString('applicant_incomesupport_date')
          },
          {
            key: "applicant_incomesupport_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_incomesupport_claimdate')
          },
          {
            key: "partner_incomesupport",
            title: "Does your partner get Income Support?",
            type: "radios",
            condition: conditions.toString('partner_incomesupport')
          },
          {
            key: "partner_incomesupport_date",
            title: "When did they start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you are not sure about the exact date please put an approximate date.",
            condition: conditions.toString('partner_incomesupport_date')
          },
          {
            key: "partner_incomesupport_claimdate",
            title: "When did they claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_incomesupport_claimdate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 13",
        page: 13,
        title: "Benefits - Jobseeker's Allowance",
        items: [
          {
            key: "applicant_jsa",
            title: "Do you get income-based Jobseeker's Allowance (JSA)?",
            type: "radios",
            condition: conditions.toString('applicant_jsa'),
            description: "<p>There are two types of Jobseeker's Allowance:</p><ul><li>Income-based Jobseeker's Allowance, based on  income and savings</li><li>Contribution-based Jobseeker's Allowance, paid for up to 182 days and based on National Insurance contributions</li></ul><p>Only answer Yes to this question if you get <strong>income-based</strong> Jobseeker's Allowance, we will ask later about contribution-based Jobseeker's Allowance.</p><p>Please answer No if you receive contribution-based Jobseeker's Allowance or if you don't know what type of Jobseeker's Allowance you get.</p><p>If you don't know which type you get, it will say on your award letter letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "applicant_jsa_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_jsa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_jsa_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_jsa_claimdate')
          },
          {
            key: "partner_jsa",
            title: "Does your partner get income-based Jobseeker's Allowance (JSA)?",
            type: "radios",
            titleMap: [
              {
                value: "Yes",
                name: "Yes"
              },
              {
                value: "Waiting",
                name: "They are waiting to hear"
              },
              {
                value: "No",
                name: "No"
              }],
            condition: conditions.toString('partner_jsa'),
            description: "<p>There are two types of Jobseeker's Allowance:</p><ul><li>Income-based Jobseeker's Allowance, based on  income and savings</li><li>Contribution-based Jobseeker's Allowance, paid for up to 182 days and based on National Insurance contributions</li></ul><p>Only answer Yes to this question if your partner gets <strong>income-based</strong> Jobseeker's Allowance, we will ask later about contribution-based Jobseeker's Allowance.</p><p>Please answer No if your partner receives contribution-based Jobseeker's Allowance or if you don't know what type of Jobseeker's Allowance they get.</p><p>If you don't know which type they get, it will say on their award letter letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "partner_jsa_date",
            title: "When did your partner start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_jsa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_jsa_claimdate",
            title: "When did your partner claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_jsa_claimdate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 14",
        page: 14,
        title: "Benefits - Income-related Employment and Support Allowance",
        items: [
          {
            key: "applicant_incomeesa",
            title: "Do you get income-related Employment and Support Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_incomeesa'),
            description: "<p>Only answer Yes to this question if you get <strong>income-related</strong> Employment and Support Allowance, we will ask later about contribution-based Employment and Support Allowance.</p><p>Please answer No if you only receive contribution-based Employment and Support Allowance or do not know what type of Employment and Support Allowance you receive. If you don't know which type you get, look on your award letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "applicant_incomeesa_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_incomeesa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_incomeesa_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_incomeesa_claimdate')
          },
          {
            key: "applicant_incomeesarate",
            title: "Which rate of Employment and Support Allowance are you getting?",
            type: "select",
            condition: conditions.toString('applicant_incomeesarate'),
            description: "Employment and Support Allowance is paid at several different rates. Initially, for up to 13 weeks, it is paid at the basic rate. After 13 weeks either a work-related activity component, or a support component can be added to that basic rate."
          },
          {
            key: "partner_incomeesa",
            title: "Does your partner get income-related Employment and Support Allowance?",
            type: "radios",
            condition: conditions.toString('partner_incomeesa'),
            description: "<p>Only answer Yes to this question if your partner gets <strong>income-related</strong> Employment and Support Allowance, we will ask later about contribution-based Employment and Support Allowance.</p><p>Please answer No if your partner only receives contribution-based Employment and Support Allowance or you don't know what type of Employment and Support Allowance they receive.</p><p>If you don't know which type your partner gets, it will say on their award letter from the Department for Work and Pensions (DWP).</p>"
          },
          {
            key: "partner_incomeesa_date",
            title: "When did they start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_incomeesa_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_incomeesa_claimdate",
            title: "When did they claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_incomeesa_claimdate')
          },
          {
            key: "partner_incomeesarate",
            title: "Which rate of Employment and Support Allowance are they getting?",
            type: "select",
            condition: conditions.toString('partner_incomeesarate'),
            description: "Employment and Support Allowance is paid at several different rates. Initially, for up to 13 weeks, it is paid at the basic rate. After 13 weeks either a work-related activity component, or a support component can be added to that basic rate."
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 15",
        page: 15,
        title: "Benefits - Pension Credit",
        items: [
          {
            key: "applicant_pensioncreditguarantee",
            title: "Do you get Pension Credit (the Guarantee part)?",
            type: "radios",
            description: "<p>There are 2 parts to Pension Credit:</p><ul><li>Savings Credit, and</li><li>Guarantee Credit</li></ul><p>You can get one or both parts. Only answer Yes if your award contains the Guarantee part, we will ask about the Savings part later. If you are not sure, check your award letter from the Pension Service.</p>"
          },
          {
            key: "applicant_pensioncreditguarantee_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditguarantee_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_pensioncreditguarantee_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditguarantee_claimdate')
          },
          {
            key: "partner_pensioncreditguarantee",
            title: "Does your partner get Pension Credit (the Guarantee part)?",
            type: "radios",
            description: "<p>There are 2 parts to Pension Credit:</p><ul><li>Savings Credit, and</li><li>Guarantee Credit</li></ul><p>You can get one or both parts. Only answer Yes if your partner's award contains the Guarantee part, we will ask about the Savings part later. If you are not sure, check your partner's award letter from the Pension Service.</p>",
            condition: conditions.toString('partner_pensioncreditguarantee')
          },
          {
            key: "partner_pensioncreditguarantee_date",
            title: "When did your partner start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditguarantee_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_pensioncreditguarantee_claimdate",
            title: "When did your partner claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditguarantee_claimdate')
          },
          {
            key: "applicant_pensioncreditsavings",
            title: "Do you get Pension Credit (the Savings part)?",
            type: "radios"
          },
          {
            key: "applicant_pensioncreditsavings_date",
            title: "When did you start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditsavings_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "applicant_pensioncreditsavings_claimdate",
            title: "When did you claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_pensioncreditsavings_claimdate')
          },
          {
            key: "partner_pensioncreditsavings",
            title: "Does your partner get Pension Credit (the Savings part)?",
            type: "radios",
            condition: conditions.toString('partner_pensioncreditsavings')
          },
          {
            key: "partner_pensioncreditsavings_date",
            title: "When did they start receiving it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditsavings_date'),
            description: "If you are not sure about the exact date please put an approximate date."
          },
          {
            key: "partner_pensioncreditsavings_claimdate",
            title: "When did they claim it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_pensioncreditsavings_claimdate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 16",
        page: 16,
        title: "Benefits - Disability Living Allowance",
        items: [
          {
            key: "applicant_dla",
            title: "Do you get Disability Living Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_dla')
          },
          {
            key: "applicant_dlafrequency",
            title: "How often do you get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('applicant_dlafrequency')
          },
          {
            key: "applicant_dlacare4weeks",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£73.90 (Middle)</li><li>£110.40 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacare4weeks')
          },
          {
            key: "applicant_dlacareweek",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£73.90 (Middle)</li><li>£110.40 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacareweek')
          },
          {
            key: "applicant_dlamot4weeks",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamot4weeks')
          },
          {
            key: "applicant_dlamotweek",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamotweek')
          },
          {
            key: "couple_dla",
            title: "Do you or your partner get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('couple_dla')
          },
          {
            key: "applicant_dlafrequency1",
            title: "How often do you get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('applicant_dlafrequency1')
          },
          {
            key: "applicant_dlacare4weeks1",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£73.90 (Middle)</li><li>£110.40 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacare4weeks1')
          },
          {
            key: "applicant_dlacareweek1",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£73.90 (Middle)</li><li>£110.40 (High)</li></ul>",
            condition: conditions.toString('applicant_dlacareweek1')
          },
          {
            key: "applicant_dlamot4weeks1",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamot4weeks1')
          },
          {
            key: "applicant_dlamotweek1",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (High)</li></ul>",
            condition: conditions.toString('applicant_dlamotweek1')
          },
          {
            key: "partner_dlafrequency",
            title: "How often does your partner get Disability Living Allowance?",
            type: "select",
            condition: conditions.toString('partner_dlafrequency')
          },
          {
            key: "partner_dlacare4weeks",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£73.90 (Middle)</li><li>£110.40 (High)</li></ul>",
            condition: conditions.toString('partner_dlacare4weeks')
          },
          {
            key: "partner_dlacareweek",
            title: "What rate of your Disability Living Allowance is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£73.90 (Middle)</li><li>£110.40 (High)</li></ul>",
            condition: conditions.toString('partner_dlacareweek')
          },
          {
            key: "partner_dlamot4weeks",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (High)</li></ul>",
            condition: conditions.toString('partner_dlamot4weeks')
          },
          {
            key: "partner_dlamotweek",
            title: "What rate of your Disability Living Allowance is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (High)</li></ul>",
            condition: conditions.toString('partner_dlamotweek')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 17",
        page: 17,
        title: "Benefits - Personal Independence Payment",
        items: [
          {
            key: "applicant_pip",
            title: "Do you get Personal Independence Payment or Armed Forces Independence Payment?",
            type: "radios",
            condition: conditions.toString('applicant_pip')
          },
          {
            key: "couple_pip",
            title: "Do you or your partner get Personal Independence Payment or Armed Forces Independence Payment?",
            type: "select",
            condition: conditions.toString('couple_pip')
          },
          {
            key: "applicant_pipdaily",
            title: "What rate of your Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£73.90 (Standard)</li><li>£110.40 (Enhanced)</li></ul>",
            condition: conditions.toString('applicant_pipdaily')
          },
          {
            key: "applicant_pipmot",
            title: "What rate of your Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (Enhanced)</li></ul>",
            condition: conditions.toString('applicant_pipmot')
          },
          {
            key: "partner_pipdaily",
            title: "What rate of your partner's Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£73.90 (Standard)</li><li>£110.40 (Enhanced)</li></ul>",
            condition: conditions.toString('partner_pipdaily')
          },
          {
            key: "partner_pipmot",
            title: "What rate of your partner's Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (Enhanced)</li></ul>",
            condition: conditions.toString('partner_pipmot')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 18",
        page: 18,
        title: "Benefits - Attendance Allowance",
        items: [
          {
            key: "applicant_aa",
            title: "Do you get Attendance Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_aa')
          },
          {
            key: "couple_aa",
            title: "Do you or your partner get Attendance Allowance?",
            type: "select",
            condition: conditions.toString('couple_aa')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 19",
        page: 19,
        title: "Benefits - Carer's Allowance",
        items: [
          {
            key: "applicant_calookingafter",
            title: "Does anyone else get Carer's Allowance for looking after you?",
            type: "radios",
            condition: conditions.toString('applicant_calookingafter')
          },
          {
            key: "applicant_carername",
            title: "What's their name?",
            condition: conditions.toString('applicant_carername')
          },
          {
            key: "applicant_careraddress1",
            title: "What is their address? Address Line 1",
            condition: conditions.toString('applicant_careraddress1')
          },
          {
            key: "applicant_careraddress2",
            title: "Address line 2",
            condition: conditions.toString('applicant_careraddress2')
          },
          {
            key: "applicant_careraddress3",
            title: "Address line 3",
            condition: conditions.toString('applicant_careraddress3')
          },
          {
            key: "applicant_carerpostcode",
            title: "Postcode",
            condition: conditions.toString('applicant_carerpostcode')
          },
          {
            key: "applicant_ca_entitlement",
            title: "Do you have an underlying entitlement to Carer's Allowance?",
            condition: conditions.toString('applicant_ca_entitlement'),
            type: "radios"
          },
          {
            key: "applicant_ca8weeks",
            title: "In the last eight weeks have you received Carer's Allowance for looking after a person with a disability?",
            type: "radios",
            condition: conditions.toString('applicant_ca8weeks')
          },
          {
            key: "applicant_ca",
            title: "Are you getting Carer's Allowance now?",
            type: "radios",
            condition: conditions.toString('applicant_ca')
          },
          {
            key: "applicant_ca_receiving",
            title: "What is the name of the person you are receiving Carer's Allowance for?",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            type: "help",
            helpvalue: "<label>What is their address?</label>",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_addressline1",
            title: "Address line 1",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_ca_receiving_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_cafrequency",
            title: "How often do you get Carer's Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_cafrequency')
          },
          {
            key: "applicant_castop",
            title: "Why have you stopped getting Carer's Allowance ?",
            type: "select",
            condition: conditions.toString('applicant_castop')
          },
          {
            key: "applicant_carate",
            title: "How much Carer's Allowance do you get?",
            condition: conditions.toString('applicant_carate')
          },
          {
            key: "couple_calookingafter",
            title: "Does anyone else get Carer's Allowance for looking after you or your partner?",
            type: "radios",
            condition: conditions.toString('couple_calookingafter')
          },
          {
            key: "carer_name",
            title: "What's their name?",
            condition: conditions.toString('carer_name')
          },
          {
            key: "carer_address1",
            title: "What's their address? Address line 1",
            condition: conditions.toString('carer_address1')
          },
          {
            key: "carer_address2",
            title: "Address line 2",
            condition: conditions.toString('carer_address2')
          },
          {
            key: "carer_address3",
            title: "Address line 3",
            condition: conditions.toString('carer_address3')
          },
          {
            key: "carer_postcode",
            title: "Postcode?",
            condition: conditions.toString('carer_postcode')
          },
          {
            key: "couple_ca_entitlement",
            title: "Do you or your partner have an underlying entitlement to Carer's Allowance?",
            condition: conditions.toString('couple_ca_entitlement'),
            type: "radios",
            description: "Answer yes if either of the following apply to you or your partner:<ul><li>you or your partner have an underlying entitlement to Carer's Allowance but are not receiving it because you or your partner are receiving another state benefit</li><li>it has stopped or been sanctioned due to the loss of benefits for benefit offences rule</li></ul>"
          },
          {
            key: "couple_ca8weeks",
            title: "In the last eight weeks have you received Carer's Allowance for looking after a person with a disability?",
            type: "select",
            condition: conditions.toString('couple_ca8weeks')
          },
          {
            key: "applicant_ca1",
            title: "Are you getting Carer's Allowance now?",
            type: "radios",
            condition: conditions.toString('applicant_ca1')
          },

          {
            key: "applicant_ca_receiving1",
            title: "What is the name of the person you are receiving Carer's Allowance for?",
            condition: conditions.toString('applicant_ca1')
          },
          {
            type: "help",
            helpvalue: "<label>What is their address?</label>",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_addressline1",
            title: "Address line 1",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_ca1')
          },
          {
            key: "applicant_ca_receiving1_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_ca1')
          },

          {
            key: "applicant_cafrequency1",
            title: "How often do you get Carer's Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_cafrequency1')
          },
          {
            key: "applicant_castop1",
            title: "Why have you stopped getting Carer's Allowance ?",
            type: "select",
            condition: conditions.toString('applicant_castop1')
          },
          {
            key: "applicant_carate1",
            title: "How much Carer's Allowance do you get?",
            condition: conditions.toString('applicant_carate1')
          },
          {
            key: "partner_ca",
            title: "Is your partner getting Carer's Allowance now?",
            type: "select",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving",
            title: "What is the name of the person your partner is receiving Carer's Allowance for?",
            condition: conditions.toString('partner_ca')
          },
          {
            type: "help",
            helpvalue: "<label>What is their address?</label>",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_addressline1",
            title: "Address line 1",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_ca_receiving_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_ca')
          },
          {
            key: "partner_castop",
            title: "Why has your partner stopped getting Carer's Allowance?",
            type: "select",
            condition: conditions.toString('partner_castop')
          },
          {
            key: "partner_cafrequency",
            title: "How often does your partner get Carer's Allowance?",
            type: "select",
            condition: conditions.toString('partner_cafrequency')
          },
          {
            key: "partner_carate",
            title: "How much Carer's Allowance does your partner get?",
            condition: conditions.toString('partner_carate')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 20",
        page: 20,
        title: "Benefits",
        items: [
          {
            key: "applicant_disabled",
            title: "Are you registered blind, severely mentally impaired, long term sick or disabled?",
            type: "checkboxes",
            description: "If you have been registered blind within the previous 6 months then still select that you are blind"
          },
          {
            key: "partner_disabled",
            title: "Is your partner registered blind, severely mentally impaired, long term sick or disabled?",
            type: "checkboxes",
            description: "If your partner has been registered blind within the previous 6 months then still select that they are blind",
            condition: conditions.toString('partner_disabled')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 21",
        page: 21,
        title: "Living away from home",
        items: [
          {
            key: "applicant_livingaway",
            title: "Are you living away from your usual home at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_livingaway')
          },
          {
            key: "couple_livingaway",
            title: "Are you or your partner living away from your usual home at the moment?",
            type: "select",
            condition: conditions.toString('couple_livingaway')
          },
          {
            key: "applicant_livingaway_reason",
            title: "Why are you not living at home?",
            condition: conditions.toString('applicant_livingaway_reason')
          },
          {
            key: "applicant_livingaway_lastlive",
            title: "When did you last live at home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_livingaway_lastlive')
          },
          {
            key: "applicant_livingaway_intendtoreturn",
            title: "Do you intend to return home?",
            type: "radios",
            condition: conditions.toString('applicant_livingaway_intendtoreturn')
          },
          {
            key: "applicant_livingaway_returndate",
            title: "When do you expect to go back home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_livingaway_returndate')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/applicantLivingAwayLookup.html",
            condition: conditions.toString('template_applicant_livingaway_address')
          },
          {
            key: "applicant_livingaway_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_uprn')
          },
          {
            key: "applicant_livingaway_addressline1",
            title: "Address Line 1",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_addressline1')
          },
          {
            key: "applicant_livingaway_addressline2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_addressline2')
          },
          {
            key: "applicant_livingaway_addressline3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_addressline3')
          },
          {
            key: "applicant_livingaway_postcode",
            title: "Postcode",
            type: "hidden",
            condition: conditions.toString('applicant_livingaway_postcode')
          },
          {
            key: "applicant_livingaway_sublet",
            title: "Have you sublet your home?",
            type: "radios",
            description: "Answer Yes if you have a commercial arrangement with someone and you charge them a regular rent for living at the property.",
            condition: conditions.toString('applicant_livingaway_sublet')
          },
          {
            key: "applicant_livingaway_lives",
            title: "Who lives there now?",
            condition: conditions.toString('applicant_livingaway_lives')
          },
          {
            key: "partner_livingaway_reason",
            title: "Why is your partner not living at home?",
            condition: conditions.toString('partner_livingaway_reason')
          },
          {
            key: "partner_livingaway_lastlive",
            title: "When did your partner last live at home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_livingaway_lastlive')
          },
          {
            key: "partner_livingaway_intendtoreturn",
            title: "Does your partner intend to return home?",
            type: "radios",
            condition: conditions.toString('partner_livingaway_intendtoreturn')
          },
          {
            key: "partner_livingaway_returndate",
            title: "When does your partner expect to go back home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_livingaway_returndate')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/partnerLivingAwayLookup.html",
            condition: conditions.toString('template_partner_livingaway_address')
          },
          {
            key: "partner_livingaway_uprn",
            title: "Property reference",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_uprn')
          },
          {
            key: "partner_livingaway_addressline1",
            title: "Address line 1",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_addressline1')
          },
          {
            key: "partner_livingaway_addressline2",
            title: "Address line 2",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_addressline2')
          },
          {
            key: "partner_livingaway_addressline3",
            title: "Address line 3",
            type: "hidden",
            condition: conditions.toString('partner_livingaway_addressline3')
          },
          {
            key: "partner_livingaway_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_livingaway_postcode'),
            type: "hidden"
          },
          {
            key: "partner_livingaway_sublet",
            title: "Has your partner sublet their home?",
            type: "radios",
            description: "Answer Yes if they have a commercial arrangement with someone and charge them a regular rent for living at the property.",
            condition: conditions.toString('partner_livingaway_sublet')
          },
          {
            key: "partner_livingaway_lives",
            title: "Who lives there now?",
            condition: conditions.toString('partner_livingaway_lives')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 22",
        page: 22,
        title: "Your Household",
        items: [
          {
            key: "household_died",
            title: "Has someone in your household died within the last 12 months?",
            type: "radios"
          },
          {
            key: "household_diedname",
            title: "What was their name?",
            condition: conditions.toString('household_diedname')
          },
          {
            key: "household_dieddate",
            title: "When did they die?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('household_dieddate')
          },
          {
            key: "applicant_household_diedrelationship",
            title: "What was their relationship to you?",
            condition: conditions.toString('applicant_household_diedrelationship')
          },
          {
            key: "partner_household_diedrelationship",
            title: "What was their relationship to you or your partner?",
            condition: conditions.toString('partner_household_diedrelationship')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 23",
        page: 23,
        title: "You and your household  Children",
        items: [
          {
            key: "applicant_pregnant",
            title: "Are you expecting a baby?",
            type: "radios"
          },
          {
            key: "partner_pregnant",
            title: "Is your partner expecting a baby?",
            type: "radios",
            condition: conditions.toString('partner_pregnant')
          },
          {
            key: "applicant_duedate",
            title: "When is the baby due?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_duedate')
          },
          {
            key: "partner_duedate",
            title: "When is the baby due?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_duedate')
          },
          {
            key: "couple_dependantchildren",
            title: "Do you or your partner live with any dependant children?",
            type: "radios",
            condition: conditions.toString('couple_dependantchildren'),
            description: "A dependant child is one who you or your partner usually get child benefit for. Answer Yes if you are responsible for a child, even if you do not get child benefit for that child. Do not count any child whose parent or guardian also lives with you or any child who has been in hospital continuously for 52 weeks (one year) or more."
          },
          {
            key: "applicant_dependantchildren",
            title: "Do you live with any dependant children?",
            type: "radios",
            condition: conditions.toString('applicant_dependantchildren'),
            description: "A dependant child is one who you or your partner usually get child benefit for. Answer Yes if you are responsible for a child, even if you do not get child benefit for that child. Do not count any child whose parent or guardian also lives with you or any child who has been in hospital continuously for 52 weeks (one year) or more."
          },
          {
            key: "dependantchildren_no",
            title: "How many dependant children live in your home?",
            condition: conditions.toString('dependantchildren_no')
          },
          {
            type: "help",
            helpvalue: "<h3>Child 1</h3>",
            condition: conditions.toString('help_dependant_child1')
          },
          {
            key: "dependantchild1_forename",
            title: "What's the Child's forename(s)?",
            condition: conditions.toString('dependantchild1_forename')
          },
          {
            key: "dependantchild1_surname",
            title: "What is the Child's last name?",
            condition: conditions.toString('dependantchild1_surname')
          },
          {
            key: "dependantchild1_dob",
            title: "What is this child's date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('dependantchild1_dob')
          },
          {
            key: "dependantchild1_gender",
            title: "Is this child a girl or a boy?",
            type: "radios",
            condition: conditions.toString('dependantchild1_gender')
          },
          {
            key: "dependantchild1_relationship_applicant",
            title: "What is the Child's relationship to you?",
            condition: conditions.toString('dependantchild1_relationship_applicant')
          },
          {
            key: "dependantchild1_relationship_partner",
            title: "What is the Child's relationship to your partner?",
            condition: conditions.toString('dependantchild1_relationship_partner')
          },
          {
            key: "dependantchild1_cb_applicant",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "Someone else does"],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild1_cb_applicant')
          },
          {
            key: "dependantchild1_cb_partner",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "My partner does",
              "Someone else does"
            ],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild1_cb_partner')
          },
          {
            key: "dependantchild1_applicantaddress",
            title: "Is the Child's usual address different to yours?",
            type: "radios",
            condition: conditions.toString('dependantchild1_applicantaddress')
          },
          {
            key: "dependantchild1_coupleaddress",
            title: "Is the Child's usual address different to yours or your partners?",
            type: "radios",
            condition: conditions.toString('dependantchild1_coupleaddress')
          },
          {
            key: "dependantchild1_addressline1",
            title: "What address does the Child usually live at? Address Line 1",
            condition: conditions.toString('dependantchild1_addressline1')
          },
          {
            key: "dependantchild1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_addressline2')
          },
          {
            key: "dependantchild1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_addressline3')
          },
          {
            key: "dependantchild1_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_postcode')
          },
          {
            key: "dependantchild1_childcare_couple",
            title: "Do you or your partner pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild1_childcare_couple')
          },
          {
            key: "dependantchild1_childcare_applicant",
            title: "Do you pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild1_childcare_applicant')
          },
          {
            key: "dependantchild1_childcare_number",
            title: "How many childcare providers do you pay?",
            condition: conditions.toString('dependantchild1_childcare_number')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 1</h3>",
            condition: conditions.toString('help_dependantchild1_child_care_1')
          },
          {
            key: "dependantchild1_childcare1_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild1_childcare1_provider')
          },
          {
            key: "dependantchild1_childcare1_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild1_childcare1_addressline1')
          },
          {
            key: "dependantchild1_childcare1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_childcare1_addressline2')
          },
          {
            key: "dependantchild1_childcare1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_childcare1_addressline3')
          },
          {
            key: "dependantchild1_childcare1_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_childcare1_postcode')
          },
          {
            key: "dependantchild1_childcare1_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild1_childcare1_tel')
          },
          {
            key: "dependantchild1_childcare1_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild1_childcare1_ofsted')
          },
          {
            key: "dependantchild1_childcare1_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild1_childcare1_amount'),
            description: "If you do not pay the same amount each week put how much you pay on average?"
          },
          {
            key: "dependantchild1_childcare1_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild1_childcare1_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 2</h3>",
            condition: conditions.toString('help_dependantchild1_child_care_2')
          },
          {
            key: "dependantchild1_childcare2_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild1_childcare2_provider')
          },
          {
            key: "dependantchild1_childcare2_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild1_childcare2_addressline1')
          },
          {
            key: "dependantchild1_childcare2_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_childcare2_addressline2')
          },
          {
            key: "dependantchild1_childcare2_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_childcare2_addressline3')
          },
          {
            key: "dependantchild1_childcare2_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_childcare2_postcode')
          },
          {
            key: "dependantchild1_childcare2_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild1_childcare2_tel')
          },
          {
            key: "dependantchild1_childcare2_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild1_childcare2_ofsted')
          },
          {
            key: "dependantchild1_childcare2_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild1_childcare2_amount')
          },
          {
            key: "dependantchild1_childcare2_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild1_childcare2_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 3</h3>",
            condition: conditions.toString('fieldset_dependantchild1_child_care_3')
          },
          {
            key: "dependantchild1_childcare3_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild1_childcare3_provider')
          },
          {
            key: "dependantchild1_childcare3_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild1_childcare3_addressline1')
          },
          {
            key: "dependantchild1_childcare3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_childcare3_addressline2')
          },
          {
            key: "dependantchild1_childcare3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_childcare3_addressline3')
          },
          {
            key: "dependantchild1_childcare3_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_childcare3_postcode')
          },
          {
            key: "dependantchild1_childcare3_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild1_childcare3_tel')
          },
          {
            key: "dependantchild1_childcare3_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild1_childcare3_ofsted')
          },
          {
            key: "dependantchild1_childcare3_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild1_childcare3_amount')
          },
          {
            key: "dependantchild1_childcare3_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild1_childcare3_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 4</h3>",
            condition: conditions.toString('fieldset_dependantchild1_child_care_4')
          },
          {
            key: "dependantchild1_childcare4_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild1_childcare4_provider')
          },
          {
            key: "dependantchild1_childcare4_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild1_childcare4_addressline1')
          },
          {
            key: "dependantchild1_childcare4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_childcare4_addressline2')
          },
          {
            key: "dependantchild1_childcare4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_childcare4_addressline3')
          },
          {
            key: "dependantchild1_childcare4_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_childcare4_postcode')
          },
          {
            key: "dependantchild1_childcare4_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild1_childcare4_tel')
          },
          {
            key: "dependantchild1_childcare4_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild1_childcare4_ofsted')
          },
          {
            key: "dependantchild1_childcare4_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild1_childcare4_amount')
          },
          {
            key: "dependantchild1_childcare4_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild1_childcare4_frequency')
          },
          {
            key: "dependantchild1_blind",
            title: "Is this child registered blind?",
            type: "radios",
            condition: conditions.toString('dependantchild1_blind')
          },
          {
            key: "dependantchild1_dla_applicant",
            title: "Do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild1_dla_applicant')
          },
          {
            key: "dependantchild1_dla_couple",
            title: "Do you or your partner get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild1_dla_couple')
          },
          {
            key: "dependantchild1_dlarate",
            title: "How often do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild1_dlarate')
          },
          {
            key: "dependantchild1_dlacare4weeks",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP.",
            condition: conditions.toString('dependantchild1_dlacare4weeks')
          },
          {
            key: "dependantchild1_dlacareweek",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP.",
            condition: conditions.toString('dependantchild1_dlacareweek')
          },
          {
            key: "dependantchild1_dlamot4weeks",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP.",
            condition: conditions.toString('dependantchild1_dlamot4weeks')
          },
          {
            key: "dependantchild1_dlamotweek",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP.",
            condition: conditions.toString('dependantchild1_dlamotweek')
          },
          {
            key: "dependantchild1_pip",
            title: "Do they get a Personal Independence Payment",
            type: "radios",
            condition: conditions.toString('dependantchild1_pip')
          },
          {
            key: "dependantchild1_pipdaily",
            title: "What rate of this childs Personal Independence Payment is for Daily Living?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP.",
            condition: conditions.toString('dependantchild1_pipdaily')
          },
          {
            key: "dependantchild1_pipmot",
            title: "What rate of this childs Personal Independence Payment is for Mobility?",
            type: "select",
            description: "If you're not sure, check the award letter from the DWP.",
            condition: conditions.toString('dependantchild1_pipmot')
          },
          {
            key: "dependantchild1_bedroom",
            title: "Is this child unable to share a bedroom because of their disability?",
            type: "radios",
            description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate.",
            condition: conditions.toString('dependantchild1_bedroom')
          },
          {
            key: "dependantchild1_school",
            title: "Does this Child attend school in the Kirklees area?",
            type: "radios",
            condition: conditions.toString('dependantchild1_school')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/child1SchoolAddressLookup.html",

            condition: conditions.toString('template_dependantchild1_address_lookup')
          },
          {
            key: "dependantchild1_kirkleesschool",
            title: "School name",
            condition: conditions.toString('dependantchild1_kirkleesschool')
          },
          {
            key: "dependantchild1_kirkleesschool_addressline1",
            title: "Address Line 1",
            condition: conditions.toString('dependantchild1_kirkleesschool_addressline1')
          },
          {
            key: "dependantchild1_kirkleesschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_kirkleesschool_addressline2')
          },
          {
            key: "dependantchild1_kirkleesschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_kirkleesschool_addressline3')
          },
          {
            key: "dependantchild1_kirkleesschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_kirkleesschool_postcode')
          },
          {
            key: "dependantchild1_otherschool_name",
            title: "What's the name of the school they attend?",
            condition: conditions.toString('dependantchild1_otherschool_name')
          },
          {
            key: "dependantchild1_otherschool_addressline1",
            title: "Address Line 1",
            condition: conditions.toString('dependantchild1_otherschool_addressline1')
          },
          {
            key: "dependantchild1_otherschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild1_otherschool_addressline2')
          },
          {
            key: "dependantchild1_otherschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild1_otherschool_addressline3')
          },
          {
            key: "dependantchild1_otherschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild1_otherschool_postcode')
          },
          {
            type: "help",
            helpvalue: "<h3>Child 2</h3>",
            condition: conditions.toString('help_dependantchild2')
          },
          {
            key: "dependantchild2_forename",
            title: "What's the Child's forename(s)?",
            condition: conditions.toString('dependantchild2_forename')
          },
          {
            key: "dependantchild2_surname",
            title: "What is the Child's last name?",
            condition: conditions.toString('dependantchild2_surname')
          },
          {
            key: "dependantchild2_dob",
            title: "What is this child's date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('dependantchild2_dob')
          },
          {
            key: "dependantchild2_gender",
            title: "Is this child a girl or a boy?",
            type: "radios",
            condition: conditions.toString('dependantchild2_gender')
          },
          {
            key: "dependantchild2_relationship_applicant",
            title: "What is the Child's relationship to you?",
            condition: conditions.toString('dependantchild2_relationship_applicant')
          },
          {
            key: "dependantchild2_relationship_partner",
            title: "What is the Child's relationship to your partner?",
            condition: conditions.toString('dependantchild2_relationship_partner')
          },
          {
            key: "dependantchild2_cb_applicant",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "Someone else does"],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild2_cb_applicant')
          },
          {
            key: "dependantchild2_cb_partner",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "My partner does",
              "Someone else does"
            ],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild2_cb_partner')
          },
          {
            key: "dependantchild2_applicantaddress",
            title: "Is the Child's usual address different to yours?",
            type: "radios",
            condition: conditions.toString('dependantchild2_applicantaddress')
          },
          {
            key: "dependantchild2_coupleaddress",
            title: "Is the Child's usual address different to yours or your partners?",
            type: "radios",
            condition: conditions.toString('dependantchild2_coupleaddress')
          },
          {
            key: "dependantchild2_addressline1",
            title: "What address does the Child usually live at? Address Line 1",
            condition: conditions.toString('dependantchild2_addressline1')
          },
          {
            key: "dependantchild2_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_addressline2')
          },
          {
            key: "dependantchild2_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_addressline3')
          },
          {
            key: "dependantchild2_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_postcode')
          },
          {
            key: "dependantchild2_childcare_couple",
            title: "Do you or your partner pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild2_childcare_couple')
          },
          {
            key: "dependantchild2_childcare_applicant",
            title: "Do you pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild2_childcare_applicant')
          },
          {
            key: "dependantchild2_childcare_number",
            title: "How many childcare providers do you pay?",
            condition: conditions.toString('dependantchild2_childcare_number')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 1</h3>",
            condition: conditions.toString('help_dependantchild2_child_care_1')
          },
          {
            key: "dependantchild2_childcare1_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild2_childcare1_provider')
          },
          {
            key: "dependantchild2_childcare1_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild2_childcare1_addressline1')
          },
          {
            key: "dependantchild2_childcare1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_childcare1_addressline2')
          },
          {
            key: "dependantchild2_childcare1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_childcare1_addressline3')
          },
          {
            key: "dependantchild2_childcare1_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_childcare1_postcode')
          },
          {
            key: "dependantchild2_childcare1_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild2_childcare1_tel')
          },
          {
            key: "dependantchild2_childcare1_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild2_childcare1_ofsted')
          },
          {
            key: "dependantchild2_childcare1_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild2_childcare1_amount')
          },
          {
            key: "dependantchild2_childcare1_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild2_childcare1_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 2</h3>",
            condition: conditions.toString('help_dependantchild2_child_care_2')
          },
          {
            key: "dependantchild2_childcare2_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild2_childcare2_provider')
          },
          {
            key: "dependantchild2_childcare2_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild2_childcare2_addressline1')
          },
          {
            key: "dependantchild2_childcare2_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_childcare2_addressline2')
          },
          {
            key: "dependantchild2_childcare2_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_childcare2_addressline3')
          },
          {
            key: "dependantchild2_childcare2_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_childcare2_postcode')
          },
          {
            key: "dependantchild2_childcare2_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild2_childcare2_tel')
          },
          {
            key: "dependantchild2_childcare2_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild2_childcare2_ofsted')
          },
          {
            key: "dependantchild2_childcare2_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild2_childcare2_amount')
          },
          {
            key: "dependantchild2_childcare2_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild2_childcare2_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 3</h3>",
            condition: conditions.toString('help_dependantchild2_child_care_3')
          },
          {
            key: "dependantchild2_childcare3_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild2_childcare3_provider')
          },
          {
            key: "dependantchild2_childcare3_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild2_childcare3_addressline1')
          },
          {
            key: "dependantchild2_childcare3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_childcare3_addressline2')
          },
          {
            key: "dependantchild2_childcare3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_childcare3_addressline3')
          },
          {
            key: "dependantchild2_childcare3_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_childcare3_postcode')
          },
          {
            key: "dependantchild2_childcare3_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild2_childcare3_tel')
          },
          {
            key: "dependantchild2_childcare3_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild2_childcare3_ofsted')
          },
          {
            key: "dependantchild2_childcare3_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild2_childcare3_amount')
          },
          {
            key: "dependantchild2_childcare3_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild2_childcare3_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 4</h3>",
            condition: conditions.toString('help_dependantchild2_child_care_4')
          },
          {
            key: "dependantchild2_childcare4_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild2_childcare4_provider')
          },
          {
            key: "dependantchild2_childcare4_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild2_childcare4_addressline1')
          },
          {
            key: "dependantchild2_childcare4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_childcare4_addressline2')
          },
          {
            key: "dependantchild2_childcare4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_childcare4_addressline3')
          },
          {
            key: "dependantchild2_childcare4_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_childcare4_postcode')
          },
          {
            key: "dependantchild2_childcare4_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild2_childcare4_tel')
          },
          {
            key: "dependantchild2_childcare4_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild2_childcare4_ofsted')
          },
          {
            key: "dependantchild2_childcare4_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild2_childcare4_amount')
          },
          {
            key: "dependantchild2_childcare4_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild2_childcare4_frequency')
          },
          {
            key: "dependantchild2_blind",
            title: "Is this child registered blind?",
            type: "radios",
            condition: conditions.toString('dependantchild2_blind')
          },
          {
            key: "dependantchild2_dla_applicant",
            title: "Do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild2_dla_applicant')
          },
          {
            key: "dependantchild2_dla_couple",
            title: "Do you or your partner get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild2_dla_couple')
          },
          {
            key: "dependantchild2_dlarate",
            title: "How often do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild2_dlarate')
          },
          {
            key: "dependantchild2_dlacare4weeks",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            condition: conditions.toString('dependantchild2_dlacare4weeks')
          },
          {
            key: "dependantchild2_dlacareweek",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            condition: conditions.toString('dependantchild2_dlacareweek')
          },
          {
            key: "dependantchild2_dlamot4weeks",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            condition: conditions.toString('dependantchild2_dlamot4weeks')
          },
          {
            key: "dependantchild2_dlamotweek",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            condition: conditions.toString('dependantchild2_dlamotweek')
          },
          {
            key: "dependantchild2_pip",
            title: "Do they get a Personal Independence Payment",
            type: "radios",
            condition: conditions.toString('dependantchild2_pip')
          },
          {
            key: "dependantchild2_pipdaily",
            title: "What rate of this childs Personal Independence Payment is for Daily Living?",
            type: "select",
            condition: conditions.toString('dependantchild2_pipdaily')
          },
          {
            key: "dependantchild2_pipmot",
            title: "What rate of this childs Personal Independence Payment is for Mobility?",
            type: "select",
            condition: conditions.toString('dependantchild2_pipmot')
          },
          {
            key: "dependantchild2_bedroom",
            title: "Is this child unable to share a bedroom because of their disability?",
            type: "radios",
            condition: conditions.toString('dependantchild2_bedroom')
          },
          {
            key: "dependantchild2_school",
            title: "Does this Child attend school in the Kirklees area?",
            type: "radios",
            condition: conditions.toString('dependantchild2_school')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/child2SchoolAddressLookup.html",
            condition: conditions.toString('template_dependantchild2_address_lookup')
          },
          {
            key: "dependantchild2_kirkleesschool",
            title: "School name",
            condition: conditions.toString('dependantchild2_kirkleesschool')
          },
          {
            key: "dependantchild2_kirkleesschool_addressline1",
            title: "Address Line 1",
            condition: conditions.toString('dependantchild2_kirkleesschool_addressline1')
          },
          {
            key: "dependantchild2_kirkleesschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_kirkleesschool_addressline2')
          },
          {
            key: "dependantchild2_kirkleesschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_kirkleesschool_addressline3')
          },
          {
            key: "dependantchild2_kirkleesschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_kirkleesschool_postcode')
          },
          {
            key: "dependantchild2_otherschool_name",
            title: "What's the name of the school they attend?",
            condition: conditions.toString('dependantchild2_otherschool_name')
          },
          {
            key: "dependantchild2_otherschool_addressline1",
            title: "What's the address of the school they attend? Address Line 1",
            condition: conditions.toString('dependantchild2_otherschool_addressline1')
          },
          {
            key: "dependantchild2_otherschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild2_otherschool_addressline2')
          },
          {
            key: "dependantchild2_otherschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild2_otherschool_addressline3')
          },
          {
            key: "dependantchild2_otherschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild2_otherschool_postcode')
          },
          {
            type: "help",
            helpvalue: "<h3>Child 3</h3>",
            condition: conditions.toString('help_dependantchild3')
          },
          {
            key: "dependantchild3_forename",
            title: "What's the Child's forename(s)?",
            condition: conditions.toString('dependantchild3_forename')
          },
          {
            key: "dependantchild3_surname",
            title: "What is the Child's last name?",
            condition: conditions.toString('dependantchild3_surname')
          },
          {
            key: "dependantchild3_dob",
            title: "What is this child's date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('dependantchild3_dob')
          },
          {
            key: "dependantchild3_gender",
            title: "Is this child a girl or a boy?",
            type: "radios",
            condition: conditions.toString('dependantchild3_gender')
          },
          {
            key: "dependantchild3_relationship_applicant",
            title: "What is the Child's relationship to you?",
            condition: conditions.toString('dependantchild3_relationship_applicant')
          },
          {
            key: "dependantchild3_relationship_partner",
            title: "What is the Child's relationship to your partner?",
            condition: conditions.toString('dependantchild3_relationship_partner')
          },
          {
            key: "dependantchild3_cb_applicant",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "Someone else does"],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild3_cb_applicant')
          },
          {
            key: "dependantchild3_cb_partner",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "My partner does",
              "Someone else does"
            ],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild3_cb_partner')
          },
          {
            key: "dependantchild3_applicantaddress",
            title: "Is the Child's usual address different to yours?",
            type: "radios",
            condition: conditions.toString('dependantchild3_applicantaddress')
          },
          {
            key: "dependantchild3_coupleaddress",
            title: "Is the Child's usual address different to yours or your partners?",
            type: "radios",
            condition: conditions.toString('dependantchild3_coupleaddress')
          },
          {
            key: "dependantchild3_addressline1",
            title: "What address does the Child usually live at? Address Line 1",
            condition: conditions.toString('dependantchild3_addressline1')
          },
          {
            key: "dependantchild3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_addressline2')
          },
          {
            key: "dependantchild3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_addressline3')
          },
          {
            key: "dependantchild3_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_postcode')
          },
          {
            key: "dependantchild3_childcare_couple",
            title: "Do you or your partner pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild3_childcare_couple')
          },
          {
            key: "dependantchild3_childcare_applicant",
            title: "Do you pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild3_childcare_applicant')
          },
          {
            key: "dependantchild3_childcare_number",
            title: "How many childcare providers do you pay?",
            condition: conditions.toString('dependantchild3_childcare_number')
          },

          {
            type: "help",
            helpvalue: "<h3>Child Care 1</h3>",
            condition: conditions.toString('help_dependantchild3_child_care_1')
          },
          {
            key: "dependantchild3_childcare1_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild3_childcare1_provider')
          },
          {
            key: "dependantchild3_childcare1_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild3_childcare1_addressline1')
          },
          {
            key: "dependantchild3_childcare1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_childcare1_addressline2')
          },
          {
            key: "dependantchild3_childcare1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_childcare1_addressline3')
          },
          {
            key: "dependantchild3_childcare1_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_childcare1_postcode')
          },
          {
            key: "dependantchild3_childcare1_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild3_childcare1_tel')
          },
          {
            key: "dependantchild3_childcare1_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild3_childcare1_ofsted')
          },
          {
            key: "dependantchild3_childcare1_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild3_childcare1_amount')
          },
          {
            key: "dependantchild3_childcare1_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild3_childcare1_frequency')
          },

          {
            type: "help",
            helpvalue: "<h3>Child Care 2</h3>",
            condition: conditions.toString('help_dependantchild3_child_care_2')
          },
          {
            key: "dependantchild3_childcare2_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild3_childcare2_provider')
          },
          {
            key: "dependantchild3_childcare2_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild3_childcare2_addressline1')
          },
          {
            key: "dependantchild3_childcare2_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_childcare2_addressline2')
          },
          {
            key: "dependantchild3_childcare2_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_childcare2_addressline3')
          },
          {
            key: "dependantchild3_childcare2_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_childcare2_postcode')
          },
          {
            key: "dependantchild3_childcare2_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild3_childcare2_tel')
          },
          {
            key: "dependantchild3_childcare2_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild3_childcare2_ofsted')
          },
          {
            key: "dependantchild3_childcare2_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild3_childcare2_amount')
          },
          {
            key: "dependantchild3_childcare2_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild3_childcare2_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 3</h3>",
            condition: conditions.toString('help_dependantchild3_child_care_3')
          },
          {
            key: "dependantchild3_childcare3_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild3_childcare3_provider')
          },
          {
            key: "dependantchild3_childcare3_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild3_childcare3_addressline1')
          },
          {
            key: "dependantchild3_childcare3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_childcare3_addressline2')
          },
          {
            key: "dependantchild3_childcare3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_childcare3_addressline3')
          },
          {
            key: "dependantchild3_childcare3_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_childcare3_postcode')
          },
          {
            key: "dependantchild3_childcare3_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild3_childcare3_tel')
          },
          {
            key: "dependantchild3_childcare3_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild3_childcare3_ofsted')
          },
          {
            key: "dependantchild3_childcare3_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild3_childcare3_amount')
          },
          {
            key: "dependantchild3_childcare3_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild3_childcare3_frequency')
          },
          {
            type: "help",
            helpvalue: "<h3>Child Care 4</h3>",
            condition: conditions.toString('help_dependantchild3_child_care_4')
          },
          {
            key: "dependantchild3_childcare4_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild3_childcare4_provider')
          },
          {
            key: "dependantchild3_childcare4_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild3_childcare4_addressline1')
          },
          {
            key: "dependantchild3_childcare4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_childcare4_addressline2')
          },
          {
            key: "dependantchild3_childcare4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_childcare4_addressline3')
          },
          {
            key: "dependantchild3_childcare4_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_childcare4_postcode')
          },
          {
            key: "dependantchild3_childcare4_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild3_childcare4_tel')
          },
          {
            key: "dependantchild3_childcare4_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild3_childcare4_ofsted')
          },
          {
            key: "dependantchild3_childcare4_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild3_childcare4_amount')
          },
          {
            key: "dependantchild3_childcare4_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild3_childcare4_frequency')
          },
          {
            key: "dependantchild3_blind",
            title: "Is this child registered blind?",
            type: "radios",
            condition: conditions.toString('dependantchild3_blind')
          },
          {
            key: "dependantchild3_dla_applicant",
            title: "Do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild3_dla_applicant')
          },
          {
            key: "dependantchild3_dla_couple",
            title: "Do you or your partner get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild3_dla_couple')
          },
          {
            key: "dependantchild3_dlarate",
            title: "How often do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild3_dlarate')
          },
          {
            key: "dependantchild3_dlacare4weeks",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            condition: conditions.toString('dependantchild3_dlacare4weeks')
          },
          {
            key: "dependantchild3_dlacareweek",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            condition: conditions.toString('dependantchild3_dlacareweek')
          },
          {
            key: "dependantchild3_dlamot4weeks",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            condition: conditions.toString('dependantchild3_dlamot4weeks')
          },
          {
            key: "dependantchild3_dlamotweek",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            condition: conditions.toString('dependantchild3_dlamotweek')
          },
          {
            key: "dependantchild3_pip",
            title: "Do they get a Personal Independence Payment",
            type: "radios",
            condition: conditions.toString('dependantchild3_pip')
          },
          {
            key: "dependantchild3_pipdaily",
            title: "What rate of this childs Personal Independence Payment is for Daily Living?",
            type: "select",
            condition: conditions.toString('dependantchild3_pipdaily')
          },
          {
            key: "dependantchild3_pipmot",
            title: "What rate of this childs Personal Independence Payment is for Mobility?",
            type: "select",
            condition: conditions.toString('dependantchild3_pipmot')
          },
          {
            key: "dependantchild3_bedroom",
            title: "Is this child unable to share a bedroom because of their disability?",
            type: "radios",
            condition: conditions.toString('dependantchild3_bedroom')
          },
          {
            key: "dependantchild3_school",
            title: "Does this Child attend school in the Kirklees area?",
            type: "radios",
            condition: conditions.toString('dependantchild3_school')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/child3SchoolAddressLookup.html",

            condition: conditions.toString('template_dependantchild3_address_lookup')
          },
          {
            key: "dependantchild3_kirkleesschool",
            title: "School name",
            condition: conditions.toString('dependantchild3_kirkleesschool')
          },
          {
            key: "dependantchild3_kirkleesschool_addressline1",
            title: "Address Line 1",
            condition: conditions.toString('dependantchild3_kirkleesschool_addressline1')
          },
          {
            key: "dependantchild3_kirkleesschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_kirkleesschool_addressline2')
          },
          {
            key: "dependantchild3_kirkleesschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_kirkleesschool_addressline3')
          },
          {
            key: "dependantchild3_kirkleesschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_kirkleesschool_postcode')
          },
          {
            key: "dependantchild3_otherschool_name",
            title: "What's the name of the school they attend?",
            condition: conditions.toString('dependantchild3_otherschool_name')
          },
          {
            key: "dependantchild3_otherschool_addressline1",
            title: "What's the address of the school they attend? Address Line 1",
            condition: conditions.toString('dependantchild3_otherschool_addressline1')
          },
          {
            key: "dependantchild3_otherschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild3_otherschool_addressline2')
          },
          {
            key: "dependantchild3_otherschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild3_otherschool_addressline3')
          },
          {
            key: "dependantchild3_otherschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild3_otherschool_postcode')
          },
          {
            type: "help",
            helpvalue: "<h3>Child 4</h3>",
            condition: conditions.toString('help_dependantchild4')
          },
          {
            key: "dependantchild4_forename",
            title: "What's the Child's forename(s)?",
            condition: conditions.toString('dependantchild4_forename')
          },
          {
            key: "dependantchild4_surname",
            title: "What is the Child's last name?",
            condition: conditions.toString('dependantchild4_surname')
          },
          {
            key: "dependantchild4_dob",
            title: "What is this child's date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('dependantchild4_dob')
          },
          {
            key: "dependantchild4_gender",
            title: "Is this child a girl or a boy?",
            type: "radios",
            condition: conditions.toString('dependantchild4_gender')
          },
          {
            key: "dependantchild4_relationship_applicant",
            title: "What is the Child's relationship to you?",
            condition: conditions.toString('dependantchild4_relationship_applicant')
          },
          {
            key: "dependantchild4_relationship_partner",
            title: "What is the Child's relationship to your partner?",
            condition: conditions.toString('dependantchild4_relationship_partner')
          },
          {
            key: "dependantchild4_cb_applicant",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "Someone else does"],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild4_cb_applicant')
          },
          {
            key: "dependantchild4_cb_partner",
            type: "selectWithInput",
            title: "Who gets Child Benefit for this Child?",
            options: ["I do", "My partner does",
              "Someone else does"
            ],
            radios: true,
            other: "Someone else does",
            comment: "Please specify",
            condition: conditions.toString('dependantchild4_cb_partner')
          },
          {
            key: "dependantchild4_applicantaddress",
            title: "Is the Child's usual address different to yours?",
            type: "radios",
            condition: conditions.toString('dependantchild4_applicantaddress')
          },
          {
            key: "dependantchild4_coupleaddress",
            title: "Is the Child's usual address different to yours or your partners?",
            type: "radios",
            condition: conditions.toString('dependantchild4_coupleaddress')
          },
          {
            key: "dependantchild4_addressline1",
            title: "What address does the Child usually live at? Address Line 1",
            condition: conditions.toString('dependantchild4_addressline1')
          },
          {
            key: "dependantchild4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_addressline2')
          },
          {
            key: "dependantchild4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_addressline3')
          },
          {
            key: "dependantchild4_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_postcode')
          },
          {
            key: "dependantchild4_childcare_couple",
            title: "Do you or your partner pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild4_childcare_couple')
          },
          {
            key: "dependantchild4_childcare_applicant",
            title: "Do you pay any childcare costs for this child to a registered childminder, a nursery or an out of school club?",
            type: "radios",
            condition: conditions.toString('dependantchild4_childcare_applicant')
          },
          {
            key: "dependantchild4_childcare_number",
            title: "How many childcare providers do you pay?",
            condition: conditions.toString('dependantchild4_childcare_number')
          },

          {
            type: "help",
            helpvalue: "<h3>Child Care 1</h3>",
            condition: conditions.toString('help_dependantchild4_child_care_1')
          },
          {
            key: "dependantchild4_childcare1_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild4_childcare1_provider')
          },
          {
            key: "dependantchild4_childcare1_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild4_childcare1_addressline1')
          },
          {
            key: "dependantchild4_childcare1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_childcare1_addressline2')
          },
          {
            key: "dependantchild4_childcare1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_childcare1_addressline3')
          },
          {
            key: "dependantchild4_childcare1_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_childcare1_postcode')
          },
          {
            key: "dependantchild4_childcare1_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild4_childcare1_tel')
          },
          {
            key: "dependantchild4_childcare1_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild4_childcare1_ofsted')
          },
          {
            key: "dependantchild4_childcare1_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild4_childcare1_amount')
          },
          {
            key: "dependantchild4_childcare1_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild4_childcare1_frequency')
          },

          {
            type: "help",
            helpvalue: "<h3>Child Care 2</h3>",
            condition: conditions.toString('help_dependantchild4_child_care_2')
          },
          {
            key: "dependantchild4_childcare2_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild4_childcare2_provider')
          },
          {
            key: "dependantchild4_childcare2_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild4_childcare2_addressline1')
          },
          {
            key: "dependantchild4_childcare2_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_childcare2_addressline2')
          },
          {
            key: "dependantchild4_childcare2_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_childcare2_addressline3')
          },
          {
            key: "dependantchild4_childcare2_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_childcare2_postcode')
          },
          {
            key: "dependantchild4_childcare2_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild4_childcare2_tel')
          },
          {
            key: "dependantchild4_childcare2_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild4_childcare2_ofsted')
          },
          {
            key: "dependantchild4_childcare2_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild4_childcare2_amount')
          },
          {
            key: "dependantchild4_childcare2_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild4_childcare2_frequency')
          },

          {
            type: "help",
            helpvalue: "<h3>Child Care 3</h3>",
            condition: conditions.toString('help_dependantchild4_child_care_3')
          },
          {
            key: "dependantchild4_childcare3_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild4_childcare3_provider')
          },
          {
            key: "dependantchild4_childcare3_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild4_childcare3_addressline1')
          },
          {
            key: "dependantchild4_childcare3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_childcare3_addressline2')
          },
          {
            key: "dependantchild4_childcare3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_childcare3_addressline3')
          },
          {
            key: "dependantchild4_childcare3_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_childcare3_postcode')
          },
          {
            key: "dependantchild4_childcare3_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild4_childcare3_tel')
          },
          {
            key: "dependantchild4_childcare3_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild4_childcare3_ofsted')
          },
          {
            key: "dependantchild4_childcare3_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild4_childcare3_amount')
          },
          {
            key: "dependantchild4_childcare3_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild4_childcare3_frequency')
          },

          {
            type: "help",
            helpvalue: "<h3>Child Care 4</h3>",
            condition: conditions.toString('help_dependantchild4_child_care_4')
          },
          {
            key: "dependantchild4_childcare4_provider",
            title: "What is the name of the childcare provider?",
            condition: conditions.toString('dependantchild4_childcare4_provider')
          },
          {
            key: "dependantchild4_childcare4_addressline1",
            title: "What is the address of the childcare provider? Address line 1",
            condition: conditions.toString('dependantchild4_childcare4_addressline1')
          },
          {
            key: "dependantchild4_childcare4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_childcare4_addressline2')
          },
          {
            key: "dependantchild4_childcare4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_childcare4_addressline3')
          },
          {
            key: "dependantchild4_childcare4_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_childcare4_postcode')
          },
          {
            key: "dependantchild4_childcare4_tel",
            title: "Telephone number",
            condition: conditions.toString('dependantchild4_childcare4_tel')
          },
          {
            key: "dependantchild4_childcare4_ofsted",
            title: "What is their OFSTED Registration Number?",
            condition: conditions.toString('dependantchild4_childcare4_ofsted')
          },
          {
            key: "dependantchild4_childcare4_amount",
            title: "How much do you pay?",
            condition: conditions.toString('dependantchild4_childcare4_amount')
          },
          {
            key: "dependantchild4_childcare4_frequency",
            title: "How often?",
            type: "select",
            condition: conditions.toString('dependantchild4_childcare4_frequency')
          },
          {
            key: "dependantchild4_blind",
            title: "Is this child registered blind?",
            type: "radios",
            condition: conditions.toString('dependantchild4_blind')
          },
          {
            key: "dependantchild4_dla_applicant",
            title: "Do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild4_dla_applicant')
          },
          {
            key: "dependantchild4_dla_couple",
            title: "Do you or your partner get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild4_dla_couple')
          },
          {
            key: "dependantchild4_dlarate",
            title: "How often do you get Disability Living Allowance for this child?",
            type: "radios",
            condition: conditions.toString('dependantchild4_dlarate')
          },
          {
            key: "dependantchild4_dlacare4weeks",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            condition: conditions.toString('dependantchild4_dlacare4weeks')
          },
          {
            key: "dependantchild4_dlacareweek",
            title: "How much of the Disability Living Allowance for this child is for care?",
            type: "select",
            condition: conditions.toString('dependantchild4_dlacareweek')
          },
          {
            key: "dependantchild4_dlamot4weeks",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            condition: conditions.toString('dependantchild4_dlamot4weeks')
          },
          {
            key: "dependantchild4_dlamotweek",
            title: "How much of the Disability Living Allowance for this child is for mobility (including any payment to Motability fund)?",
            type: "select",
            condition: conditions.toString('dependantchild4_dlamotweek')
          },
          {
            key: "dependantchild4_pip",
            title: "Do they get a Personal Independence Payment",
            type: "radios",
            condition: conditions.toString('dependantchild4_pip')
          },
          {
            key: "dependantchild4_pipdaily",
            title: "What rate of this childs Personal Independence Payment is for Daily Living?",
            type: "select",
            condition: conditions.toString('dependantchild4_pipdaily')
          },
          {
            key: "dependantchild4_pipmot",
            title: "What rate of this childs Personal Independence Payment is for Mobility?",
            type: "select",
            condition: conditions.toString('dependantchild4_pipmot')
          },
          {
            key: "dependantchild4_bedroom",
            title: "Is this child unable to share a bedroom because of their disability?",
            type: "radios",
            condition: conditions.toString('dependantchild4_bedroom')
          },
          {
            key: "dependantchild4_school",
            title: "Does this Child attend school in the Kirklees area?",
            type: "radios",
            condition: conditions.toString('dependantchild4_school')
          },
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/child4SchoolAddressLookup.html",

            condition: conditions.toString('template_dependantchild4_address_lookup')
          },
          {
            key: "dependantchild4_kirkleesschool",
            title: "School name",
            condition: conditions.toString('dependantchild4_kirkleesschool')
          },
          {
            key: "dependantchild4_kirkleesschool_addressline1",
            title: "Address Line 1",
            condition: conditions.toString('dependantchild4_kirkleesschool_addressline1')
          },
          {
            key: "dependantchild4_kirkleesschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_kirkleesschool_addressline2')
          },
          {
            key: "dependantchild4_kirkleesschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_kirkleesschool_addressline3')
          },
          {
            key: "dependantchild4_kirkleesschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_kirkleesschool_postcode')
          },
          {
            key: "dependantchild4_otherschool_name",
            title: "What's the name of the school they attend?",
            condition: conditions.toString('dependantchild4_otherschool_name')
          },
          {
            key: "dependantchild4_otherschool_addressline1",
            title: "What's the address of the school they attend? Address Line 1",
            condition: conditions.toString('dependantchild4_otherschool_addressline1')
          },
          {
            key: "dependantchild4_otherschool_addressline2",
            title: "Address line 2",
            condition: conditions.toString('dependantchild4_otherschool_addressline2')
          },
          {
            key: "dependantchild4_otherschool_addressline3",
            title: "Address line 3",
            condition: conditions.toString('dependantchild4_otherschool_addressline3')
          },
          {
            key: "dependantchild4_otherschool_postcode",
            title: "Postcode",
            condition: conditions.toString('dependantchild4_otherschool_postcode')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 24",
        page: 24,
        title: "Other Adults",
        items: [
          {
            type: "help",
            helpvalue: "<p>Other adults in your household</p><ul><li>This question is about people over 16 who live in your home and who you do not get Child Benefit for. For example they could be your grown up son, an elderly relative or a long-term friend. They might be a joint tenant with you whose name is on your tenancy agreement, or someone who pays you 'board' or 'keep'.</li></ul><p>Do NOT include anyone who:</p><ul><li>is your wife, husband or same sex partner (you should have already told us about this person),</li><li>is a dependant child you or your partner get Child Benefit for; or</li><li>has a separate tenancy agreement with the landlord (in a shared house for example); or</li><li>is in legal custody (such as prison, on remand, or young offender institution); or</li><li>has been in hospital for 52 weeks (one year) or more.</li></ul>"
          },
          {
            key: "other_adults",
            title: "Do any other adults live in your home?",
            type: "select"
          },
          {
            type: 'section',
            htmlClass: "invisible",
            items: [
              {
                key: "other_adultsno",
                title: "How many other adults are there in your home?",
                type: "number",
                condition: conditions.toString('other_adultsno')
              },
              {
                key: "jointtenants",
                title: "How many of these other adults are joint tenants?",
                type: "number",
                condition: conditions.toString('jointtenants')
              },
              {
                key: "boarders",
                title: "How many of these other adults are Boarders, Lodgers or Sub-Tenants?",
                type: "number",
                condition: conditions.toString('boarders')
              },
              {
                key: "nondependants",
                title: "How many of these other adults are Non-dependants?",
                type: "number",
                condition: conditions.toString('nondependants')
              }]
          },
          {
            type: "sumOfAdults",
            condition: conditions.toString('sum_of_adults_other_adults')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 25",
        page: 25,
        items: [
          {
            type: "help",
            helpvalue: "<h2>Joint Tenants</h2>",
            condition: conditions.toString('has_joint_tenant')
          },
          {
            type: "help",
            helpvalue: "This section is about The Joint Tenants in your Household",
            condition: conditions.toString('has_joint_tenant')
          },
          {
            type: "help",
            helpvalue: "<h3>Joint Tenant 1</h3>",
            condition: conditions.toString('help_joint_tenant_1')
          },
          {
            key: "jointtenant1_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('jointtenant1_forename')
          },
          {
            key: "jointtenant1_surname",
            title: "What is their last name?",
            condition: conditions.toString('jointtenant1_surname')
          },
          {
            key: "jointtenant1_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('jointtenant1_dob')
          },
          {
            key: "jointtenant1_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('jointtenant1_gender')
          },
          {
            key: "jointtenant1_relationship_applicant",
            title: "What is this persons relationship to you?",
            condition: conditions.toString('jointtenant1_relationship_applicant')
          },
          {
            key: "jointtenant1_relationship_couple",
            title: "What is this persons relationship to you or your partner",
            condition: conditions.toString('jointtenant1_relationship_couple')
          },
          {
            key: "jointtenant1_relationship_otheradults",
            title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('jointtenant1_relationship_otheradults')
          },
          {
            key: "jointtenant1_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('jointtenant1_relationship_otheradults_name')
          },
          {
            key: "jointtenant1_student",
            type: "radios",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            condition: conditions.toString('jointtenant1_student')
          },
          {
            key: "jointtenant1_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('jointtenant1_mental')
          },
          {
            key: "jointtenant1_blind",
            title: "Is this person registered blind",
            type: "radios",
            condition: conditions.toString('jointtenant1_blind')
          },
          {
            type: "help",
            helpvalue: "<h3>Joint Tenant 2</h3>",
            condition: conditions.toString('help_joint_tenant_2')
          },
          {
            key: "jointtenant2_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('jointtenant2_forename')
          },
          {
            key: "jointtenant2_surname",
            title: "What is their last name?",
            condition: conditions.toString('jointtenant2_surname')
          },
          {
            key: "jointtenant2_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('jointtenant2_dob')
          },
          {
            key: "jointtenant2_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('jointtenant2_gender')
          },
          {
            key: "jointtenant2_relationship_applicant",
            title: "What is this persons relationship to you?",
            condition: conditions.toString('jointtenant2_relationship_applicant')
          },
          {
            key: "jointtenant2_relationship_couple",
            title: "What is this persons relationship to you or your partner",
            condition: conditions.toString('jointtenant2_relationship_couple')
          },
          {
            key: "jointtenant2_relationship_otheradults",
            title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('jointtenant2_relationship_otheradults')
          },
          {
            key: "jointtenant2_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('jointtenant2_relationship_otheradults_name')
          },
          {
            key: "jointtenant2_student",
            type: "radios",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            condition: conditions.toString('jointtenant2_student')
          },
          {
            key: "jointtenant2_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('jointtenant2_mental')
          },
          {
            key: "jointtenant2_blind",
            title: "Is this person registered blind",
            type: "radios",
            condition: conditions.toString('jointtenant2_blind')
          },
          {
            type: "help",
            helpvalue: "<h3>Joint Tenant 3</h3>",
            condition: conditions.toString('help_joint_tenant_3')
          },
          {
            key: "jointtenant3_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('jointtenant3_forename')
          },
          {
            key: "jointtenant3_surname",
            title: "What is their last name?",
            condition: conditions.toString('jointtenant3_surname')
          },
          {
            key: "jointtenant3_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('jointtenant3_dob')
          },
          {
            key: "jointtenant3_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('jointtenant3_gender')
          },
          {
            key: "jointtenant3_relationship_applicant",
            title: "What is this persons relationship to you?",
            condition: conditions.toString('jointtenant3_relationship_applicant')
          },
          {
            key: "jointtenant3_relationship_couple",
            title: "What is this persons relationship to you or your partner",
            condition: conditions.toString('jointtenant3_relationship_couple')
          },
          {
            key: "jointtenant3_relationship_otheradults",
            title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('jointtenant3_relationship_otheradults')
          },
          {
            key: "jointtenant3_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('jointtenant3_relationship_otheradults_name')
          },
          {
            key: "jointtenant3_student",
            type: "radios",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            condition: conditions.toString('jointtenant3_student')
          },
          {
            key: "jointtenant3_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('jointtenant3_mental')
          },
          {
            key: "jointtenant3_blind",
            title: "Is this person registered blind",
            type: "radios",
            condition: conditions.toString('jointtenant3_blind')
          },
          {
            type: "help",
            helpvalue: "<h3>Joint Tenant 4</h3>",
            condition: conditions.toString('help_joint_tenant_4')

          },
          {
            key: "jointtenant4_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('jointtenant4_forename')
          },
          {
            key: "jointtenant4_surname",
            title: "What is their last name?",
            condition: conditions.toString('jointtenant4_surname')
          },
          {
            key: "jointtenant4_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('jointtenant4_dob')
          },
          {
            key: "jointtenant4_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('jointtenant4_gender')
          },
          {
            key: "jointtenant4_relationship_applicant",
            title: "What is this person's relationship to you?",
            condition: conditions.toString('jointtenant4_relationship_applicant')
          },
          {
            key: "jointtenant4_relationship_couple",
            title: "What is this person's relationship to you or your partner?",
            condition: conditions.toString('jointtenant4_relationship_couple')
          },
          {
            key: "jointtenant4_relationship_otheradults",
            title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('jointtenant4_relationship_otheradults')
          },
          {
            key: "jointtenant4_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('jointtenant4_relationship_otheradults_name')
          },
          {
            key: "jointtenant4_student",
            type: "radios",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            condition: conditions.toString('jointtenant4_student')
          },
          {
            key: "jointtenant4_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('jointtenant4_mental')
          },
          {
            key: "jointtenant4_blind",
            title: "Is this person registered blind",
            type: "radios",
            condition: conditions.toString('jointtenant4_blind')
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 26",
        page: 26,
        items: [
          {
            type: "help",
            helpvalue: "<h2>This section is about The Boarders, Lodgers and Sub-Tenants in your Household</h2>",
            condition: conditions.toString(['has_other_adults', 'has_boarder'])
          },
          {
            type: "help",
            helpvalue: "<h3>boarder 1</h3>",
            condition: conditions.toString('help_boarder_1')
          },
          {
            key: "boarder1_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('boarder1_forename')
          },
          {
            key: "boarder1_surname",
            title: "What is their last name?",
            condition: conditions.toString('boarder1_surname')
          },
          {
            key: "boarder1_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder1_dob')
          },
          {
            key: "boarder1_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('boarder1_gender')
          },
          {
            key: "boarder1_relationship_applicant",
            title: "What is this persons relationship to you?",
            condition: conditions.toString('boarder1_relationship_applicant')
          },
          {
            key: "boarder1_relationship_couple",
            title: "What is this person's relationship to you or your partner",
            condition: conditions.toString('boarder1_relationship_couple')
          },
          {
            key: "boarder1_relationship_otheradults",
            title: "Is this person living with any other adult who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('boarder1_relationship_otheradults')
          },
          {
            key: "boarder1_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('boarder1_relationship_otheradults_name')
          },
          {
            key: "boarder1_student",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            type: "radios",
            condition: conditions.toString('boarder1_student')
          },
          {
            key: "boarder1_rentamount",
            title: "How much per week do they pay?",
            condition: conditions.toString('boarder1_rentamount')
          },
          {
            key: "boarder1_rentheating",
            title: "Does this include money for food or heating?",
            type: "radios",
            condition: conditions.toString('boarder1_rentheating')
          },
          {
            key: "boarder1_custody",
            title: "Are they in legal custody at the moment?",
            type: "radios",
            condition: conditions.toString('boarder1_custody')
          },
          {
            key: "boarder1_custody_released",
            title: "When will they be released?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder1_custody_released')
          },
          {
            key: "boarder1_hospital",
            title: "Are they in hospital at the moment?",
            type: "radios",
            condition: conditions.toString('boarder1_hospital')
          },
          {
            key: "boarder1_hospital_in",
            title: "When did they go into hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder1_hospital_in')
          },
          {
            key: "boarder1_hospital_knownout",
            title: "Do you know when they are due to come out of hospital?",
            type: "radios",
            condition: conditions.toString('boarder1_hospital_knownout')
          },
          {
            key: "boarder1_hospital_out",
            title: "When are they due to come out of hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder1_hospital_out')
          },
          {
            key: "boarder1_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('boarder1_mental')
          },
          {
            type: "help",
            helpvalue: "<h3>boarder 2</h3>",
            condition: conditions.toString('help_boarder_2')
          },
          {
            key: "boarder2_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('boarder2_forename')
          },
          {
            key: "boarder2_surname",
            title: "What is their last name?",
            condition: conditions.toString('boarder2_surname')
          },
          {
            key: "boarder2_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder2_dob')
          },
          {
            key: "boarder2_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('boarder2_gender')
          },
          {
            key: "boarder2_relationship_applicant",
            title: "What is this person's relationship to you?",
            condition: conditions.toString('boarder2_relationship_applicant')
          },
          {
            key: "boarder2_relationship_couple",
            title: "What is this person's relationship to you or your partner?",
            condition: conditions.toString('boarder2_relationship_couple')
          },
          {
            key: "boarder2_relationship_otheradults",
            title: "Is this person living with any other adult who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('boarder2_relationship_otheradults')
          },
          {
            key: "boarder2_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('boarder2_relationship_otheradults_name')
          },
          {
            key: "boarder2_student",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            type: "radios",
            condition: conditions.toString('boarder2_student')
          },
          {
            key: "boarder2_rentamount",
            title: "How much per week do they pay?",
            condition: conditions.toString('boarder2_rentamount')
          },
          {
            key: "boarder2_rentheating",
            title: "Does this include money for food or heating?",
            type: "radios",
            condition: conditions.toString('boarder2_rentheating')
          },
          {
            key: "boarder2_custody",
            title: "Are they in legal custody at the moment?",
            type: "radios",
            condition: conditions.toString('boarder2_custody')
          },
          {
            key: "boarder2_custody_released",
            title: "When will they be released?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder2_custody_released')
          },
          {
            key: "boarder2_hospital",
            title: "Are they in hospital at the moment?",
            type: "radios",
            condition: conditions.toString('boarder2_hospital')
          },
          {
            key: "boarder2_hospital_in",
            title: "When did they go into hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder2_hospital_in')
          },
          {
            key: "boarder2_hospital_knownout",
            title: "Do you know when they are due to come out of hospital?",
            type: "radios",
            condition: conditions.toString('boarder2_hospital_knownout')
          },
          {
            key: "boarder2_hospital_out",
            title: "When are they due to come out of hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder2_hospital_out')
          },
          {
            key: "boarder2_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('boarder2_mental')
          },
          {
            type: "help",
            helpvalue: "<h3>boarder 3</h3>",
            condition: conditions.toString('help_boarder_3')

          },
          {
            key: "boarder3_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('boarder3_forename')
          },
          {
            key: "boarder3_surname",
            title: "What is their last name?",
            condition: conditions.toString('boarder3_surname')
          },
          {
            key: "boarder3_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder3_dob')
          },
          {
            key: "boarder3_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('boarder3_gender')
          },
          {
            key: "boarder3_relationship_applicant",
            title: "What is this person's relationship to you?",
            condition: conditions.toString('boarder3_relationship_applicant')
          },
          {
            key: "boarder3_relationship_couple",
            title: "What is this person's relationship to you or your partner?",
            condition: conditions.toString('boarder3_relationship_couple')
          },
          {
            key: "boarder3_relationship_otheradults",
            title: "Is this person living with any other adult who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('boarder3_relationship_otheradults')
          },
          {
            key: "boarder3_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('boarder3_relationship_otheradults_name')
          },
          {
            key: "boarder3_student",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            type: "radios",
            condition: conditions.toString('boarder3_student')
          },
          {
            key: "boarder3_rentamount",
            title: "How much per week do they pay?",
            condition: conditions.toString('boarder3_rentamount')
          },
          {
            key: "boarder3_rentheating",
            title: "Does this include money for food or heating?",
            type: "radios",
            condition: conditions.toString('boarder3_rentheating')
          },
          {
            key: "boarder3_custody",
            title: "Are they in legal custody at the moment?",
            type: "radios",
            condition: conditions.toString('boarder3_custody')
          },
          {
            key: "boarder3_custody_released",
            title: "When will they be released?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder3_custody_released')
          },
          {
            key: "boarder3_hospital",
            title: "Are they in hospital at the moment?",
            type: "radios",
            condition: conditions.toString('boarder3_hospital')
          },
          {
            key: "boarder3_hospital_in",
            title: "When did they go into hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder3_hospital_in')
          },
          {
            key: "boarder3_hospital_knownout",
            title: "Do you know when they are due to come out of hospital?",
            type: "radios",
            condition: conditions.toString('boarder3_hospital_knownout')
          },
          {
            key: "boarder3_hospital_out",
            title: "When are they due to come out of hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder3_hospital_out')
          },
          {
            key: "boarder3_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('boarder3_mental')
          },
          {
            type: "help",
            helpvalue: "<h3>boarder 4</h3>",
            condition: conditions.toString('help_boarder_4')

          },
          {
            key: "boarder4_forename",
            title: "What is their forename(s)?",
            condition: conditions.toString('boarder4_forename')
          },
          {
            key: "boarder4_surname",
            title: "What is their last name?",
            condition: conditions.toString('boarder4_surname')
          },
          {
            key: "boarder4_dob",
            title: "What is their date of birth?",
            type: "memorabledate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder4_dob')
          },
          {
            key: "boarder4_gender",
            title: "Are they Male or Female?",
            type: "radios",
            condition: conditions.toString('boarder4_gender')
          },
          {
            key: "boarder4_relationship_applicant",
            title: "What is this person's relationship to you?",
            condition: conditions.toString('boarder4_relationship_applicant')
          },
          {
            key: "boarder4_relationship_couple",
            title: "What is this person's relationship to you or your partner?",
            condition: conditions.toString('boarder4_relationship_couple')
          },
          {
            key: "boarder4_relationship_otheradults",
            title: "Is this person living with any other adult who normally lives with you, as if they were married or civil partners?",
            type: "radios",
            condition: conditions.toString('boarder4_relationship_otheradults')
          },
          {
            key: "boarder4_relationship_otheradults_name",
            title: "Who are they in a relationship with?",
            condition: conditions.toString('boarder4_relationship_otheradults_name')
          },
          {
            key: "boarder4_student",
            title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
            type: "radios",
            condition: conditions.toString('boarder4_student')
          },
          {
            key: "boarder4_rentamount",
            title: "How much per week do they pay?",
            condition: conditions.toString('boarder4_rentamount')
          },
          {
            key: "boarder4_rentheating",
            title: "Does this include money for food or heating?",
            type: "radios",
            condition: conditions.toString('boarder4_rentheating')
          },
          {
            key: "boarder4_custody",
            title: "Are they in legal custody at the moment?",
            type: "radios",
            condition: conditions.toString('boarder4_custody')
          },
          {
            key: "boarder4_custody_released",
            title: "When will they be released?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder4_custody_released')
          },
          {
            key: "boarder4_hospital",
            title: "Are they in hospital at the moment?",
            type: "radios",
            condition: conditions.toString('boarder4_hospital')
          },
          {
            key: "boarder4_hospital_in",
            title: "When did they go into hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder4_hospital_in')
          },
          {
            key: "boarder4_hospital_knownout",
            title: "Do you know when they are due to come out of hospital?",
            type: "radios",
            condition: conditions.toString('boarder4_hospital_knownout')
          },
          {
            key: "boarder4_hospital_out",
            title: "When are they due to come out of hospital?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('boarder4_hospital_out')
          },
          {
            key: "boarder4_mental",
            title: "Is this person severely mentally impaired?",
            type: "radios",
            condition: conditions.toString('boarder4_mental')
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 27",
        page: 27,
        items: [
          {
            type: "fieldset",
            title: "This section is about The Non-Dependant Adults in your Household",
            items: [
              {
                type: "help",
                helpvalue: "<h3>Nondependant 1</h3>",
                condition: conditions.toString('help_nondependant_1')
              },
              {
                key: "nondependant1_forename",
                title: "What is their forename(s)?",
                condition: conditions.toString('nondependant1_forename')
              },
              {
                key: "nondependant1_surname",
                title: "What is their last name?",
                condition: conditions.toString('nondependant1_surname')
              },
              {
                key: "nondependant1_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_dob')
              },
              {
                key: "nondependant1_gender",
                title: "Are they Male or Female?",
                type: "radios",
                condition: conditions.toString('nondependant1_gender')
              },
              {
                key: "nondependant1_ni",
                title: "What's their National Insurance Number?",
                condition: conditions.toString('nondependant1_ni'),
                description: "Leave blank if you do not know the number."
              },
              {
                key: "nondependant1_relationship_applicant",
                title: "What is this person's relationship to you?",
                condition: conditions.toString('nondependant1_relationship_applicant')
              },
              {
                key: "nondependant1_relationship_couple",
                title: "What is this person's relationship to you or your partner?",
                condition: conditions.toString('nondependant1_relationship_couple')
              },
              {
                key: "nondependant1_relationship_otheradults",
                title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
                type: "radios",
                condition: conditions.toString('nondependant1_relationship_otheradults')
              },
              {
                key: "nondependant1_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: conditions.toString('nondependant1_relationship_otheradults_name')
              },
              {
                key: "nondependant1_student",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                type: "radios",
                condition: conditions.toString('nondependant1_student')
              },
              {
                key: "nondependant1_care",
                title: "Does this person provide care or support (either paid or unpaid) for someone who is NOT their dependant child under 18, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant1_care')
              },
              {
                key: "nondependant1_care35",
                title: "Does this non-dependant adult provide care for another person (for at least 35 hours per week) who lives in your home; and who gets higher rate Attendance Allowance; or Disability Living Allowance with the higher rate for 'care'; or Personal Independence Payment or Armed Forces Independence Payment with the Enhanced rate of 'Daily Living' (if you are not sure, check their award letter); or a similar benefit; and the person they care for is NOT their dependant child under 18, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant1_care35')
              },
              {
                key: "nondependant1_care24",
                title: "Does this non-dependant adult provide care for another person who lives in your home for at least 24 hours per week; and who earns no more than £44 a week for this; and resides (for the better performance of the work) in premises provided on behalf of that person; and was introduced by a charity, council, or government department?",
                type: "radios",
                condition: conditions.toString('nondependant1_care24')
              },
              {
                key: "nondependant1_vol",
                title: "Is this non-dependant adult engaged by a charitable or voluntary body, which charges you or partner, to provide care for you or your partner?",
                type: "radios",
                condition: conditions.toString('nondependant1_vol')
              },
              {
                key: "nondependant1_pip",
                title: "Do they get Personal Independence Payment or Armed Forces Independence Payment?",
                type: "radios",
                condition: conditions.toString('nondependant1_pip')
              },
              {
                key: "nondependant1_pipdaily",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£73.90 (Standard)</li><li>£110.40 (Enhanced)</li></ul>",
                condition: conditions.toString('nondependant1_pipdaily')
              },
              {
                key: "nondependant1_pipmot",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP. The weekly rate amounts are:<ul><li>£29.20 (Low)</li><li>£77.05 (Enhanced)</li></ul>",
                condition: conditions.toString('nondependant1_pipmot')
              },
              {
                key: "nondependant1_mental",
                title: "Is this person registered blind or severely mentally impaired?",
                type: "checkboxes",
                condition: conditions.toString('nondependant1_mental')
              },
              {
                key: "nondependant1_benefits",
                title: "Do they get any of the following benefits? Select all that apply to this person.",
                type: "checkboxes",
                condition: conditions.toString('nondependant1_benefits')
              },
              {
                key: "nondependant1_dlacare",
                title: "What rate of their Disability Living Allowance is for care?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: conditions.toString('nondependant1_dlacare')
              },
              {
                key: "nondependant1_dlamot",
                title: "What rate of their Disability Living Allowance is for mobility (including any payment to Motability fund)?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: conditions.toString('nondependant1_dlamot')
              },
              {
                key: "nondependant1_esa",
                title: "Which rate of Income-related Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant1_esa')
              },
              {
                key: "nondependant1_uc",
                title: "How much Universal Credit does this person get per month?",
                condition: conditions.toString('nondependant1_uc')
              },
              {
                key: "nondependant1_work",
                title: "Does this person usually work?",
                type: "radios",
                condition: conditions.toString('nondependant1_work')
              },
              {
                key: "nondependant1_work_hours",
                title: "How many hours do they work on average per week?",
                condition: conditions.toString('nondependant1_work_hours')
              },
              {
                key: "nondependant1_work_pay",
                title: "How much do they earn on average, before any deductions?",
                condition: conditions.toString('nondependant1_work_pay')
              },
              {
                key: "nondependant1_work_pay_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant1_work_pay_frequency')
              },
              {
                key: "nondependant1_contjsa",
                title: "Does this person receive Contributions based Jobseekers Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant1_contjsa')
              },
              {
                key: "nondependant1_contjsa_amount",
                title: "How much Contributions based Jobseekers Allowance do they get?",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: conditions.toString('nondependant1_contjsa_amount')
              },
              {
                key: "nondependant1_contjsa_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant1_contjsa_frequency')
              },
              {
                key: "nondependant1_contesa",
                title: "Does this person receive Contribution-based Employment and Support Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant1_contesa')
              },
              {
                key: "nondependant1_contesarate",
                title: "Which rate of Contribution-based Employment and Support Allowance is this person getting?",
                type: "select",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: conditions.toString('nondependant1_contesarate')
              },
              {
                key: "nondependant1_contesafrequency",
                title: "How often does this person get Employment and Support Allowance?",
                type: "select",
                condition: conditions.toString('nondependant1_contesafrequency')
              },
              {
                key: "nondependant1_contesaamount",
                title: "How much Contribution-based Employment and Support Allowance does this person get paid in this period?",
                description: "If you're not sure, check the award letter from the DWP.",
                condition: conditions.toString('nondependant1_contesaamount')
              },
              {
                key: "nondependant1_otherincome",
                title: "Does this person have any other income at all?",
                type: "radios",
                description: "For example, private pensions, occupational pensions, annuities, Working Tax Credits or Child Tax Credits.",
                condition: conditions.toString('nondependant1_otherincome')
              },
              {
                key: "nondependant1_otherincome_number",
                title: "How many other incomes do you need to tell us about?",
                condition: conditions.toString('nondependant1_otherincome_number')
              },
              {
                type: "template",
                template: "<h3>Income 1</h3>",
                condition: conditions.toString('template_nondependant1_otherincome1')
              },
              {
                key: "nondependant1_otherincome1_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant1_otherincome1_type')
              },
              {
                key: "nondependant1_otherincome1_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant1_otherincome1_amount')
              },
              {
                key: "nondependant1_otherincome1_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant1_otherincome1_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 2</h3>",
                condition: conditions.toString('template_nondependant1_otherincome2')
              },
              {
                key: "nondependant1_otherincome2_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant1_otherincome2_type')
              },
              {
                key: "nondependant1_otherincome2_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant1_otherincome2_amount')
              },
              {
                key: "nondependant1_otherincome2_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant1_otherincome2_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 3</h3>",

                condition: conditions.toString('template_nondependant1_otherincome3')
              },
              {
                key: "nondependant1_otherincome3_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant1_otherincome3_type')
              },
              {
                key: "nondependant1_otherincome3_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant1_otherincome3_amount')
              },
              {
                key: "nondependant1_otherincome3_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant1_otherincome3_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 4</h3>",

                condition: conditions.toString('template_nondependant1_otherincome4')
              },
              {
                key: "nondependant1_otherincome4_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant1_otherincome4_type')
              },
              {
                key: "nondependant1_otherincome4_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant1_otherincome4_amount')
              },
              {
                key: "nondependant1_otherincome4_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant1_otherincome4_frequency')
              },
              {
                key: "nondependant1_custody",
                title: "Is this Non-Dependant Adult  in legal custody at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant1_custody')
              },
              {
                key: "nondependant1_custody_released",
                title: "When will they be released?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_custody_released')
              },
              {
                key: "nondependant1_hospital",
                title: "Are they in hospital at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant1_hospital')
              },
              {
                key: "nondependant1_hospital_in",
                title: "When did they go into hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_hospital_in')
              },
              {
                key: "nondependant1_hospital_knownout",
                title: "Do you know when they are due to come out of hospital?",
                type: "radios",
                condition: conditions.toString('nondependant1_hospital_knownout')
              },
              {
                key: "nondependant1_hospital_out",
                title: "When are they due to come out of hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_hospital_out')
              },
              {
                type: "help",
                helpvalue: "<h3>Nondependant 2</h3>",
                condition: conditions.toString('help_nondependant_2')
              },
              {
                key: "nondependant2_forename",
                title: "What is their forename(s)?",
                condition: conditions.toString('nondependant2_forename')
              },
              {
                key: "nondependant2_surname",
                title: "What is their last name?",
                condition: conditions.toString('nondependant2_surname')
              },
              {
                key: "nondependant2_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_dob')
              },
              {
                key: "nondependant2_gender",
                title: "Are they Male or Female?",
                type: "radios",
                condition: conditions.toString('nondependant2_gender')
              },
              {
                key: "nondependant2_ni",
                title: "What's their National Insurance Number?",
                condition: conditions.toString('nondependant2_ni')
              },
              {
                key: "nondependant2_relationship_applicant",
                title: "What is this persons relationship to you?",
                condition: conditions.toString('nondependant2_relationship_applicant')
              },
              {
                key: "nondependant2_relationship_couple",
                title: "What is this persons relationship to you or your partner",
                condition: conditions.toString('nondependant2_relationship_couple')
              },
              {
                key: "nondependant2_relationship_otheradults",
                title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
                type: "radios",
                condition: conditions.toString('nondependant2_relationship_otheradults')
              },
              {
                key: "nondependant2_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: conditions.toString('nondependant2_relationship_otheradults_name')
              },
              {
                key: "nondependant2_student",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                type: "radios",
                condition: conditions.toString('nondependant2_student')
              },
              {
                key: "nondependant2_care",
                title: "Does this person provide care or support (either paid or unpaid) for someone who is NOT their dependant child under 28, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant2_care')
              },
              {
                key: "nondependant2_care35",
                title: "Does this non-dependant adult provide care for another person (for at least 35 hours per week) who lives in your home; and who gets higher rate Attendance Allowance; or Disability Living Allowance with the higher rate for 'care'; or Personal Independence Payment or Armed Forces Independence Payment with the Enhanced rate of 'Daily Living' (if you are not sure, check their award letter); or a similar benefit; and the person they care for is NOT their dependant child under 28, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant2_care35')
              },
              {
                key: "nondependant2_care24",
                title: "Does this non-dependant adult provide care for another person who lives in your home for at least 24 hours per week; and who earns no more than £44 a week for this; and resides (for the better performance of the work) in premises provided on behalf of that person; and was introduced by a charity, council, or government department?",
                type: "radios",
                condition: conditions.toString('nondependant2_care24')
              },
              {
                key: "nondependant2_vol",
                title: "Is this non-dependant adult engaged by a charitable or voluntary body, which charges you or partner, to provide care for you or your partner?",
                type: "radios",
                condition: conditions.toString('nondependant2_vol')
              },
              {
                key: "nondependant2_pip",
                title: "Do they get Personal Independence Payment or Armed Forces Independence Payment?",
                type: "radios",
                condition: conditions.toString('nondependant2_pip')
              },
              {
                key: "nondependant2_pipdaily",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
                type: "select",
                condition: conditions.toString('nondependant2_pipdaily')
              },
              {
                key: "nondependant2_pipmot",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
                type: "select",
                condition: conditions.toString('nondependant2_pipmot')
              },
              {
                key: "nondependant2_mental",
                title: "Is this person registered blind or severely mentally impaired?",
                type: "checkboxes",
                condition: conditions.toString('nondependant2_mental')
              },
              {
                key: "nondependant2_benefits",
                title: "Do they get any of the following benefits? Select all that apply to this person.",
                type: "checkboxes",
                condition: conditions.toString('nondependant2_benefits')
              },
              {
                key: "nondependant2_dlacare",
                title: "What rate of their Disability Living Allowance is for care?",
                type: "select",
                condition: conditions.toString('nondependant2_dlacare')
              },
              {
                key: "nondependant2_dlamot",
                title: "What rate of their Disability Living Allowance is for mobility (including any payment to Motability fund)?",
                type: "select",
                condition: conditions.toString('nondependant2_dlamot')
              },
              {
                key: "nondependant2_esa",
                title: "Which rate of Income-related Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant2_esa')
              },
              {
                key: "nondependant2_uc",
                title: "How much Universal Credit does this person get per month?",
                condition: conditions.toString('nondependant2_uc')
              },
              {
                key: "nondependant2_work",
                title: "Does this person usually work?",
                type: "radios",
                condition: conditions.toString('nondependant2_work')
              },
              {
                key: "nondependant2_work_hours",
                title: "How many hours do they work on average per week?",
                condition: conditions.toString('nondependant2_work_hours')
              },
              {
                key: "nondependant2_work_pay",
                title: "How much do they earn on average, before any deductions?",
                condition: conditions.toString('nondependant2_work_pay')
              },
              {
                key: "nondependant2_work_pay_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant2_work_pay_frequency')
              },
              {
                key: "nondependant2_contjsa",
                title: "Does this person receive Contributions based Jobseekers Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant2_contjsa')
              },
              {
                key: "nondependant2_contjsa_amount",
                title: "How much Contributions based Jobseekers Allowance do they get?",
                condition: conditions.toString('nondependant2_contjsa_amount')
              },
              {
                key: "nondependant2_contjsa_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant2_contjsa_frequency')
              },
              {
                key: "nondependant2_contesa",
                title: "Does this person receive Contribution-based Employment and Support Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant2_contesa')
              },
              {
                key: "nondependant2_contesarate",
                title: "Which rate of Contribution-based Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant2_contesarate')
              },
              {
                key: "nondependant2_contesafrequency",
                title: "How often does this person get Employment and Support Allowance?",
                type: "select",
                condition: conditions.toString('nondependant2_contesafrequency')
              },
              {
                key: "nondependant2_contesaamount",
                title: "How much Contribution-based Employment and Support Allowance does this person get paid in this period?",
                condition: conditions.toString('nondependant2_contesaamount')
              },
              {
                key: "nondependant2_otherincome",
                title: "Does this person have any other income at all?",
                type: "radios",
                condition: conditions.toString('nondependant2_otherincome')
              },
              {
                key: "nondependant2_otherincome_number",
                title: "How many other incomes do you need to tell us about?",
                condition: conditions.toString('nondependant2_otherincome_number')
              },
              {
                type: "template",
                template: "<h3>Income 1</h3>",

                condition: conditions.toString('template_nondependant2_otherincome1')
              },
              {
                key: "nondependant2_otherincome1_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant2_otherincome1_type')
              },
              {
                key: "nondependant2_otherincome1_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant2_otherincome1_amount')
              },
              {
                key: "nondependant2_otherincome1_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant2_otherincome1_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 2</h3>",

                condition: conditions.toString('template_nondependant2_otherincome2')
              },
              {
                key: "nondependant2_otherincome2_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant2_otherincome2_type')
              },
              {
                key: "nondependant2_otherincome2_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant2_otherincome2_amount')
              },
              {
                key: "nondependant2_otherincome2_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant2_otherincome2_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 3</h3>",

                condition: conditions.toString('template_nondependant2_otherincome3')
              },
              {
                key: "nondependant2_otherincome3_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant2_otherincome3_type')
              },
              {
                key: "nondependant2_otherincome3_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant2_otherincome3_amount')
              },
              {
                key: "nondependant2_otherincome3_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant2_otherincome3_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 4</h3>",

                condition: conditions.toString('template_nondependant2_otherincome4')
              },
              {
                key: "nondependant2_otherincome4_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant2_otherincome4_type')
              },
              {
                key: "nondependant2_otherincome4_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant2_otherincome4_amount')
              },
              {
                key: "nondependant2_otherincome4_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant2_otherincome4_frequency')
              },
              {
                key: "nondependant2_custody",
                title: "is this Non-Dependant Adult  in legal custody at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant2_custody')
              },
              {
                key: "nondependant2_custody_released",
                title: "When will they be released?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_custody_released')
              },
              {
                key: "nondependant2_hospital",
                title: "Are they in hospital at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant2_hospital')
              },
              {
                key: "nondependant2_hospital_in",
                title: "When did they go into hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_hospital_in')
              },
              {
                key: "nondependant2_hospital_knownout",
                title: "Do you know when they are due to come out of hospital?",
                type: "radios",
                condition: conditions.toString('nondependant2_hospital_knownout')
              },
              {
                key: "nondependant2_hospital_out",
                title: "When are they due to come out of hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_hospital_out')
              },
              {
                type: "help",
                helpvalue: "<h3>Nondependant 3</h3>",
                condition: conditions.toString('help_nondependant_3')

              },
              {
                key: "nondependant3_forename",
                title: "What is their forename(s)?",
                condition: conditions.toString('nondependant3_forename')
              },
              {
                key: "nondependant3_surname",
                title: "What is their last name?",
                condition: conditions.toString('nondependant3_surname')
              },
              {
                key: "nondependant3_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_dob')
              },
              {
                key: "nondependant3_gender",
                title: "Are they Male or Female?",
                type: "radios",
                condition: conditions.toString('nondependant3_gender')
              },
              {
                key: "nondependant3_ni",
                title: "What's their National Insurance Number?",
                condition: conditions.toString('nondependant3_ni')
              },
              {
                key: "nondependant3_relationship_applicant",
                title: "What is this persons relationship to you?",
                condition: conditions.toString('nondependant3_relationship_applicant')
              },
              {
                key: "nondependant3_relationship_couple",
                title: "What is this persons relationship to you or your partner",
                condition: conditions.toString('nondependant3_relationship_couple')
              },
              {
                key: "nondependant3_relationship_otheradults",
                title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
                type: "radios",
                condition: conditions.toString('nondependant3_relationship_otheradults')
              },
              {
                key: "nondependant3_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: conditions.toString('nondependant3_relationship_otheradults_name')
              },
              {
                key: "nondependant3_student",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                type: "radios",
                condition: conditions.toString('nondependant3_student')
              },
              {
                key: "nondependant3_care",
                title: "Does this person provide care or support (either paid or unpaid) for someone who is NOT their dependant child under 38, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant3_care')
              },
              {
                key: "nondependant3_care35",
                title: "Does this non-dependant adult provide care for another person (for at least 35 hours per week) who lives in your home; and who gets higher rate Attendance Allowance; or Disability Living Allowance with the higher rate for 'care'; or Personal Independence Payment or Armed Forces Independence Payment with the Enhanced rate of 'Daily Living' (if you are not sure, check their award letter); or a similar benefit; and the person they care for is NOT their dependant child under 38, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant3_care35')
              },
              {
                key: "nondependant3_care24",
                title: "Does this non-dependant adult provide care for another person who lives in your home for at least 24 hours per week; and who earns no more than £44 a week for this; and resides (for the better performance of the work) in premises provided on behalf of that person; and was introduced by a charity, council, or government department?",
                type: "radios",
                condition: conditions.toString('nondependant3_care24')
              },
              {
                key: "nondependant3_vol",
                title: "Is this non-dependant adult engaged by a charitable or voluntary body, which charges you or partner, to provide care for you or your partner?",
                type: "radios",
                condition: conditions.toString('nondependant3_vol')
              },
              {
                key: "nondependant3_pip",
                title: "Do they get Personal Independence Payment or Armed Forces Independence Payment?",
                type: "radios",
                condition: conditions.toString('nondependant3_pip')
              },
              {
                key: "nondependant3_pipdaily",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
                type: "select",
                condition: conditions.toString('nondependant3_pipdaily')
              },
              {
                key: "nondependant3_pipmot",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
                type: "select",
                condition: conditions.toString('nondependant3_pipmot')
              },
              {
                key: "nondependant3_mental",
                title: "Is this person registered blind or severely mentally impaired?",
                type: "checkboxes",
                condition: conditions.toString('nondependant3_mental')
              },
              {
                key: "nondependant3_benefits",
                title: "Do they get any of the following benefits? Select all that apply to this person.",
                type: "checkboxes",
                condition: conditions.toString('nondependant3_benefits')
              },
              {
                key: "nondependant3_dlacare",
                title: "What rate of their Disability Living Allowance is for care?",
                type: "select",
                condition: conditions.toString('nondependant3_dlacare')
              },
              {
                key: "nondependant3_dlamot",
                title: "What rate of their Disability Living Allowance is for mobility (including any payment to Motability fund)?",
                type: "select",
                condition: conditions.toString('nondependant3_dlamot')
              },
              {
                key: "nondependant3_esa",
                title: "Which rate of Income-related Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant3_esa')
              },
              {
                key: "nondependant3_uc",
                title: "How much Universal Credit does this person get per month?",
                condition: conditions.toString('nondependant3_uc')
              },
              {
                key: "nondependant3_work",
                title: "Does this person usually work?",
                type: "radios",
                condition: conditions.toString('nondependant3_work')
              },
              {
                key: "nondependant3_work_hours",
                title: "How many hours do they work on average per week?",
                condition: conditions.toString('nondependant3_work_hours')
              },
              {
                key: "nondependant3_work_pay",
                title: "How much do they earn on average, before any deductions?",
                condition: conditions.toString('nondependant3_work_pay')
              },
              {
                key: "nondependant3_work_pay_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant3_work_pay_frequency')
              },
              {
                key: "nondependant3_contjsa",
                title: "Does this person receive Contributions based Jobseekers Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant3_contjsa')
              },
              {
                key: "nondependant3_contjsa_amount",
                title: "How much Contributions based Jobseekers Allowance do they get?",
                condition: conditions.toString('nondependant3_contjsa_amount')
              },
              {
                key: "nondependant3_contjsa_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant3_contjsa_frequency')
              },
              {
                key: "nondependant3_contesa",
                title: "Does this person receive Contribution-based Employment and Support Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant3_contesa')
              },
              {
                key: "nondependant3_contesarate",
                title: "Which rate of Contribution-based Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant3_contesarate')
              },
              {
                key: "nondependant3_contesafrequency",
                title: "How often does this person get Employment and Support Allowance?",
                type: "select",
                condition: conditions.toString('nondependant3_contesafrequency')
              },
              {
                key: "nondependant3_contesaamount",
                title: "How much Contribution-based Employment and Support Allowance does this person get paid in this period?",
                condition: conditions.toString('nondependant3_contesaamount')
              },
              {
                key: "nondependant3_otherincome",
                title: "Does this person have any other income at all?",
                type: "radios",
                condition: conditions.toString('nondependant3_otherincome')
              },
              {
                key: "nondependant3_otherincome_number",
                title: "How many other incomes do you need to tell us about?",
                condition: conditions.toString('nondependant3_otherincome_number')
              },
              {
                type: "template",
                template: "<h3>Income 1</h3>",

                condition: conditions.toString('template_nondependant3_otherincome1')
              },
              {
                key: "nondependant3_otherincome1_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant3_otherincome1_type')
              },
              {
                key: "nondependant3_otherincome1_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant3_otherincome1_amount')
              },
              {
                key: "nondependant3_otherincome1_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant3_otherincome1_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 2</h3>",

                condition: conditions.toString('template_nondependant3_otherincome2')
              },
              {
                key: "nondependant3_otherincome2_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant3_otherincome2_type')
              },
              {
                key: "nondependant3_otherincome2_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant3_otherincome2_amount')
              },
              {
                key: "nondependant3_otherincome2_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant3_otherincome2_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 3</h3>",

                condition: conditions.toString('template_nondependant3_otherincome3')
              },
              {
                key: "nondependant3_otherincome3_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant3_otherincome3_type')
              },
              {
                key: "nondependant3_otherincome3_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant3_otherincome3_amount')
              },
              {
                key: "nondependant3_otherincome3_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant3_otherincome3_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 4</h3>",

                condition: conditions.toString('template_nondependant3_otherincome4')
              },
              {
                key: "nondependant3_otherincome4_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant3_otherincome4_type')
              },
              {
                key: "nondependant3_otherincome4_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant3_otherincome4_amount')
              },
              {
                key: "nondependant3_otherincome4_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant3_otherincome4_frequency')
              },
              {
                key: "nondependant3_custody",
                title: "is this Non-Dependant Adult  in legal custody at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant3_custody')
              },
              {
                key: "nondependant3_custody_released",
                title: "When will they be released?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_custody_released')
              },
              {
                key: "nondependant3_hospital",
                title: "Are they in hospital at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant3_hospital')
              },
              {
                key: "nondependant3_hospital_in",
                title: "When did they go into hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_hospital_in')
              },
              {
                key: "nondependant3_hospital_knownout",
                title: "Do you know when they are due to come out of hospital?",
                type: "radios",
                condition: conditions.toString('nondependant3_hospital_knownout')
              },
              {
                key: "nondependant3_hospital_out",
                title: "When are they due to come out of hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_hospital_out')
              },
              {
                type: "help",
                helpvalue: "<h3>Nondependant 4</h3>",
                condition: conditions.toString('help_nondependant_4')

              },
              {
                key: "nondependant4_forename",
                title: "What is their forename(s)?",
                condition: conditions.toString('nondependant4_forename')
              },
              {
                key: "nondependant4_surname",
                title: "What is their last name?",
                condition: conditions.toString('nondependant4_surname')
              },
              {
                key: "nondependant4_dob",
                title: "What is their date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_dob')
              },
              {
                key: "nondependant4_gender",
                title: "Are they Male or Female?",
                type: "radios",
                condition: conditions.toString('nondependant4_gender')
              },
              {
                key: "nondependant4_ni",
                title: "What's their National Insurance Number?",
                condition: conditions.toString('nondependant4_ni')
              },
              {
                key: "nondependant4_relationship_applicant",
                title: "What is this persons relationship to you?",
                condition: conditions.toString('nondependant4_relationship_applicant')
              },
              {
                key: "nondependant4_relationship_couple",
                title: "What is this persons relationship to you or your partner",
                condition: conditions.toString('nondependant4_relationship_couple')
              },
              {
                key: "nondependant4_relationship_otheradults",
                title: "Is this person living with any other adult, who normally lives with you, as if they were married or civil partners?",
                type: "radios",
                condition: conditions.toString('nondependant4_relationship_otheradults')
              },
              {
                key: "nondependant4_relationship_otheradults_name",
                title: "Who are they in a relationship with?",
                condition: conditions.toString('nondependant4_relationship_otheradults_name')
              },
              {
                key: "nondependant4_student",
                title: "Is this person a full-time student, a student nurse, a carer or care worker, an apprentice or on work-based training for young people?",
                type: "radios",
                condition: conditions.toString('nondependant4_student')
              },
              {
                key: "nondependant4_care",
                title: "Does this person provide care or support (either paid or unpaid) for someone who is NOT their dependant child under 48, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant4_care')
              },
              {
                key: "nondependant4_care35",
                title: "Does this non-dependant adult provide care for another person (for at least 45 hours per week) who lives in your home; and who gets higher rate Attendance Allowance; or Disability Living Allowance with the higher rate for 'care'; or Personal Independence Payment or Armed Forces Independence Payment with the Enhanced rate of 'Daily Living' (if you are not sure, check their award letter); or a similar benefit; and the person they care for is NOT their dependant child under 48, nor their partner?",
                type: "radios",
                condition: conditions.toString('nondependant4_care35')
              },
              {
                key: "nondependant4_care24",
                title: "Does this non-dependant adult provide care for another person who lives in your home for at least 24 hours per week; and who earns no more than £44 a week for this; and resides (for the better performance of the work) in premises provided on behalf of that person; and was introduced by a charity, council, or government department?",
                type: "radios",
                condition: conditions.toString('nondependant4_care24')
              },
              {
                key: "nondependant4_vol",
                title: "Is this non-dependant adult engaged by a charitable or voluntary body, which charges you or partner, to provide care for you or your partner?",
                type: "radios",
                condition: conditions.toString('nondependant4_vol')
              },
              {
                key: "nondependant4_pip",
                title: "Do they get Personal Independence Payment or Armed Forces Independence Payment?",
                type: "radios",
                condition: conditions.toString('nondependant4_pip')
              },
              {
                key: "nondependant4_pipdaily",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Daily Living?",
                type: "select",
                condition: conditions.toString('nondependant4_pipdaily')
              },
              {
                key: "nondependant4_pipmot",
                title: "What rate of their Personal Independence Payment or Armed Forces Independence Payment is for Mobility?",
                type: "select",
                condition: conditions.toString('nondependant4_pipmot')
              },
              {
                key: "nondependant4_mental",
                title: "Is this person registered blind or severely mentally impaired?",
                type: "checkboxes",
                condition: conditions.toString('nondependant4_mental')
              },
              {
                key: "nondependant4_benefits",
                title: "Do they get any of the following benefits? Select all that apply to this person.",
                type: "checkboxes",
                condition: conditions.toString('nondependant4_benefits')
              },
              {
                key: "nondependant4_dlacare",
                title: "What rate of their Disability Living Allowance is for care?",
                type: "select",
                condition: conditions.toString('nondependant4_dlacare')
              },
              {
                key: "nondependant4_dlamot",
                title: "What rate of their Disability Living Allowance is for mobility (including any payment to Motability fund)?",
                type: "select",
                condition: conditions.toString('nondependant4_dlamot')
              },
              {
                key: "nondependant4_esa",
                title: "Which rate of Income-related Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant4_esa')
              },
              {
                key: "nondependant4_uc",
                title: "How much Universal Credit does this person get per month?",
                condition: conditions.toString('nondependant4_uc')
              },
              {
                key: "nondependant4_work",
                title: "Does this person usually work?",
                type: "radios",
                condition: conditions.toString('nondependant4_work')
              },
              {
                key: "nondependant4_work_hours",
                title: "How many hours do they work on average per week?",
                condition: conditions.toString('nondependant4_work_hours')
              },
              {
                key: "nondependant4_work_pay",
                title: "How much do they earn on average, before any deductions?",
                condition: conditions.toString('nondependant4_work_pay')
              },
              {
                key: "nondependant4_work_pay_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant4_work_pay_frequency')
              },
              {
                key: "nondependant4_contjsa",
                title: "Does this person receive Contributions based Jobseekers Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant4_contjsa')
              },
              {
                key: "nondependant4_contjsa_amount",
                title: "How much Contributions based Jobseekers Allowance do they get?",
                condition: conditions.toString('nondependant4_contjsa_amount')
              },
              {
                key: "nondependant4_contjsa_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant4_contjsa_frequency')
              },
              {
                key: "nondependant4_contesa",
                title: "Does this person receive Contribution-based Employment and Support Allowance?",
                type: "radios",
                condition: conditions.toString('nondependant4_contesa')
              },
              {
                key: "nondependant4_contesarate",
                title: "Which rate of Contribution-based Employment and Support Allowance is this person getting?",
                type: "select",
                condition: conditions.toString('nondependant4_contesarate')
              },
              {
                key: "nondependant4_contesafrequency",
                title: "How often does this person get Employment and Support Allowance?",
                type: "select",
                condition: conditions.toString('nondependant4_contesafrequency')
              },
              {
                key: "nondependant4_contesaamount",
                title: "How much Contribution-based Employment and Support Allowance does this person get paid in this period?",
                condition: conditions.toString('nondependant4_contesaamount')
              },
              {
                key: "nondependant4_otherincome",
                title: "Does this person have any other income at all?",
                type: "radios",
                condition: conditions.toString('nondependant4_otherincome')
              },
              {
                key: "nondependant4_otherincome_number",
                title: "How many other incomes do you need to tell us about?",
                condition: conditions.toString('nondependant4_otherincome_number')
              },
              {
                type: "template",
                template: "<h3>Income 1</h3>",

                condition: conditions.toString('template_nondependant4_otherincome1')
              },
              {
                key: "nondependant4_otherincome1_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant4_otherincome1_type')
              },
              {
                key: "nondependant4_otherincome1_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant4_otherincome1_amount')
              },
              {
                key: "nondependant4_otherincome1_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant4_otherincome1_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 2</h3>",

                condition: conditions.toString('template_nondependant4_otherincome2')
              },
              {
                key: "nondependant4_otherincome2_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant4_otherincome2_type')
              },
              {
                key: "nondependant4_otherincome2_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant4_otherincome2_amount')
              },
              {
                key: "nondependant4_otherincome2_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant4_otherincome2_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 3</h3>",

                condition: conditions.toString('template_nondependant4_otherincome3')
              },
              {
                key: "nondependant4_otherincome3_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant4_otherincome3_type')
              },
              {
                key: "nondependant4_otherincome3_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant4_otherincome3_amount')
              },
              {
                key: "nondependant4_otherincome3_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant4_otherincome3_frequency')
              },
              {
                type: "template",
                template: "<h3>Income 4</h3>",

                condition: conditions.toString('template_nondependant4_otherincome4')
              },
              {
                key: "nondependant4_otherincome4_type",
                title: "What type of income is this?",
                condition: conditions.toString('nondependant4_otherincome4_type')
              },
              {
                key: "nondependant4_otherincome4_amount",
                title: "How much is it before deductions?",
                condition: conditions.toString('nondependant4_otherincome4_amount')
              },
              {
                key: "nondependant4_otherincome4_frequency",
                title: "How often do they get this amount?",
                type: "select",
                condition: conditions.toString('nondependant4_otherincome4_frequency')
              },
              {
                key: "nondependant4_custody",
                title: "is this Non-Dependant Adult  in legal custody at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant4_custody')
              },
              {
                key: "nondependant4_custody_released",
                title: "When will they be released?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_custody_released')
              },
              {
                key: "nondependant4_hospital",
                title: "Are they in hospital at the moment?",
                type: "radios",
                condition: conditions.toString('nondependant4_hospital')
              },
              {
                key: "nondependant4_hospital_in",
                title: "When did they go into hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_hospital_in')
              },
              {
                key: "nondependant4_hospital_knownout",
                title: "Do you know when they are due to come out of hospital?",
                type: "radios",
                condition: conditions.toString('nondependant4_hospital_knownout')
              },
              {
                key: "nondependant4_hospital_out",
                title: "When are they due to come out of hospital?",
                type: "kerpdate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_hospital_out')
              }]
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 28",
        page: 28,
        items: [
          {
            type: "fieldset",
            title: "Non-Dependant Adults with Children",
            items: [
              {
                type: "help",
                helpvalue: "<h3>Nondependant 1 with Children</h3>",
                condition: conditions.toString('help_nondependant_1_with_children')
              },
              {
                key: "nondependant1_children",
                title: "Does this non-dependant live with any children in your household?",
                type: "radios",
                description: "A dependant child is one who they usually get child benefit for. Answer Yes if they are responsible for a child, even if they do not get child benefit for that child. Do not count any child who has been in hospital continuously for 52 weeks (one year) or more.",
                condition: conditions.toString('nondependant1_children')
              },
              {
                key: "nondependant1_childrenno",
                title: "How many children does this non-dependant live with in your household?",
                condition: conditions.toString('nondependant1_childrenno')
              },
              {
                type: "help",
                helpvalue: "Nondependant 1 - Child 1",
                condition: conditions.toString('fieldset_nondependant1child1')
              },
              {
                key: "nondependant1_child1_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant1_child1_forename')
              },
              {
                key: "nondependant1_child1_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant1_child1_lastname')
              },
              {
                key: "nondependant1_child1_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant1_child1_cb')
              },
              {
                key: "nondependant1_child1_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_child1_dob')
              },
              {
                key: "nondependant1_child1_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant1_child1_gender')
              },
              {
                key: "nondependant1_child1_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant1_child1_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 1 - Child 2",
                condition: conditions.toString('fieldset_nondependant1child2')
              },
              {
                key: "nondependant1_child2_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant1_child2_forename')
              },
              {
                key: "nondependant1_child2_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant1_child2_lastname')
              },
              {
                key: "nondependant1_child2_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant1_child2_cb')
              },
              {
                key: "nondependant1_child2_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_child2_dob')
              },
              {
                key: "nondependant1_child2_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant1_child2_gender')
              },
              {
                key: "nondependant1_child2_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant1_child2_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 1 - Child 3",
                condition: conditions.toString('fieldset_nondependant1child3')
              },
              {
                key: "nondependant1_child3_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant1_child3_forename')
              },
              {
                key: "nondependant1_child3_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant1_child3_lastname')
              },
              {
                key: "nondependant1_child3_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant1_child3_cb')
              },
              {
                key: "nondependant1_child3_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_child3_dob')
              },
              {
                key: "nondependant1_child3_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant1_child3_gender')
              },
              {
                key: "nondependant1_child3_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant1_child3_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 1 - Child 4",
                condition: conditions.toString('fieldset_nondependant1child4')
              },
              {
                key: "nondependant1_child4_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant1_child4_forename')
              },
              {
                key: "nondependant1_child4_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant1_child4_lastname')
              },
              {
                key: "nondependant1_child4_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant1_child4_cb')
              },
              {
                key: "nondependant1_child4_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant1_child4_dob')
              },
              {
                key: "nondependant1_child4_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant1_child4_gender')
              },
              {
                key: "nondependant1_child4_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant1_child4_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "<h3>Nondependant 2 with Children</h3>",
                condition: conditions.toString('help_nondependant_2_with_children')
              },
              {
                key: "nondependant2_children",
                title: "Does this non-dependant live with any children in your household?",
                type: "radios",
                condition: conditions.toString('nondependant2_children')
              },
              {
                key: "nondependant2_childrenno",
                title: "How many children does this non-dependant live with in your household?",
                condition: conditions.toString('nondependant2_childrenno')
              },

              {
                type: "help",
                helpvalue: "Nondependant 2 - Child 1",
                condition: conditions.toString('fieldset_nondependant2child1')
              },
              {
                key: "nondependant2_child1_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant2_child1_forename')
              },
              {
                key: "nondependant2_child1_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant2_child1_lastname')
              },
              {
                key: "nondependant2_child1_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant2_child1_cb')
              },
              {
                key: "nondependant2_child1_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_child1_dob')
              },
              {
                key: "nondependant2_child1_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant2_child1_gender')
              },
              {
                key: "nondependant2_child1_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant2_child1_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 2 - Child 2",
                condition: conditions.toString('fieldset_nondependant2child2')
              },
              {
                key: "nondependant2_child2_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant2_child2_forename')
              },
              {
                key: "nondependant2_child2_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant2_child2_lastname')
              },
              {
                key: "nondependant2_child2_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant2_child2_cb')
              },
              {
                key: "nondependant2_child2_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_child2_dob')
              },
              {
                key: "nondependant2_child2_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant2_child2_gender')
              },
              {
                key: "nondependant2_child2_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant2_child2_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },

              {
                type: "help",
                helpvalue: "Nondependant 2 - Child 3",
                condition: conditions.toString('fieldset_nondependant2child3')
              },
              {
                key: "nondependant2_child3_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant2_child3_forename')
              },
              {
                key: "nondependant2_child3_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant2_child3_lastname')
              },
              {
                key: "nondependant2_child3_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant2_child3_cb')
              },
              {
                key: "nondependant2_child3_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_child3_dob')
              },
              {
                key: "nondependant2_child3_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant2_child3_gender')
              },
              {
                key: "nondependant2_child3_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant2_child3_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 2 - Child 4",
                condition: conditions.toString('fieldset_nondependant2child4')
              },
              {
                key: "nondependant2_child4_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant2_child4_forename')
              },
              {
                key: "nondependant2_child4_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant2_child4_lastname')
              },
              {
                key: "nondependant2_child4_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant2_child4_cb')
              },
              {
                key: "nondependant2_child4_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant2_child4_dob')
              },
              {
                key: "nondependant2_child4_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant2_child4_gender')
              },
              {
                key: "nondependant2_child4_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant2_child4_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "<h3>Nondependant 3 with Children</h3>",
                condition: conditions.toString('help_nondependant_3_with_children')
              },
              {
                key: "nondependant3_children",
                title: "Does this non-dependant live with any children in your household?",
                type: "radios",
                condition: conditions.toString('nondependant3_children')
              },
              {
                key: "nondependant3_childrenno",
                title: "How many children does this non-dependant live with in your household?",
                condition: conditions.toString('nondependant3_childrenno')
              },
              {
                type: "help",
                helpvalue: "Nondependant 3 - Child 1",
                condition: conditions.toString('fieldset_nondependant3child1')
              },
              {
                key: "nondependant3_child1_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant3_child1_forename')
              },
              {
                key: "nondependant3_child1_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant3_child1_lastname')
              },
              {
                key: "nondependant3_child1_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant3_child1_cb')
              },
              {
                key: "nondependant3_child1_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_child1_dob')
              },
              {
                key: "nondependant3_child1_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant3_child1_gender')
              },
              {
                key: "nondependant3_child1_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant3_child1_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 3 - Child 2",
                condition: conditions.toString('fieldset_nondependant3child2')
              },
              {
                key: "nondependant3_child2_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant3_child2_forename')
              },
              {
                key: "nondependant3_child2_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant3_child2_lastname')
              },
              {
                key: "nondependant3_child2_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant3_child2_cb')
              },
              {
                key: "nondependant3_child2_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_child2_dob')
              },
              {
                key: "nondependant3_child2_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant3_child2_gender')
              },
              {
                key: "nondependant3_child2_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant3_child2_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },

              {
                type: "help",
                helpvalue: "Nondependant 3 - Child 3",
                condition: conditions.toString('fieldset_nondependant3child3')
              },
              {
                key: "nondependant3_child3_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant3_child3_forename')
              },
              {
                key: "nondependant3_child3_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant3_child3_lastname')
              },
              {
                key: "nondependant3_child3_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant3_child3_cb')
              },
              {
                key: "nondependant3_child3_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_child3_dob')
              },
              {
                key: "nondependant3_child3_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant3_child3_gender')
              },
              {
                key: "nondependant3_child3_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant3_child3_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },

              {
                type: "help",
                helpvalue: "Nondependant 3 - Child 4",
                condition: conditions.toString('fieldset_nondependant3child4')
              },
              {
                key: "nondependant3_child4_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant3_child4_forename')
              },
              {
                key: "nondependant3_child4_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant3_child4_lastname')
              },
              {
                key: "nondependant3_child4_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant3_child4_cb')
              },
              {
                key: "nondependant3_child4_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant3_child4_dob')
              },
              {
                key: "nondependant3_child4_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant3_child4_gender')
              },
              {
                key: "nondependant3_child4_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant3_child4_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."

              },
              {
                type: "help",
                helpvalue: "<h3>Nondependant 4 with Children</h3>",
                condition: conditions.toString('help_nondependant_4_with_children')

              },
              {
                key: "nondependant4_children",
                title: "Does this non-dependant live with any children in your household?",
                type: "radios",
                condition: conditions.toString('nondependant4_children')
              },
              {
                key: "nondependant4_childrenno",
                title: "How many children does this non-dependant live with in your household?",
                condition: conditions.toString('nondependant4_childrenno')
              },
              {
                type: "help",
                helpvalue: "Nondependant 4 - Child 1",
                condition: conditions.toString('fieldset_nondependant4child1')
              },
              {
                key: "nondependant4_child1_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant4_child1_forename')
              },
              {
                key: "nondependant4_child1_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant4_child1_lastname')
              },
              {
                key: "nondependant4_child1_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant4_child1_cb')
              },
              {
                key: "nondependant4_child1_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_child1_dob')
              },
              {
                key: "nondependant4_child1_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant4_child1_gender')
              },
              {
                key: "nondependant4_child1_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant4_child1_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 4 - Child 2",
                condition: conditions.toString('fieldset_nondependant4child2')
              },
              {
                key: "nondependant4_child2_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant4_child2_forename')
              },
              {
                key: "nondependant4_child2_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant4_child2_lastname')
              },
              {
                key: "nondependant4_child2_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant4_child2_cb')
              },
              {
                key: "nondependant4_child2_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_child2_dob')
              },
              {
                key: "nondependant4_child2_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant4_child2_gender')
              },
              {
                key: "nondependant4_child2_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant4_child2_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 4 - Child 3",
                condition: conditions.toString('fieldset_nondependant4child3')
              },
              {
                key: "nondependant4_child3_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant4_child3_forename')
              },
              {
                key: "nondependant4_child3_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant4_child3_lastname')
              },
              {
                key: "nondependant4_child3_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant4_child3_cb')
              },
              {
                key: "nondependant4_child3_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_child3_dob')
              },
              {
                key: "nondependant4_child3_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant4_child3_gender')
              },
              {
                key: "nondependant4_child3_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant4_child3_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              },
              {
                type: "help",
                helpvalue: "Nondependant 4 - Child 4",
                condition: conditions.toString('fieldset_nondependant4child4')
              },
              {
                key: "nondependant4_child4_forename",
                title: "What's the Child's forenames(s)?",
                condition: conditions.toString('nondependant4_child4_forename')
              },
              {
                key: "nondependant4_child4_lastname",
                title: "What's the Childs last name?",
                condition: conditions.toString('nondependant4_child4_lastname')
              },
              {
                key: "nondependant4_child4_cb",
                type: "selectWithInput",
                title: "Who gets Child Benefit for this Child?",
                options: ["I do", "Someone else does"],
                radios: true,
                other: "Someone else does",
                comment: "Please specify",
                condition: conditions.toString('nondependant4_child4_cb')
              },
              {
                key: "nondependant4_child4_dob",
                title: "What is this child's date of birth?",
                type: "memorabledate",
                dateFormat: "YYYY-MM-DD",
                condition: conditions.toString('nondependant4_child4_dob')
              },
              {
                key: "nondependant4_child4_gender",
                title: "Is this child a girl or a boy?",
                type: "radios",
                condition: conditions.toString('nondependant4_child4_gender')
              },
              {
                key: "nondependant4_child4_bed",
                title: "Is this child unable to share a bedroom due to disability?",
                type: "radios",
                condition: conditions.toString('nondependant4_child4_bed'),
                description: "A child is only considered to be unable to share a bedroom if someone receives the care component of child Disability Living Allowance on their behalf, at the middle or higher rate."
              }
            ]
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 29",
        page: 29,
        title: "About being self-employed",
        items: [
          {
            key: "applicant_self_employed",
            title: "Are you self-employed?",
            type: "radios"
          },
          {
            key: "applicant_kind_of_work_se",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_kind_of_work_se')
          },
          {
            key: "applicant_business_start_se",
            title: "When did the business start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_business_start_se'),
            description: "If you do not know the exact date please enter an approximate date."
          },
          {
            key: "applicant_business_name_se",
            title: "What is the business name?",
            condition: conditions.toString('applicant_business_name_se')
          },
          {
            key: "applicant_business_addressline1",
            title: "What is the business address? Address Line 1",
            condition: conditions.toString('applicant_business_addressline1')
          },
          {
            key: "applicant_business_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business_addressline2')
          },
          {
            key: "applicant_business_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business_addressline3')
          },
          {
            key: "applicant_business_postcode_se",
            title: "Postcode",
            condition: conditions.toString('applicant_business_postcode_se')
          },
          {
            key: "applicant_business_partner",
            title: "Do you have any business partners?",
            type: "radios",
            condition: conditions.toString('applicant_business_partner')
          },
          {
            key: "applicant_business_partner_name",
            title: "Business partners' names ",
            condition: conditions.toString('applicant_business_partner_name')
          },
          {
            key: "applicant_business_partner_addressline1",
            title: "What's their address? Address Line 1",
            condition: conditions.toString('applicant_business_partner_addressline1')
          },
          {
            key: "applicant_business_partner_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business_partner_addressline2')
          },
          {
            key: "applicant_business_partner_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business_partner_addressline3')
          },
          {
            key: "applicant_business_partner_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_business_partner_postcode')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do you usually work?</label>",
            condition: conditions.toString('help_applicant_self_employed_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_self_employed_hours_worked_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_self_employed_hours_worked_minutes",
                    type: "number",
                    title: "Minutes"
                  }]
              }],
            condition: conditions.toString('section_applicant_self_employed_time')
          },
          {
            key: "applicant_self_employed_startup_allow",
            title: "Do you get a Business Start-up Allowance?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_startup_allow')
          },
          {
            key: "applicant_self_employed_startup_allow_amount",
            title: "How much?",
            condition: conditions.toString('applicant_self_employed_startup_allow_amount')
          },
          {
            key: "applicant_self_employed_startup_allow_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_startup_allow_frequency')
          },
          {
            key: "applicant_self_employed_pension",
            title: "Do you pay into a private pension scheme?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_pension')
          },
          {
            key: "applicant_self_employed_pension_amount",
            title: "How much?",
            condition: conditions.toString('applicant_self_employed_pension_amount')
          },
          {
            key: "applicant_self_employed_pension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_self_employed_pension_frequency')
          },
          {
            key: "partner_self_employed",
            title: "Is your partner self-employed?",
            type: "radios",
            condition: conditions.toString('partner_self_employed')
          },
          {
            key: "partner_kind_of_work_se",
            title: "What kind of work does your partner do?",
            condition: conditions.toString('partner_kind_of_work_se')
          },
          {
            key: "partner_business_start_se",
            title: "When did the business start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you do not know the exact date please enter an approximate date.",
            condition: conditions.toString('partner_business_start_se')
          },
          {
            key: "partner_business_name_se",
            title: "What is the business name?",
            condition: conditions.toString('partner_business_name_se')
          },
          {
            key: "partner_business_address_se_1",
            title: "What is the business address? Address Line 1",
            condition: conditions.toString('partner_business_address_se_1')
          },
          {
            key: "partner_business_address_se_2",
            title: "Address Line 2",
            condition: conditions.toString('partner_business_address_se_2')
          },
          {
            key: "partner_business_address_se_3",
            title: "Address Line 3",
            condition: conditions.toString('partner_business_address_se_3')
          },
          {
            key: "partner_business_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business_postcode')
          },
          {
            key: "partner_business_partner",
            title: "Does your partner have any business partners?",
            type: "radios",
            condition: conditions.toString('partner_business_partner')
          },
          {
            key: "partner_business_partner_name",
            title: "Business partners' names",
            condition: conditions.toString('partner_business_partner_name')
          },
          {
            key: "partner_business_partner_addressline1",
            title: "Address line 1",
            condition: conditions.toString('partner_business_partner_addressline1')
          },
          {
            key: "partner_business_partner_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_business_partner_addressline2')
          },
          {
            key: "partner_business_partner_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_business_partner_addressline3')
          },
          {
            key: "partner_business_partner_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business_partner_postcode')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week does your partner usually work?</label>",
            condition: conditions.toString('help_partner_self_employed_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_self_employed_hours_worked_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_self_employed_hours_worked_minutes",
                    type: "number",
                    title: "Minutes"
                  }]
              }],
            condition: conditions.toString('section_partner_self_employed_time')
          },
          {
            key: "partner_self_employed_startup_allow",
            title: "Does your partner get a Business Start-up Allowance?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_startup_allow')
          },
          {
            key: "partner_self_employed_startup_allow_amount",
            title: "How much?",
            condition: conditions.toString('partner_self_employed_startup_allow_amount')
          },
          {
            key: "partner_self_employed_startup_allow_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_startup_allow_frequency')
          },
          {
            key: "partner_self_employed_pension",
            title: "Does your partner pay into a private pension scheme?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_pension')
          },
          {
            key: "partner_self_employed_pension_amount",
            title: "How much?",
            condition: conditions.toString('partner_self_employed_pension_amount')
          },
          {
            key: "partner_self_employed_pension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_self_employed_pension_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 30",
        title: "About working for an employer",
        page: 30,
        items: [
          {
            key: "applicant_employed",
            title: "Do you work for an employer?",
            type: "radios",
            description: "Answer Yes even if you are off sick or on maternity/paternity or adoption leave"
          },
          {
            key: "applicant_number_of_employers",
            title: "How many employers do you work for?",
            condition: conditions.toString('applicant_number_of_employers')
          },
          {
            type: "template",
            template: "<h3>Employer 1</h3>",
            condition: conditions.toString('template_applicant_employer1')
          },
          {
            key: "applicant_kind_of_work_e1",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_kind_of_work_e1')
          },
          {
            key: "applicant_business_name_e1",
            title: "What is the employer's name?",
            condition: conditions.toString('applicant_business_name_e1')
          },
          {
            key: "applicant_business1_addressline1",
            title: "What is the employers address line 1?",
            condition: conditions.toString('applicant_business1_addressline1')
          },
          {
            key: "applicant_business1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business1_addressline2')
          },
          {
            key: "applicant_business1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business1_addressline3')
          },
          {
            key: "applicant_business1_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_business1_postcode')
          },
          {
            key: "applicant_job_start1",
            title: "When did you start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you do not know the exact date please enter an approximate date.",
            condition: conditions.toString('applicant_job_start1')
          },
          {
            key: "applicant_employed_director1",
            title: "Are you a director of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_director1')
          },
          {
            key: "applicant_employed_company_own1",
            title: "Do you own all of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_own1')
          },
          {
            key: "applicant_employed_company_dividend1",
            title: "Do you receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend1')
          },
          {
            key: "applicant_employed_company_dividendvalue1",
            title: "How much?",
            condition: conditions.toString('applicant_employed_company_dividendvalue1')
          },
          {
            key: "applicant_employed_company_dividend_frequency1",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend_frequency1')
          },
          {
            key: "applicant_employed_temporary1",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('applicant_employed_temporary1')
          },
          {
            key: "applicant_employed_temporary_finish_date1",
            title: "When will you finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you do not know the exact date please enter an approximate date.",
            condition: conditions.toString('applicant_employed_temporary_finish_date1')
          },
          {
            key: "applicant_employed_pay_frequency1",
            title: "How often do you get paid for this employment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pay_frequency1')
          },
          {
            key: "applicant_employed_pay_gross1",
            title: "How much do you get paid before tax and National Insurance for this employment?",
            description: "This is the 'gross' amount shown on your payslip. If your wage varies please enter the average amount you receive.",
            condition: conditions.toString('applicant_employed_pay_gross1')
          },
          {
            key: "applicant_employed_pay_method1",
            type: "selectWithInput",
            title: "How are you paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('applicant_employed_pay_method1')
          },
          {
            key: "applicant_employed_payrise1",
            title: "Do you know when your next payrise will be from this employer?",
            type: "radios",
            condition: conditions.toString('applicant_employed_payrise1')
          },
          {
            key: "applicant_employed_pay_expected_rise1",
            title: "When will your next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you do not know the exact date please enter an approximate date.",
            condition: conditions.toString('applicant_employed_pay_expected_rise1')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do you usually work for this employer?</label>",
            condition: conditions.toString('help_applicant_employed1_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked1_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked1_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('applicant_employed_hours_worked1_minutes')
                  }]
              }],
            condition: conditions.toString('section_applicant_employed1_time'),
            description: "If your hours vary from week to week, enter the average amount of hours you usually work."
          },
          {
            key: "applicant_employed_ssp1",
            title: "Are you getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp1')
          },
          {
            key: "applicant_employed_ssp_other1",
            title: "Are you getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp_other1'),
            description: "For example: Some employers offer their staff an extra benefit via company sick pay or contractual maternity pay."
          },
          {
            type: "template",
            template: "<h3>Employer 2</h3>",

            condition: conditions.toString('template_applicant_employer2')
          },
          {
            key: "applicant_kind_of_work_e2",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_kind_of_work_e2')
          },
          {
            key: "applicant_business_name_e2",
            title: "What is the employer's name?",
            condition: conditions.toString('applicant_business_name_e2')
          },
          {
            key: "applicant_business2_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('applicant_business2_addressline1')
          },

          {
            key: "applicant_business2_addressline2",
            title: "What is the employers address? Address line 2",
            condition: conditions.toString('applicant_business2_addressline2')
          },

          {
            key: "applicant_business2_addressline3",
            title: "Address line 2",
            condition: conditions.toString('applicant_business2_addressline3')
          },
          {
            key: "applicant_business2_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_business2_postcode')
          },
          {
            key: "applicant_job_start2",
            title: "When did you start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_job_start2')
          },
          {
            key: "applicant_employed_director2",
            title: "Are you a director of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_director2')
          },
          {
            key: "applicant_employed_company_own2",
            title: "Do you own all of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_own2')
          },
          {
            key: "applicant_employed_company_dividend2",
            title: "Do you receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend2')
          },
          {
            key: "applicant_employed_company_dividendvalue2",
            title: "How much?",
            condition: conditions.toString('applicant_employed_company_dividendvalue2')
          },
          {
            key: "applicant_employed_company_dividend_frequency2",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend_frequency2')
          },
          {
            key: "applicant_employed_temporary2",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('applicant_employed_temporary2')
          },
          {
            key: "applicant_employed_temporary_finish_date2",
            title: "When will you finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_employed_temporary_finish_date2')
          },
          {
            key: "applicant_employed_pay_frequency2",
            title: "How often do you get paid for this employment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pay_frequency2')
          },
          {
            key: "applicant_employed_pay_gross2",
            title: "How much do you get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('applicant_employed_pay_gross2')
          },
          {
            key: "applicant_employed_pay_method2",
            type: "selectWithInput",
            title: "How are you paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('applicant_employed_pay_method2')
          },
          {
            key: "applicant_employed_payrise2",
            title: "Do you know when your next payrise will be from this employer?",
            type: "radios",
            condition: conditions.toString('applicant_employed_payrise2')
          },
          {
            key: "applicant_employed_pay_expected_rise2",
            title: "When will your next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_employed_pay_expected_rise2')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do you usually work for this employer?</label>",

            condition: conditions.toString('help_applicant_employed2_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked2_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked2_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('applicant_employed_hours_worked2_minutes')
                  }]
              }],
            condition: conditions.toString('section_applicant_employed2_time')
          },
          {
            key: "applicant_employed_ssp2",
            title: "Are you getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP} or Statutory Maternity Pay (SMP} from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp2')
          },
          {
            key: "applicant_employed_ssp_other2",
            title: "Are you getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp_other2')
          },
          {
            type: "template",
            template: "<h3>Employer 3</h3>",

            condition: conditions.toString('template_applicant_employer3')
          },
          {
            key: "applicant_kind_of_work_e3",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_kind_of_work_e3')
          },
          {
            key: "applicant_business_name_e3",
            title: "What is the employer's name?",
            condition: conditions.toString('applicant_business_name_e3')
          },
          {
            key: "applicant_business3_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('applicant_business3_addressline1')
          },
          {
            key: "applicant_business3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business3_addressline2')
          },
          {
            key: "applicant_business3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business3_addressline3')
          },
          {
            key: "applicant_business3_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_business3_postcode')
          },
          {
            key: "applicant_job_start3",
            title: "When did you start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_job_start3')
          },
          {
            key: "applicant_employed_director3",
            title: "Are you a director of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_director3')
          },
          {
            key: "applicant_employed_company_own3",
            title: "Do you own all of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_own3')
          },
          {
            key: "applicant_employed_company_dividend3",
            title: "Do you receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend3')
          },
          {
            key: "applicant_employed_company_dividendvalue3",
            title: "How much?",
            condition: conditions.toString('applicant_employed_company_dividendvalue3')
          },
          {
            key: "applicant_employed_company_dividend_frequency3",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend_frequency3')
          },
          {
            key: "applicant_employed_temporary3",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('applicant_employed_temporary3')
          },
          {
            key: "applicant_employed_temporary_finish_date3",
            title: "When will you finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_employed_temporary_finish_date3')
          },
          {
            key: "applicant_employed_pay_frequency3",
            title: "How often do you get paid for this employment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pay_frequency3')
          },
          {
            key: "applicant_employed_pay_gross3",
            title: "How much do you get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('applicant_employed_pay_gross3')
          },
          {
            key: "applicant_employed_pay_method3",
            type: "selectWithInput",
            title: "How are you paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('applicant_employed_pay_method3')
          },
          {
            key: "applicant_employed_payrise3",
            title: "Do you know when your next payrise will be from this employer?",
            type: "radios",
            condition: conditions.toString('applicant_employed_payrise3')
          },
          {
            key: "applicant_employed_pay_expected_rise3",
            title: "When will your next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_employed_pay_expected_rise3')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do you usually work for this employer?</label>",

            condition: conditions.toString('help_applicant_employed3_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked3_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked3_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('applicant_employed_hours_worked3_minutes')
                  }]
              }],
            condition: conditions.toString('section_applicant_employed3_time')
          },
          {
            key: "applicant_employed_ssp3",
            title: "Are you getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP} or Statutory Maternity Pay (SMP} from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp3')
          },
          {
            key: "applicant_employed_ssp_other3",
            title: "Are you getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp_other3')
          },
          {
            type: "template",
            template: "<h3>Employer 4</h3>",

            condition: conditions.toString('template_applicant_employer4')
          },
          {
            key: "applicant_kind_of_work_e4",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_kind_of_work_e4')
          },

          {
            key: "applicant_business_name_e4",
            title: "What is the employer's name?",
            condition: conditions.toString('applicant_business_name_e4')
          },

          {
            key: "applicant_business4_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('applicant_business4_addressline1')
          },
          {
            key: "applicant_business4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_business4_addressline2')
          },
          {
            key: "applicant_business4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_business4_addressline3')
          },
          {
            key: "applicant_business4_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_business4_postcode')
          },
          {
            key: "applicant_job_start4",
            title: "When did you start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_job_start4')
          },
          {
            key: "applicant_employed_director4",
            title: "Are you a director of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_director4')
          },
          {
            key: "applicant_employed_company_own4",
            title: "Do you own all of this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_own4')
          },
          {
            key: "applicant_employed_company_dividend4",
            title: "Do you receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend4')
          },
          {
            key: "applicant_employed_company_dividendvalue4",
            title: "How much?",
            condition: conditions.toString('applicant_employed_company_dividendvalue4')
          },
          {
            key: "applicant_employed_company_dividend_frequency4",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_employed_company_dividend_frequency4')
          },
          {
            key: "applicant_employed_temporary4",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('applicant_employed_temporary4')
          },
          {
            key: "applicant_employed_temporary_finish_date4",
            title: "When will you finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_employed_temporary_finish_date4')
          },
          {
            key: "applicant_employed_pay_frequency4",
            title: "How often do you get paid for this employment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pay_frequency4')
          },
          {
            key: "applicant_employed_pay_gross4",
            title: "How much do you get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('applicant_employed_pay_gross4')
          },
          {
            key: "applicant_employed_pay_method4",
            type: "selectWithInput",
            title: "How are you paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('applicant_employed_pay_method4')
          },
          {
            key: "applicant_employed_payrise4",
            title: "Do you know when your next payrise will be from this employer?",
            type: "radios",
            condition: conditions.toString('applicant_employed_payrise4')
          },
          {
            key: "applicant_employed_pay_expected_rise4",
            title: "When will your next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_employed_pay_expected_rise4')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do you usually work for this employer?</label>",

            condition: conditions.toString('help_applicant_employed4_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked4_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "applicant_employed_hours_worked4_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('applicant_employed_hours_worked4_minutes')
                  }]
              }],
            condition: conditions.toString('section_applicant_employed4_time')
          },
          {
            key: "applicant_employed_ssp4",
            title: "Are you getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP} or Statutory Maternity Pay (SMP} from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp4')
          },
          {
            key: "applicant_employed_ssp_other4",
            title: "Are you getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('applicant_employed_ssp_other4')
          },
          {
            key: "applicant_employed_pension",
            title: "Do you pay into a private or company pension scheme?",
            type: "radios"
          },
          {
            key: "applicant_employed_pension_number",
            title: "How many private or company pensions do you pay into?",
            condition: conditions.toString('applicant_employed_pension_number')
          },
          {
            type: "template",
            template: "<h3>Pension 1</h3>",

            condition: conditions.toString('template_applicant_pension1')
          },
          {
            key: "applicant_employed_pension_name1",
            title: "What is the company name?",
            condition: conditions.toString('applicant_employed_pension_name1')
          },
          {
            key: "applicant_employed_pension_amount1",
            title: "How much do you pay into this pension?",
            condition: conditions.toString('applicant_employed_pension_amount1')
          },
          {
            key: "applicant_employed_pension_frequency1",
            title: "How often do you pay into this pension?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pension_frequency1')
          },
          {
            type: "template",
            template: "<h3>Pension 2</h3>",

            condition: conditions.toString('template_applicant_pension2')
          },
          {
            key: "applicant_employed_pension_name2",
            title: "What is the company name?",
            condition: conditions.toString('applicant_employed_pension_name2')
          },
          {
            key: "applicant_employed_pension_amount2",
            title: "How much do you pay into this pension?",
            condition: conditions.toString('applicant_employed_pension_amount2')
          },
          {
            key: "applicant_employed_pension_frequency2",
            title: "How often do you pay into this pension?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pension_frequency2')
          },
          {
            type: "template",
            template: "<h3>Pension 3</h3>",

            condition: conditions.toString('template_applicant_pension3')
          },
          {
            key: "applicant_employed_pension_name3",
            title: "What is the company name?",
            condition: conditions.toString('applicant_employed_pension_name3')
          },
          {
            key: "applicant_employed_pension_amount3",
            title: "How much do you pay into this pension?",
            condition: conditions.toString('applicant_employed_pension_amount3')
          },
          {
            key: "applicant_employed_pension_frequency3",
            title: "How often do you pay into this pension?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pension_frequency3')
          },
          {
            type: "template",
            template: "<h3>Pension 4</h3>",

            condition: conditions.toString('template_applicant_pension4')
          },
          {
            key: "applicant_employed_pension_name4",
            title: "What is the company name?",
            condition: conditions.toString('applicant_employed_pension_name4')
          },
          {
            key: "applicant_employed_pension_amount4",
            title: "How much do you pay into this pension?",
            condition: conditions.toString('applicant_employed_pension_amount4')
          },
          {
            key: "applicant_employed_pension_frequency4",
            title: "How often do you pay into this pension?",
            type: "radios",
            condition: conditions.toString('applicant_employed_pension_frequency4')
          },
          {
            key: "partner_employed",
            title: "Does your partner work for an employer?",
            type: "radios",
            condition: conditions.toString('partner_employed')
          },
          {
            key: "partner_number_of_employers",
            title: "How many employers do they work for?",
            condition: conditions.toString('partner_number_of_employers')
          },
          {
            type: "template",
            template: "<h3>Employer 1</h3>",
            condition: conditions.toString('partner_applicant_employer1')
          },
          {
            key: "partner_kind_of_work_e1",
            title: "What kind of work do they do?",
            condition: conditions.toString('partner_kind_of_work_e1')
          },
          {
            key: "partner_business_name_e1",
            title: "What is the employer's name?",
            condition: conditions.toString('partner_business_name_e1')
          },
          {
            key: "partner_business1_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('partner_business1_addressline1')
          },
          {
            key: "partner_business1_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_business1_addressline2')
          },
          {
            key: "partner_business1_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_business1_addressline3')
          },
          {
            key: "partner_business1_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business1_postcode')
          },
          {
            key: "partner_job_start1",
            title: "When did they start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_job_start1')
          },
          {
            key: "partner_employed_director1",
            title: "Are they a director of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_director1')
          },
          {
            key: "partner_employed_company_own1",
            title: "Do they own all of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_own1')
          },
          {
            key: "partner_employed_company_dividend1",
            title: "Do they receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend1')
          },
          {
            key: "partner_employed_company_dividendvalue1",
            title: "How much?",
            condition: conditions.toString('partner_employed_company_dividendvalue1')
          },
          {
            key: "partner_employed_company_dividend_frequency1",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend_frequency1')
          },
          {
            key: "partner_employed_temporary1",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('partner_employed_temporary1')
          },
          {
            key: "partner_employed_temporary_finish_date1",
            title: "When will they finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_temporary_finish_date1')
          },
          {
            key: "partner_employed_pay_frequency1",
            title: "How often do they get paid for this employment?",
            type: "radios",
            condition: conditions.toString('partner_employed_pay_frequency1')
          },
          {
            key: "partner_employed_pay_gross1",
            title: "How much do they get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('partner_employed_pay_gross1')
          },
          {
            key: "partner_employed_pay_method1",
            type: "selectWithInput",
            title: "How are they paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('partner_employed_pay_method1')
          },
          {
            key: "partner_employed_payrise1",
            type: "radios",
            title: "Does your partner know when their next payrise will be from this employer?",
            condition: conditions.toString('partner_employed_payrise1')
          },
          {
            key: "partner_employed_pay_expected_rise1",
            title: "When does your partner expect their next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_pay_expected_rise1')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do they usually work for this employer?</label>",
            condition: conditions.toString('help_partner_employed1_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked1_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked1_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('partner_employed_hours_worked1_minutes')
                  }]
              }],
            condition: conditions.toString('section_partner_employed1_time')
          },
          {
            key: "partner_employed_ssp1",
            title: "Are they getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('partner_employed_ssp1')
          },
          {
            key: "partner_employed_ssp_other1",
            title: "Are they getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            description: "For example, company sick or maternity pay.",
            condition: conditions.toString('partner_employed_ssp_other1')
          },
          {
            type: "template",
            template: "<h3>Employer 2</h3>",

            condition: conditions.toString('partner_applicant_employer2')
          },
          {
            key: "partner_kind_of_work_e2",
            title: "What kind of work do they do?",
            condition: conditions.toString('partner_kind_of_work_e2')
          },
          {
            key: "partner_business_name_e2",
            title: "What is the employer's name?",
            condition: conditions.toString('partner_business_name_e2')
          },
          {
            key: "partner_business2_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('partner_business2_addressline1')
          },
          {
            key: "partner_business2_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_business2_addressline2')
          },
          {
            key: "partner_business2_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_business2_addressline3')
          },
          {
            key: "partner_business2_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business2_postcode')
          },
          {
            key: "partner_job_start2",
            title: "When did they start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_job_start2')
          },
          {
            key: "partner_employed_director2",
            title: "Are they a director of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_director2')
          },
          {
            key: "partner_employed_company_own2",
            title: "Do they own all of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_own2')
          },
          {
            key: "partner_employed_company_dividend2",
            title: "Do they receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend2')
          },
          {
            key: "partner_employed_company_dividendvalue2",
            title: "How much?",
            condition: conditions.toString('partner_employed_company_dividendvalue2')
          },
          {
            key: "partner_employed_company_dividend_frequency2",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend_frequency2')
          },
          {
            key: "partner_employed_temporary2",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('partner_employed_temporary2')
          },
          {
            key: "partner_employed_temporary_finish_date2",
            title: "When will they finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_temporary_finish_date2')
          },
          {
            key: "partner_employed_pay_frequency2",
            title: "How often do they get paid for this employment?",
            type: "radios",
            condition: conditions.toString('partner_employed_pay_frequency2')
          },
          {
            key: "partner_employed_pay_gross2",
            title: "How much do they get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('partner_employed_pay_gross2')
          },
          {
            key: "partner_employed_pay_method2",
            type: "selectWithInput",
            title: "How are they paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('partner_employed_pay_method2')
          },
          {
            key: "partner_employed_payrise2",
            type: "radios",
            title: "Does your partner know when their next payrise will be from this employer?",
            condition: conditions.toString('partner_employed_payrise2')
          },
          {
            key: "partner_employed_pay_expected_rise2",
            title: "When does your partner expect their next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_pay_expected_rise2')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do they usually work for this employer?</label>",

            condition: conditions.toString('help_partner_employed2_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked2_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked2_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('partner_employed_hours_worked2_minutes')
                  }]
              }],
            condition: conditions.toString('section_partner_employed2_time')
          },
          {
            key: "partner_employed_ssp2",
            title: "Are they getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('partner_employed_ssp2')
          },
          {
            key: "partner_employed_ssp_other2",
            title: "Are they getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            description: "For example, company sick or maternity pay.",
            condition: conditions.toString('partner_employed_ssp_other2')
          },
          {
            type: "template",
            template: "<h3>Employer 3</h3>",

            condition: conditions.toString('partner_applicant_employer3')
          },
          {
            key: "partner_kind_of_work_e3",
            title: "What kind of work do they do?",
            condition: conditions.toString('partner_kind_of_work_e3')
          },
          {
            key: "partner_business_name_e3",
            title: "What is the employer's name?",
            condition: conditions.toString('partner_business_name_e3')
          },
          {
            key: "partner_business3_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('partner_business3_addressline1')
          },
          {
            key: "partner_business3_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_business3_addressline2')
          },
          {
            key: "partner_business3_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_business3_addressline3')
          },
          {
            key: "partner_business3_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business3_postcode')
          },
          {
            key: "partner_job_start3",
            title: "When did they start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_job_start3')
          },
          {
            key: "partner_employed_director3",
            title: "Are they a director of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_director3')
          },
          {
            key: "partner_employed_company_own3",
            title: "Do they own all of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_own3')
          },
          {
            key: "partner_employed_company_dividend3",
            title: "Do they receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend3')
          },
          {
            key: "partner_employed_company_dividendvalue3",
            title: "How much?",
            condition: conditions.toString('partner_employed_company_dividendvalue3')
          },
          {
            key: "partner_employed_company_dividend_frequency3",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend_frequency3')
          },
          {
            key: "partner_employed_temporary3",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('partner_employed_temporary3')
          },
          {
            key: "partner_employed_temporary_finish_date3",
            title: "When will they finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_temporary_finish_date3')
          },
          {
            key: "partner_employed_pay_frequency3",
            title: "How often do they get paid for this employment?",
            type: "radios",
            condition: conditions.toString('partner_employed_pay_frequency3')
          },
          {
            key: "partner_employed_pay_gross3",
            title: "How much do they get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('partner_employed_pay_gross3')
          },
          {
            key: "partner_employed_pay_method3",
            type: "selectWithInput",
            title: "How are they paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('partner_employed_pay_method3')
          },
          {
            key: "partner_employed_payrise3",
            type: "radios",
            title: "Does your partner know when their next payrise will be from this employer?",
            condition: conditions.toString('partner_employed_payrise3')
          },
          {
            key: "partner_employed_pay_expected_rise3",
            title: "When does your partner expect their next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_pay_expected_rise3')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do they usually work for this employer?</label>",

            condition: conditions.toString('help_partner_employed3_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked3_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked3_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('partner_employed_hours_worked3_minutes')
                  }]
              }],
            condition: conditions.toString('section_partner_employed3_time')
          },
          {
            key: "partner_employed_ssp3",
            title: "Are they getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('partner_employed_ssp3')
          },
          {
            key: "partner_employed_ssp_other3",
            title: "Are they getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            description: "For example, company sick or maternity pay.",
            condition: conditions.toString('partner_employed_ssp_other3')
          },
          {
            type: "template",
            template: "<h3>Employer 4</h3>",

            condition: conditions.toString('partner_applicant_employer4')
          },
          {
            key: "partner_kind_of_work_e4",
            title: "What kind of work do they do?",
            condition: conditions.toString('partner_kind_of_work_e4')
          },
          {
            key: "partner_business_name_e4",
            title: "What is the employer's name?",
            condition: conditions.toString('partner_business_name_e4')
          },
          {
            key: "partner_business4_addressline1",
            title: "What is the employers address? Address line 1",
            condition: conditions.toString('partner_business4_addressline1')
          },
          {
            key: "partner_business4_addressline2",
            title: "Address line 2",
            condition: conditions.toString('partner_business4_addressline2')
          },
          {
            key: "partner_business4_addressline3",
            title: "Address line 3",
            condition: conditions.toString('partner_business4_addressline3')
          },
          {
            key: "partner_business4_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_business4_postcode')
          },
          {
            key: "partner_job_start4",
            title: "When did they start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_job_start4')
          },
          {
            key: "partner_employed_director4",
            title: "Are they a director of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_director4')
          },
          {
            key: "partner_employed_company_own4",
            title: "Do they own all of this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_own4')
          },
          {
            key: "partner_employed_company_dividend4",
            title: "Do they receive a director's dividend for this company?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend4')
          },
          {
            key: "partner_employed_company_dividendvalue4",
            title: "How much?",
            condition: conditions.toString('partner_employed_company_dividendvalue4')
          },
          {
            key: "partner_employed_company_dividend_frequency4",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_employed_company_dividend_frequency4')
          },
          {
            key: "partner_employed_temporary4",
            title: "Is this employment for a temporary period (for example, agency work)?",
            type: "radios",
            condition: conditions.toString('partner_employed_temporary4')
          },
          {
            key: "partner_employed_temporary_finish_date4",
            title: "When will they finish this employment?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_temporary_finish_date4')
          },
          {
            key: "partner_employed_pay_frequency4",
            title: "How often do they get paid for this employment?",
            type: "radios",
            condition: conditions.toString('partner_employed_pay_frequency4')
          },
          {
            key: "partner_employed_pay_gross4",
            title: "How much do they get paid before tax and National Insurance for this employment?",
            condition: conditions.toString('partner_employed_pay_gross4')
          },
          {
            key: "partner_employed_pay_method4",
            type: "selectWithInput",
            title: "How are they paid for this employment, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('partner_employed_pay_method4')
          },
          {
            key: "partner_employed_payrise4",
            type: "radios",
            title: "Does your partner know when their next payrise will be from this employer?",
            condition: conditions.toString('partner_employed_payrise4')
          },
          {
            key: "partner_employed_pay_expected_rise4",
            title: "When does your partner expect their next pay rise to be from this employer?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_employed_pay_expected_rise4')
          },
          {
            type: "help",
            htmlClass: "form-group",
            helpvalue: "<label>How many hours a week do they usually work for this employer?</label>",

            condition: conditions.toString('help_partner_employed4_time')
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked4_hours",
                    type: "number",
                    title: "Hours"
                  }]
              },
              {
                type: "section",
                htmlClass: "col-xs-6 col-sm-3 col-lg-2",
                items: [
                  {
                    key: "partner_employed_hours_worked4_minutes",
                    type: "number",
                    title: "Minutes",
                    condition: conditions.toString('partner_employed_hours_worked4_minutes')
                  }]
              }],
            condition: conditions.toString('section_partner_employed4_time')
          },
          {
            key: "partner_employed_ssp4",
            title: "Are they getting Statutory Sick Pay (SSP), Statutory Paternity Pay (SPP) or Statutory Maternity Pay (SMP) from this employer at the moment?",
            type: "radios",
            condition: conditions.toString('partner_employed_ssp4')
          },
          {
            key: "partner_employed_ssp_other4",
            title: "Are they getting any other sick pay or maternity pay from this employer at the moment?",
            type: "radios",
            description: "For example, company sick or maternity pay.",
            condition: conditions.toString('partner_employed_ssp_other4')
          },
          {
            key: "partner_employed_pension",
            title: "Does your partner pay into a private or company pension scheme?",
            type: "radios",
            condition: conditions.toString('partner_employed_pension')
          },
          {
            key: "partner_employed_pension_number",
            title: "How many private or company pensions do they pay into?",
            condition: conditions.toString('partner_employed_pension_number')
          },
          {
            type: "template",
            template: "<h3>Pension 1</h3>",

            condition: conditions.toString('template_partner_pension1')
          },
          {
            key: "partner_employed_pension_name1",
            title: "What is the company name?",
            condition: conditions.toString('partner_employed_pension_name1')
          },
          {
            key: "partner_employed_pension_amount1",
            title: "How much do they pay into this pension?",
            condition: conditions.toString('partner_employed_pension_amount1')
          },
          {
            key: "partner_employed_pension_frequency1",
            title: "How often do they pay into this pension?",
            type: "radios",
            condition: conditions.toString('partner_employed_pension_frequency1')
          },
          {
            type: "template",
            template: "<h3>Pension 2</h3>",

            condition: conditions.toString('template_partner_pension2')
          },
          {
            key: "partner_employed_pension_name2",
            title: "What is the company name?",
            condition: conditions.toString('partner_employed_pension_name2')
          },
          {
            key: "partner_employed_pension_amount2",
            title: "How much do they pay into this pension?",
            condition: conditions.toString('partner_employed_pension_amount2')
          },
          {
            key: "partner_employed_pension_frequency2",
            title: "How often do they pay into this pension?",
            type: "radios",
            condition: conditions.toString('partner_employed_pension_frequency2')
          },
          {
            type: "template",
            template: "<h3>Pension 3</h3>",

            condition: conditions.toString('template_partner_pension3')
          },
          {
            key: "partner_employed_pension_name3",
            title: "What is the company name?",
            condition: conditions.toString('partner_employed_pension_name3')
          },
          {
            key: "partner_employed_pension_amount3",
            title: "How much do they pay into this pension?",
            condition: conditions.toString('partner_employed_pension_amount3')
          },
          {
            key: "partner_employed_pension_frequency3",
            title: "How often do they pay into this pension?",
            type: "radios",
            condition: conditions.toString('partner_employed_pension_frequency3')
          },
          {
            type: "template",
            template: "<h3>Pension 4</h3>",

            condition: conditions.toString('template_partner_pension4')
          },
          {
            key: "partner_employed_pension_name4",
            title: "What is the company name?",
            condition: conditions.toString('partner_employed_pension_name4')
          },
          {
            key: "partner_employed_pension_amount4",
            title: "How much do they pay into this pension?",
            condition: conditions.toString('partner_employed_pension_amount4')
          },
          {
            key: "partner_employed_pension_frequency4",
            title: "How often do they pay into this pension?",
            type: "radios",
            condition: conditions.toString('partner_employed_pension_frequency4')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 31",
        title: "About any other work",
        page: 31,
        items: [
          {
            key: "applicant_other_work",
            title: "Do you do any other work at all? This could be voluntary work or any other work, even if it is not paid work.",
            type: "radios"
          },
          {
            key: "applicant_other_work_type",
            title: "What kind of work do you do?",
            condition: conditions.toString('applicant_other_work_type')
          },
          {
            key: "applicant_other_work_employer",
            title: "What is the name of the person or organisation you do this work for?",
            condition: conditions.toString('applicant_other_work_employer')
          },
          {
            key: "applicant_other_work_employer_address1",
            title: "What is the address of the person or organisation you do this work for? Address Line 1",
            condition: conditions.toString('applicant_other_work_employer_address1')
          },
          {
            key: "applicant_other_work_employer_address2",
            title: "Address Line 2",
            condition: conditions.toString('applicant_other_work_employer_address2')
          },
          {
            key: "applicant_other_work_employer_address3",
            title: "Address Line 3",
            condition: conditions.toString('applicant_other_work_employer_address3')
          },
          {
            key: "applicant_other_work_employer_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_other_work_employer_postcode')
          },
          {
            key: "applicant_other_work_employer_start_dt",
            title: "When did you start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_other_work_employer_start_dt')
          },
          {
            key: "applicant_other_work_director",
            title: "Are you a director of this company?",
            type: "radios",
            condition: conditions.toString('applicant_other_work_director')
          },
          {
            key: "applicant_other_work_company_own",
            title: "Do you own all of this company?",
            type: "radios",
            condition: conditions.toString('applicant_other_work_company_own')
          },
          {
            key: "applicant_other_work_company_dividend",
            title: "Do you receive a director's dividend?",
            type: "radios",
            condition: conditions.toString('applicant_other_work_company_dividend')
          },
          {
            key: "applicant_other_work_company_dividend_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_work_company_dividend_amount')
          },
          {
            key: "applicant_other_work_company_dividend_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_work_company_dividend_frequency')
          },
          {
            key: "partner_other_work",
            title: "Does your partner do any other work at all? This could be voluntary work or any other work,  even if it is not paid work.",
            type: "radios",
            condition: conditions.toString('partner_other_work')
          },
          {
            key: "partner_other_work_type",
            title: "What kind of work do they do?",
            condition: conditions.toString('partner_other_work_type')
          },
          {
            key: "partner_other_work_employer",
            title: "What is the name of the person or organisation they do this work for?",
            condition: conditions.toString('partner_other_work_employer')
          },
          {
            key: "partner_other_work_employer_address1",
            title: "What is the address of the person or organisation they do this work for? Address 1",
            condition: conditions.toString('partner_other_work_employer_address1')
          },
          {
            key: "partner_other_work_employer_address2",
            title: "Address 2",
            condition: conditions.toString('partner_other_work_employer_address2')
          },
          {
            key: "partner_other_work_employer_address3",
            title: "Address 3",
            condition: conditions.toString('partner_other_work_employer_address3')
          },
          {
            key: "partner_other_work_employer_postcode",
            title: "Postcode",
            condition: conditions.toString('partner_other_work_employer_postcode')
          },
          {
            key: "partner_other_work_employer_tel",
            title: "What is the telephone number of the person or organisation they do this work for?",
            condition: conditions.toString('partner_other_work_employer_tel')
          },
          {
            key: "partner_other_work_employer_start_dt",
            title: "When did they start this job?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_other_work_employer_start_dt')
          },
          {
            key: "partner_other_work_director",
            title: "Is your partner a director of this company?",
            type: "radios",
            condition: conditions.toString('partner_other_work_director')
          },
          {
            key: "partner_other_work_company_own",
            title: "Do they own all of this company?",
            type: "radios",
            condition: conditions.toString('partner_other_work_company_own')
          },
          {
            key: "partner_other_work_company_dividend",
            title: "Do they receive a director's dividend?",
            type: "radios",
            condition: conditions.toString('partner_other_work_company_dividend')
          },
          {
            key: "partner_other_work_company_dividend_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_work_company_dividend_amount')
          },
          {
            key: "partner_other_work_company_dividend_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_work_company_dividend_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 32",
        title: "About other money coming in",
        page: 32,
        items: [
          {
            key: "applicant_other_benefit_type",
            title: "Of the following benefits, pensions, or other income, select all that you are currently receiving, or have a claim in progress for:",
            type: "checkboxes"
          },
          {
            key: "applicant_other_benefit_adoptionpay_status",
            title: "Getting Adoption Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_adoptionpay_status')
          },
          {
            key: "applicant_other_benefit_adoptionpay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_adoptionpay_amount')
          },
          {
            key: "applicant_other_benefit_adoptionpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_adoptionpay_frequency')
          },
          {
            key: "applicant_other_benefit_armedforcescompensation_status",
            title: "Getting armed forces compensation now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_armedforcescompensation_status')
          },
          {
            key: "applicant_other_benefit_armedforcescompensation_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_armedforcescompensation_amount')
          },
          {
            key: "applicant_other_benefit_armedforcescompensation_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_armedforcescompensation_frequency')
          },
          {
            key: "applicant_other_benefit_bereavementallowance_status",
            title: "Getting Bereavement Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_bereavementallowance_status')
          },
          {
            key: "applicant_other_benefit_bereavementallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_bereavementallowance_amount')
          },
          {
            key: "applicant_other_benefit_bereavementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_bereavementallowance_frequency')
          },
          {
            key: "applicant_other_benefit_carersallowance_status",
            title: "Getting Carer's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_carersallowance_status')
          },
          {
            key: "applicant_other_benefit_carersallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_carersallowance_amount')
          },
          {
            key: "applicant_other_benefit_carersallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_carersallowance_frequency')
          },
          {
            key: "applicant_other_benefit_childbenefit_status",
            title: "Getting Child Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childbenefit_status')
          },
          {
            key: "applicant_other_benefit_childbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_childbenefit_amount')
          },
          {
            key: "applicant_other_benefit_childbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_childtaxcredit_status",
            title: "Getting Child Tax Credit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childtaxcredit_status')
          },
          {
            key: "applicant_other_benefit_childtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_childtaxcredit_amount')
          },
          {
            key: "applicant_other_benefit_childtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_childtaxcredit_frequency')
          },
          {
            key: "applicant_other_benefit_contributionesa_status",
            title: "Getting Contribution-based Employment and Support Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionesa_status')
          },
          {
            key: "applicant_other_benefit_contributionesa_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_contributionesa_amount')
          },
          {
            key: "applicant_other_benefit_contributionesa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionesa_frequency')
          },
          {
            key: "applicant_other_benefit_contributionjsa_status",
            title: "Getting Contribution-based Jobseeker's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionjsa_status')
          },
          {
            key: "applicant_other_benefit_contributionjsa_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_contributionjsa_amount')
          },
          {
            key: "applicant_other_benefit_contributionjsa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_contributionjsa_frequency')
          },
          {
            key: "applicant_other_benefit_fosteringallowance_status",
            title: "Getting Fostering Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_fosteringallowance_status')
          },
          {
            key: "applicant_other_benefit_fosteringallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_fosteringallowance_amount')
          },
          {
            key: "applicant_other_benefit_fosteringallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_fosteringallowance_frequency')
          },
          {
            key: "applicant_other_benefit_guardiansallowance_status",
            title: "Getting Guardian's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_guardiansallowance_status')
          },
          {
            key: "applicant_other_benefit_guardiansallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_guardiansallowance_amount')
          },
          {
            key: "applicant_other_benefit_guardiansallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_guardiansallowance_frequency')
          },
          {
            key: "applicant_other_benefit_incapacitybenefit_status",
            title: "Getting Incapacity Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_incapacitybenefit_status')
          },
          {
            key: "applicant_other_benefit_incapacitybenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_incapacitybenefit_amount')
          },
          {
            key: "applicant_other_benefit_incapacitybenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_incapacitybenefit_frequency')
          },
          {
            key: "applicant_other_benefit_industrialdeathbenefit_status",
            title: "Getting Industrial Death Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_industrialdeathbenefit_status')
          },
          {
            key: "applicant_other_benefit_industrialdeathbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_industrialdeathbenefit_amount')
          },
          {
            key: "applicant_other_benefit_industrialdeathbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_industrialdeathbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_injuriesdisablementbenefit_status",
            title: "Getting Industrial Injuries Disablement Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_injuriesdisablementbenefit_status')
          },
          {
            key: "applicant_other_benefit_injuriesdisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_injuriesdisablementbenefit_amount')
          },
          {
            key: "applicant_other_benefit_injuriesdisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_injuriesdisablementbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_maintenancepayment_status",
            title: "Getting Maintenance Payment now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maintenancepayment_status')
          },
          {
            key: "applicant_other_benefit_maintenancepayment_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_maintenancepayment_amount')
          },
          {
            key: "applicant_other_benefit_maintenancepayment_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maintenancepayment_frequency')
          },
          {
            key: "applicant_other_benefit_maternityallowance_status",
            title: "Getting Maternity Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maternityallowance_status')
          },
          {
            key: "applicant_other_benefit_maternityallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_maternityallowance_amount')
          },
          {
            key: "applicant_other_benefit_maternityallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_maternityallowance_frequency')
          },
          {
            key: "applicant_other_benefit_severedisablementallowance_status",
            title: "Getting Severe Disablement Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_severedisablementallowance_status')
          },
          {
            key: "applicant_other_benefit_severedisablementallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_severedisablementallowance_amount')
          },
          {
            key: "applicant_other_benefit_severedisablementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_severedisablementallowance_frequency')
          },
          {
            key: "applicant_other_benefit_stateretirementpension_status",
            title: "Getting State Retirement Pension now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_stateretirementpension_status')
          },
          {
            key: "applicant_other_benefit_stateretirementpension_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_stateretirementpension_amount')
          },
          {
            key: "applicant_other_benefit_stateretirementpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_stateretirementpension_frequency')
          },
          {
            key: "applicant_other_benefit_statutorymaternitypay_status",
            title: "Getting Statutory Maternity Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorymaternitypay_status')
          },
          {
            key: "applicant_other_benefit_statutorymaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_statutorymaternitypay_amount')
          },
          {
            key: "applicant_other_benefit_statutorymaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorymaternitypay_frequency')
          },
          {
            key: "applicant_other_benefit_statutorypaternitypay_status",
            title: "Getting Statutory Paternity Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorypaternitypay_status')
          },
          {
            key: "applicant_other_benefit_statutorypaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_statutorypaternitypay_amount')
          },
          {
            key: "applicant_other_benefit_statutorypaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorypaternitypay_frequency')
          },
          {
            key: "applicant_other_benefit_statutorysickpay_status",
            title: "Getting Statutory Sick Pay now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorysickpay_status')
          },
          {
            key: "applicant_other_benefit_statutorysickpay_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_statutorysickpay_amount')
          },
          {
            key: "applicant_other_benefit_statutorysickpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_statutorysickpay_frequency')
          },
          {
            key: "applicant_other_benefit_trainingallowance_status",
            title: "Getting Training Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_trainingallowance_status')
          },
          {
            key: "applicant_other_benefit_trainingallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_trainingallowance_amount')
          },
          {
            key: "applicant_other_benefit_trainingallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_trainingallowance_frequency')
          },
          {
            key: "applicant_other_benefit_wardisablementbenefit_status",
            title: "Getting War Disablement Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_wardisablementbenefit_status')
          },
          {
            key: "applicant_other_benefit_wardisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_wardisablementbenefit_amount')
          },
          {
            key: "applicant_other_benefit_wardisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_wardisablementbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_warpension_status",
            title: "Getting War Pension or War Widow's Pension now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_warpension_status')
          },
          {
            key: "applicant_other_benefit_warpension_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_warpension_amount')
          },
          {
            key: "applicant_other_benefit_warpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_warpension_frequency')
          },
          {
            key: "applicant_other_benefit_widowbenefit_status",
            title: "Getting Widow or Widower's Benefit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowbenefit_status')
          },
          {
            key: "applicant_other_benefit_widowbenefit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_widowbenefit_amount')
          },
          {
            key: "applicant_other_benefit_widowbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowbenefit_frequency')
          },
          {
            key: "applicant_other_benefit_widowedparentsallowance_status",
            title: "Getting Widowed Parent's Allowance now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowedparentsallowance_status')
          },
          {
            key: "applicant_other_benefit_widowedparentsallowance_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_widowedparentsallowance_amount')
          },
          {
            key: "applicant_other_benefit_widowedparentsallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_widowedparentsallowance_frequency')
          },
          {
            key: "applicant_other_benefit_workingtaxcredit_status",
            title: "Getting Working Tax Credit now or are you waiting to hear?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_workingtaxcredit_status')
          },
          {
            key: "applicant_other_benefit_workingtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_workingtaxcredit_amount')
          },
          {
            key: "applicant_other_benefit_workingtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('applicant_other_benefit_workingtaxcredit_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 33",
        title: "About other money coming in - Your Partner",
        page: 33,
        items: [
          {
            key: "partner_other_benefit_type",
            title: "Of the following benefits, pensions, or other income, select all that your parner is currently receiving, or has a claim in progress for:",
            type: "checkboxes",
            condition: conditions.toString('partner_other_benefit_type')
          },
          {
            key: "partner_other_benefit_adoptionpay_status",
            title: "Getting Adoption Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_adoptionpay_status')
          },
          {
            key: "partner_other_benefit_adoptionpay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_adoptionpay_amount')
          },
          {
            key: "partner_other_benefit_adoptionpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_adoptionpay_frequency')
          },
          {
            key: "partner_other_benefit_armedforcescompensation_status",
            title: "Getting armed forces compensation now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_armedforcescompensation_status')
          },
          {
            key: "partner_other_benefit_armedforcescompensation_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_armedforcescompensation_amount')
          },
          {
            key: "partner_other_benefit_armedforcescompensation_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_armedforcescompensation_frequency')
          },
          {
            key: "partner_other_benefit_bereavementallowance_status",
            title: "Getting Bereavement Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_bereavementallowance_status')
          },
          {
            key: "partner_other_benefit_bereavementallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_bereavementallowance_amount')
          },
          {
            key: "partner_other_benefit_bereavementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_bereavementallowance_frequency')
          },
          {
            key: "partner_other_benefit_carersallowance_status",
            title: "Getting Carer's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_carersallowance_status')
          },
          {
            key: "partner_other_benefit_carersallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_carersallowance_amount')
          },
          {
            key: "partner_other_benefit_carersallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_carersallowance_frequency')
          },
          {
            key: "partner_other_benefit_childbenefit_status",
            title: "Getting Child Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childbenefit_status')
          },
          {
            key: "partner_other_benefit_childbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_childbenefit_amount')
          },
          {
            key: "partner_other_benefit_childbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childbenefit_frequency')
          },
          {
            key: "partner_other_benefit_childtaxcredit_status",
            title: "Getting Child Tax Credit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childtaxcredit_status')
          },
          {
            key: "partner_other_benefit_childtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_childtaxcredit_amount')
          },
          {
            key: "partner_other_benefit_childtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_childtaxcredit_frequency')
          },
          {
            key: "partner_other_benefit_contributionesa_status",
            title: "Getting Contribution-based Employment and Support Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionesa_status')
          },
          {
            key: "partner_other_benefit_contributionesa_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_contributionesa_amount')
          },
          {
            key: "partner_other_benefit_contributionesa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionesa_frequency')
          },
          {
            key: "partner_other_benefit_contributionjsa_status",
            title: "Getting Contribution-based Jobseeker's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionjsa_status')
          },
          {
            key: "partner_other_benefit_contributionjsa_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_contributionjsa_amount')
          },
          {
            key: "partner_other_benefit_contributionjsa_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_contributionjsa_frequency')
          },
          {
            key: "partner_other_benefit_fosteringallowance_status",
            title: "Getting Fostering Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_fosteringallowance_status')
          },
          {
            key: "partner_other_benefit_fosteringallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_fosteringallowance_amount')
          },
          {
            key: "partner_other_benefit_fosteringallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_fosteringallowance_frequency')
          },
          {
            key: "partner_other_benefit_guardiansallowance_status",
            title: "Getting Guardian's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_guardiansallowance_status')
          },
          {
            key: "partner_other_benefit_guardiansallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_guardiansallowance_amount')
          },
          {
            key: "partner_other_benefit_guardiansallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_guardiansallowance_frequency')
          },
          {
            key: "partner_other_benefit_incapacitybenefit_status",
            title: "Getting Incapacity Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_incapacitybenefit_status')
          },
          {
            key: "partner_other_benefit_incapacitybenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_incapacitybenefit_amount')
          },
          {
            key: "partner_other_benefit_incapacitybenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_incapacitybenefit_frequency')
          },
          {
            key: "partner_other_benefit_industrialdeathbenefit_status",
            title: "Getting Industrial Death Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_industrialdeathbenefit_status')
          },
          {
            key: "partner_other_benefit_industrialdeathbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_industrialdeathbenefit_amount')
          },
          {
            key: "partner_other_benefit_industrialdeathbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_industrialdeathbenefit_frequency')
          },
          {
            key: "partner_other_benefit_injuriesdisablementbenefit_status",
            title: "Getting Industrial Injuries Disablement Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_injuriesdisablementbenefit_status')
          },
          {
            key: "partner_other_benefit_injuriesdisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_injuriesdisablementbenefit_amount')
          },
          {
            key: "partner_other_benefit_injuriesdisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_injuriesdisablementbenefit_frequency')
          },
          {
            key: "partner_other_benefit_maintenancepayment_status",
            title: "Getting Maintenance Payment now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maintenancepayment_status')
          },
          {
            key: "partner_other_benefit_maintenancepayment_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_maintenancepayment_amount')
          },
          {
            key: "partner_other_benefit_maintenancepayment_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maintenancepayment_frequency')
          },
          {
            key: "partner_other_benefit_maternityallowance_status",
            title: "Getting Maternity Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maternityallowance_status')
          },
          {
            key: "partner_other_benefit_maternityallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_maternityallowance_amount')
          },
          {
            key: "partner_other_benefit_maternityallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_maternityallowance_frequency')
          },
          {
            key: "partner_other_benefit_severedisablementallowance_status",
            title: "Getting Severe Disablement Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_severedisablementallowance_status')
          },
          {
            key: "partner_other_benefit_severedisablementallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_severedisablementallowance_amount')
          },
          {
            key: "partner_other_benefit_severedisablementallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_severedisablementallowance_frequency')
          },
          {
            key: "partner_other_benefit_stateretirementpension_status",
            title: "Getting State Retirement Pension now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_stateretirementpension_status')
          },
          {
            key: "partner_other_benefit_stateretirementpension_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_stateretirementpension_amount')
          },
          {
            key: "partner_other_benefit_stateretirementpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_stateretirementpension_frequency')
          },
          {
            key: "partner_other_benefit_statutorymaternitypay_status",
            title: "Getting Statutory Maternity Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorymaternitypay_status')
          },
          {
            key: "partner_other_benefit_statutorymaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_statutorymaternitypay_amount')
          },
          {
            key: "partner_other_benefit_statutorymaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorymaternitypay_frequency')
          },
          {
            key: "partner_other_benefit_statutorypaternitypay_status",
            title: "Getting Statutory Paternity Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorypaternitypay_status')
          },
          {
            key: "partner_other_benefit_statutorypaternitypay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_statutorypaternitypay_amount')
          },
          {
            key: "partner_other_benefit_statutorypaternitypay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorypaternitypay_frequency')
          },
          {
            key: "partner_other_benefit_statutorysickpay_status",
            title: "Getting Statutory Sick Pay now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorysickpay_status')
          },
          {
            key: "partner_other_benefit_statutorysickpay_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_statutorysickpay_amount')
          },
          {
            key: "partner_other_benefit_statutorysickpay_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_statutorysickpay_frequency')
          },
          {
            key: "partner_other_benefit_trainingallowance_status",
            title: "Getting Training Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_trainingallowance_status')
          },
          {
            key: "partner_other_benefit_trainingallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_trainingallowance_amount')
          },
          {
            key: "partner_other_benefit_trainingallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_trainingallowance_frequency')
          },
          {
            key: "partner_other_benefit_wardisablementbenefit_status",
            title: "Getting War Disablement Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_wardisablementbenefit_status')
          },
          {
            key: "partner_other_benefit_wardisablementbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_wardisablementbenefit_amount')
          },
          {
            key: "partner_other_benefit_wardisablementbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_wardisablementbenefit_frequency')
          },
          {
            key: "partner_other_benefit_warpension_status",
            title: "Getting War Pension or War Widow's Pension now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_warpension_status')
          },
          {
            key: "partner_other_benefit_warpension_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_warpension_amount')
          },
          {
            key: "partner_other_benefit_warpension_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_warpension_frequency')
          },
          {
            key: "partner_other_benefit_widowbenefit_status",
            title: "Getting Widow or Widower's Benefit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowbenefit_status')
          },
          {
            key: "partner_other_benefit_widowbenefit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_widowbenefit_amount')
          },
          {
            key: "partner_other_benefit_widowbenefit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowbenefit_frequency')
          },
          {
            key: "partner_other_benefit_widowedparentsallowance_status",
            title: "Getting Widowed Parent's Allowance now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowedparentsallowance_status')
          },
          {
            key: "partner_other_benefit_widowedparentsallowance_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_widowedparentsallowance_amount')
          },
          {
            key: "partner_other_benefit_widowedparentsallowance_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_widowedparentsallowance_frequency')
          },
          {
            key: "partner_other_benefit_workingtaxcredit_status",
            title: "Getting Working Tax Credit now or are they waiting to hear?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_workingtaxcredit_status')
          },
          {
            key: "partner_other_benefit_workingtaxcredit_amount",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_workingtaxcredit_amount')
          },
          {
            key: "partner_other_benefit_workingtaxcredit_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_workingtaxcredit_frequency')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 34",
        title: "About other money coming in",
        page: 34,
        items: [
          {
            key: "applicant_other_benefit_pension",
            title: "Are you currently receiving any private pension or company pension?",
            type: "radios"
          },
          {
            key: "applicant_other_benefit_pension_number",
            title: "How many private or company pensions are you currently receiving?",
            condition: conditions.toString('applicant_other_benefit_pension_number')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_company1'),
            template: "<h3>Company 1</h3>"

          },
          {
            key: "applicant_other_benefit_pension_company1",
            title: "What is the name of the company are you receiving this pension from?",
            condition: conditions.toString('applicant_other_benefit_pension_company1')
          },
          {
            key: "applicant_other_benefit_pension_value1",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_pension_value1')
          },
          {
            key: "applicant_other_benefit_pension_frequency1",
            title: "How often?",
            type: "select",
            condition: conditions.toString('applicant_other_benefit_pension_frequency1')
          },
          {
            key: "applicant_other_benefit_pension_increase1",
            title: "When is this pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_other_benefit_pension_increase1')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_company2'),

            template: "<h3>Company 2</h3>"

          },
          {
            key: "applicant_other_benefit_pension_company2",
            title: "What is the name of the company are you receiving this pension from?",
            condition: conditions.toString('applicant_other_benefit_pension_company2')
          },
          {
            key: "applicant_other_benefit_pension_value2",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_pension_value2')
          },
          {
            key: "applicant_other_benefit_pension_frequency2",
            title: "How often?",
            type: "select",
            condition: conditions.toString('applicant_other_benefit_pension_frequency2')
          },
          {
            key: "applicant_other_benefit_pension_increase2",
            title: "When is this pension payment due to go up??",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_other_benefit_pension_increase2')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_company3'),

            template: "<h3>Company 3</h3>"

          },
          {
            key: "applicant_other_benefit_pension_company3",
            title: "What is the name of the company are you receiving this pension from?",
            condition: conditions.toString('applicant_other_benefit_pension_company3')
          },
          {
            key: "applicant_other_benefit_pension_value3",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_pension_value3')
          },
          {
            key: "applicant_other_benefit_pension_frequency3",
            title: "How often?",
            type: "select",
            condition: conditions.toString('applicant_other_benefit_pension_frequency3')
          },
          {
            key: "applicant_other_benefit_pension_increase3",
            title: "When is this pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_other_benefit_pension_increase3')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_company4'),

            template: "<h3>Company 4</h3>"

          },
          {
            key: "applicant_other_benefit_pension_company4",
            title: "What is the name of the company are you receiving this pension from?",
            condition: conditions.toString('applicant_other_benefit_pension_company4')
          },
          {
            key: "applicant_other_benefit_pension_value4",
            title: "How much?",
            condition: conditions.toString('applicant_other_benefit_pension_value4')
          },
          {
            key: "applicant_other_benefit_pension_frequency4",
            title: "How often?",
            type: "select",
            condition: conditions.toString('applicant_other_benefit_pension_frequency4')
          },
          {
            key: "applicant_other_benefit_pension_increase4",
            title: "When is this pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_other_benefit_pension_increase4')
          },
          {
            key: "applicant_other_benefit_pension_defer",
            title: "Have you put off drawing all or part of a State Retirement Pension, a personal pension or a company pension?",
            type: "radios",
          },
          {
            key: "applicant_other_benefit_pension_defer_lump",
            title: "Have you received a lump sum?",
            type: "radios",
          },
          {
            key: "applicant_other_benefit_pension_defer_lump_amount",
            title: "How much was the lump sum?",
            condition: conditions.toString('applicant_other_benefit_pension_defer_lump_amount')
          },
          {
            key: "partner_other_benefit_pension",
            title: "Is your partner currently receiving any private pension or company pension?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_pension')
          },
          {
            key: "partner_other_benefit_pension_number",
            title: "How many private or company pensions is your partner currently receiving?",
            condition: conditions.toString('partner_other_benefit_pension_number')
          },
          {
            type: "template",
            condition: conditions.toString('template_partner_company1'),

            template: "<h3>Company 1</h3>"

          },
          {
            key: "partner_other_benefit_pension_company1",
            title: "What is the name of the company they are receiving this pension from?",
            condition: conditions.toString('partner_other_benefit_pension_company1')
          },
          {
            key: "partner_other_benefit_pension_value1",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_pension_value1')
          },
          {
            key: "partner_other_benefit_pension_frequency1",
            title: "How often?",
            type: "select",
            condition: conditions.toString('partner_other_benefit_pension_frequency1')
          },
          {
            key: "partner_other_benefit_pension_increase1",
            title: "When is the pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you're not sure of the exact date please put an approximate date or leave blank.",
            condition: conditions.toString('partner_other_benefit_pension_increase1')
          },
          {
            type: "template",
            condition: conditions.toString('template_partner_company2'),

            template: "<h3>Company 2</h3>"

          },
          {
            key: "partner_other_benefit_pension_company2",
            title: "What is the name of the company they are receiving this pension from?",
            condition: conditions.toString('partner_other_benefit_pension_company2')
          },
          {
            key: "partner_other_benefit_pension_value2",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_pension_value2')
          },
          {
            key: "partner_other_benefit_pension_frequency2",
            title: "How often?",
            type: "select",
            condition: conditions.toString('partner_other_benefit_pension_frequency2')
          },
          {
            key: "partner_other_benefit_pension_increase2",
            title: "When is the pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_other_benefit_pension_increase2')
          },
          {
            type: "template",
            condition: conditions.toString('template_partner_company3'),

            template: "<h3>Company 3</h3>"

          },
          {
            key: "partner_other_benefit_pension_company3",
            title: "What is the name of the company they are receiving this pension from?",
            condition: conditions.toString('partner_other_benefit_pension_company3')
          },
          {
            key: "partner_other_benefit_pension_value3",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_pension_value3')
          },
          {
            key: "partner_other_benefit_pension_frequency3",
            title: "How often?",
            type: "select",
            condition: conditions.toString('partner_other_benefit_pension_frequency3')
          },
          {
            key: "partner_other_benefit_pension_increase3",
            title: "When is the pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_other_benefit_pension_increase3')
          },
          {
            type: "template",
            condition: conditions.toString('template_partner_company4'),

            template: "<h3>Company 4</h3>"

          },
          {
            key: "partner_other_benefit_pension_company4",
            title: "What is the name of the company they are receiving this pension from?",
            condition: conditions.toString('partner_other_benefit_pension_company4')
          },
          {
            key: "partner_other_benefit_pension_value4",
            title: "How much?",
            condition: conditions.toString('partner_other_benefit_pension_value4')
          },
          {
            key: "partner_other_benefit_pension_frequency4",
            title: "How often?",
            type: "select",
            condition: conditions.toString('partner_other_benefit_pension_frequency4')
          },
          {
            key: "partner_other_benefit_pension_increase4",
            title: "When is the pension payment due to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('partner_other_benefit_pension_increase4')
          },
          {
            key: "partner_other_benefit_pension_defer",
            title: "Have they put off drawing all or part of a State Retirement Pension, a personal pension or a company pension?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_pension_defer')
          },
          {
            key: "partner_other_benefit_pension_defer_lump",
            title: "Have they received a lump sum?",
            type: "radios",
            condition: conditions.toString('partner_other_benefit_pension_defer_lump')
          },
          {
            key: "partner_other_benefit_pension_defer_lump_amount",
            title: "How much was the lump sum?",
            condition: conditions.toString('partner_other_benefit_pension_defer_lump_amount')
          },
          {
            key: "applicant_other_income",
            title: "Do you receive any other unearned income that you have not already told us about?",
            type: "radios",
            condition: conditions.toString('applicant_other_income'),
            description: "<p>This includes:</p><ul><li>money from a trust fund</li><li>money from business interests</li><li>any rental income</li><li>a student grant or loan</li><li>any cash payments</li><li>a home income plan</li><li>any mortgage payment protection insurance</li><li>a local authority member's allowance</li></ul><p>You do not need to tell us about payments from the Independent Living Fund, the Eileen Trust, the MacFarlane Trust or the Skipton Fund.</p>"
          },
          {
            key: "couple_other_income",
            title: "Do you or your partner receive any other unearned income that you have not already told us about?",
            type: "radios",
            condition: conditions.toString('couple_other_income'),
            description: "<p>This includes:</p><ul><li>money from a trust fund</li><li>money from business interests</li><li>any rental income</li><li>a student grant or loan</li><li>any cash payments</li><li>a home income plan</li><li>any mortgage payment protection insurance</li><li>a local authority member's allowance</li></ul><p>You do not need to tell us about payments from the Independent Living Fund, the Eileen Trust, the MacFarlane Trust or the Skipton Fund.</p>"
          },
          {
            key: "applicant_other_income_no",
            title: "How many more other unearned incomes do you need to tell us about?",
            condition: conditions.toString('applicant_other_income_no'),
            description: "<p>This includes:</p><ul><li>money from a trust fund</li><li>money from business interests</li><li>any rental income</li><li>a student grant or loan</li><li>any cash payments</li><li>a home income plan</li><li>any mortgage payment protection insurance</li><li>a local authority member's allowance</li></ul><p>You do not need to tell us about payments from the Independent Living Fund, the Eileen Trust, the MacFarlane Trust or the Skipton Fund.</p>"
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_other_income1'),
            template: "<h3>Income 1</h3>"

          },
          {
            key: "other_income_1_use",
            type: "textarea",
            title: "What is the money for?",
            condition: conditions.toString('other_income_1_use')
          },
          {
            key: "other_income_1_pay_method",
            type: "selectWithInput",
            title: "How is this paid, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('other_income_1_pay_method')
          },
          {
            key: "other_income_1_recipient",
            title: "Who gets it?",
            type: "radios",
            condition: conditions.toString('other_income_1_recipient')
          },
          {
            key: "other_income_1_amount",
            title: "How much?",
            condition: conditions.toString('other_income_1_amount')
          },
          {
            key: "other_income_1_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('other_income_1_frequency')
          },
          {
            key: "other_income_1_start_date",
            title: "When did this income start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you're not sure of the exact date please put an approximate date.",
            condition: conditions.toString('other_income_1_start_date')
          },
          {
            key: "other_income_1_increase",
            title: "When is the income likely to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you're not sure of the exact date please put an approximate date. If you do not expect the income to go up please leave blank.",
            condition: conditions.toString('other_income_1_increase')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_other_income2'),

            template: "<h3>Income 2</h3>"

          },
          {
            key: "other_income_2_use",
            type: "textarea",
            title: "What is the money for?",
            condition: conditions.toString('other_income_2_use')
          },
          {
            key: "other_income_2_pay_method",
            type: "selectWithInput",
            title: "How is this paid, for example, in cash, by cheque or straight into a bank or building society account??",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('other_income_2_pay_method')
          },
          {
            key: "other_income_2_recipient",
            title: "Who gets it?",
            type: "radios",
            condition: conditions.toString('other_income_2_recipient')
          },
          {
            key: "other_income_2_amount",
            title: "How much?",
            condition: conditions.toString('other_income_2_amount')
          },
          {
            key: "other_income_2_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('other_income_2_frequency')
          },
          {
            key: "other_income_2_start_date",
            title: "When did this income start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('other_income_2_start_date')
          },
          {
            key: "other_income_2_increase",
            title: "When is the income likely to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('other_income_2_increase')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_other_income3'),

            template: "<h3>Income 3</h3>"

          },
          {
            key: "other_income_3_use",
            type: "textarea",
            title: "What is the money for?",
            condition: conditions.toString('other_income_3_use')
          },
          {
            key: "other_income_3_pay_method",
            type: "selectWithInput",
            title: "How is this paid, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('other_income_3_pay_method')
          },
          {
            key: "other_income_3_recipient",
            title: "Who gets it?",
            type: "radios",
            condition: conditions.toString('other_income_3_recipient')
          },
          {
            key: "other_income_3_amount",
            title: "How much?",
            condition: conditions.toString('other_income_3_amount')
          },
          {
            key: "other_income_3_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('other_income_3_frequency')
          },
          {
            key: "other_income_3_start_date",
            title: "When did this income start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('other_income_3_start_date')
          },
          {
            key: "other_income_3_increase",
            title: "When is the income likely to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('other_income_3_increase')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_other_income4'),

            template: "<h3>Income 4</h3>"

          },
          {
            key: "other_income_4_use",
            type: "textarea",
            title: "What is the money for?",
            condition: conditions.toString('other_income_4_use')
          },
          {
            key: "other_income_4_pay_method",
            type: "selectWithInput",
            title: "How is this paid, for example, in cash, by cheque or straight into a bank or building society account?",
            options: ["In cash", "By cheque",
              "Straight into an account", "Other"
            ],
            other: "Other",
            comment: "Please specify",
            condition: conditions.toString('other_income_4_pay_method')
          },
          {
            key: "other_income_4_recipient",
            title: "Who gets it?",
            type: "radios",
            condition: conditions.toString('other_income_4_recipient')
          },
          {
            key: "other_income_4_amount",
            title: "How much?",
            condition: conditions.toString('other_income_4_amount')
          },
          {
            key: "other_income_4_frequency",
            title: "How often?",
            type: "radios",
            condition: conditions.toString('other_income_4_frequency')
          },
          {
            key: "other_income_4_start_date",
            title: "When did this income start?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('other_income_4_start_date')
          },
          {
            key: "other_income_4_increase",
            title: "When is the income likely to go up?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('other_income_4_increase')
          },
          {
            key: "applicant_other_income_owed",
            title: "Does anyone owe money to you?",
            type: "radios",
            condition: conditions.toString('applicant_other_income_owed')
          },
          {
            key: "applicant_other_income_owed_reason",
            title: "What is the money owed for?",
            condition: conditions.toString('applicant_other_income_owed_reason')
          },
          {
            key: "applicant_other_income_owed_amount",
            title: "How much is owed",
            condition: conditions.toString('applicant_other_income_owed_amount')
          },
          {
            key: "applicant_other_income_expected",
            title: "Are you expecting to get any money in the next 12 months? For example, a redundancy payment or a payment instead of notice or holiday.",
            type: "radios",
            condition: conditions.toString('applicant_other_income_expected')
          },
          {
            key: "applicant_other_income_expected_reason",
            title: "What is the expected money for?",
            condition: conditions.toString('applicant_other_income_expected_reason')
          },
          {
            key: "applicant_other_income_expected_amount",
            title: "How much is expected?",
            condition: conditions.toString('applicant_other_income_expected_amount')
          },
          {
            key: "couple_other_income_owed",
            title: "Does anyone owe money to you or your partner?",
            type: "radios",
            condition: conditions.toString('couple_other_income_owed')
          },
          {
            key: "couple_other_income_owed_reason",
            title: "What is the money owed for?",
            condition: conditions.toString('couple_other_income_owed_reason')
          },
          {
            key: "couple_other_income_owed_recipient",
            title: "Who is it owed to?",
            type: "radios",
            condition: conditions.toString('couple_other_income_owed_recipient')
          },
          {
            key: "couple_other_income_owed_amount",
            title: "How much is owed",
            condition: conditions.toString('couple_other_income_owed_amount')
          },
          {
            key: "couple_other_income_expected",
            title: "Are you or your partner expecting to get any money in the next 12 months? For example, a redundancy payment or a payment instead of notice or holiday.",
            type: "radios",
            condition: conditions.toString('couple_other_income_expected')
          },
          {
            key: "couple_other_income_expected_reason",
            title: "What is the expected money for?",
            condition: conditions.toString('couple_other_income_expected_reason')
          },
          {
            key: "couple_other_income_expected_recipient",
            title: "Who is expecting it?",
            type: "radios",
            condition: conditions.toString('couple_other_income_expected_recipient')
          },
          {
            key: "couple_other_income_expected_amount",
            title: "How much is expected?",
            condition: conditions.toString('couple_other_income_expected_amount')
          },
          {
            key: "applicant_other_income_misc",
            title: "If you want to tell us about more money coming in, do so here",
            type: "textarea"
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 35",
        title: "Bank accounts",
        page: 35,
        items: [
          {
            key: "applicant_accounts",
            title: "How many bank, building society and post office accounts do you have in the UK or abroad?",
            condition: conditions.toString('applicant_accounts'),
            description: "<p>This includes cash, current accounts, savings accounts and card accounts with a bank, building society or the Post Office.</p><p>Tell us about them even if they are empty or overdrawn, whether they are currently in use or not.</p><p>Please enter 0 if you don't have any bank, building society or post office accounts.</p>"
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_account1'),
            template: "<h3>Account 1</h3>"
          },
          {
            key: "applicant_account1_bank",
            title: "Which bank or building society is your account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('applicant_account1_bank')
          },
          {
            key: "applicant_account1_number",
            title: "What's your account number?",
            condition: conditions.toString('applicant_account1_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "applicant_account1_balance",
            title: "What's the balance of this account?",
            description: "If the account is overdrawn put 0",
            condition: conditions.toString('applicant_account1_balance')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_account2'),
            template: "<h3>Account 2</h3>"
          },
          {
            key: "applicant_account2_bank",
            title: "Which bank or building society is your account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('applicant_account2_bank')
          },
          {
            key: "applicant_account2_number",
            title: "What's your account number?",
            condition: conditions.toString('applicant_account2_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "applicant_account2_balance",
            title: "What's the balance of this account?",
            description: "If the account is overdrawn put 0",
            condition: conditions.toString('applicant_account2_balance')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_account3'),
            template: "<h3>Account 3</h3>"
          },
          {
            key: "applicant_account3_bank",
            title: "Which bank or building society is your account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('applicant_account3_bank')
          },
          {
            key: "applicant_account3_number",
            title: "What's your account number?",
            condition: conditions.toString('applicant_account3_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "applicant_account3_balance",
            title: "What's the balance of this account?",
            description: "If the account is overdrawn put 0",
            condition: conditions.toString('applicant_account3_balance')
          },
          {
            type: "template",
            condition: conditions.toString('template_applicant_account4'),
            template: "<h3>Account 4</h3>"
          },
          {
            key: "applicant_account4_bank",
            title: "Which bank or building society is your account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('applicant_account4_bank')
          },
          {
            key: "applicant_account4_number",
            title: "What's your account number?",
            condition: conditions.toString('applicant_account4_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "applicant_account4_balance",
            title: "What's the balance of this account?",
            description: "If the account is overdrawn put 0",
            condition: conditions.toString('applicant_account4_balance')
          },
          {
            key: "couple_accounts",
            title: "How many bank, building society and post office accounts do you or your partner have in the UK or abroad?",
            type: "number",
            condition: conditions.toString('couple_accounts'),
            description: "<p>This includes cash, current accounts, savings accounts and card accounts with a bank, building society or the Post Office.</p><p>Tell us about them even if they are empty or overdrawn, whether they are currently in use or not.</p><p>Please enter 0 if you don't have any bank, building society or post office accounts.</p>"
          },
          {
            type: "template",
            condition: conditions.toString('template_couple_account1'),
            template: "<h3>Account 1</h3>"
          },
          {
            key: "couple_account1_bank",
            title: "Which bank or building society is the account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('couple_account1_bank')
          },
          {
            key: "couple_account1_name",
            title: "Whose name is the account in?",
            type: "radios",
            condition: conditions.toString('couple_account1_name')
          },
          {
            key: "couple_account1_number",
            title: "What's the account number?",
            condition: conditions.toString('couple_account1_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "couple_account1_balance",
            title: "What's the balance of this account?",
            type: "number",
            description: "If the account is overdrawn put 0",
            condition: conditions.toString('couple_account1_balance')
          },
          {
            type: "template",
            condition: conditions.toString('template_couple_account2'),
            template: "<h3>Account 2</h3>"
          },
          {
            key: "couple_account2_bank",
            title: "Which bank or building society is the account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('couple_account2_bank')
          },
          {
            key: "couple_account2_name",
            title: "Whose name is the account in?",
            type: "radios",
            condition: conditions.toString('couple_account2_name')
          },
          {
            key: "couple_account2_number",
            title: "What's the account number?",
            condition: conditions.toString('couple_account2_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "couple_account2_balance",
            title: "What's the balance of this account?",
            type: "number",
            condition: conditions.toString('couple_account2_balance')
          },
          {
            type: "template",
            condition: conditions.toString('template_couple_account3'),
            template: "<h3>Account 3</h3>"
          },
          {
            key: "couple_account3_bank",
            title: "Which bank or building society is the account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('couple_account3_bank')
          },
          {
            key: "couple_account3_name",
            title: "Whose name is the account in?",
            type: "radios",
            condition: conditions.toString('couple_account3_name')
          },
          {
            key: "couple_account3_number",
            title: "What's the account number?",
            condition: conditions.toString('couple_account3_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "couple_account3_balance",
            title: "What's the balance of this account?",
            type: "number",
            condition: conditions.toString('couple_account3_balance')
          },
          {
            type: "template",
            condition: conditions.toString('template_couple_account4'),
            template: "<h3>Account 4</h3>"
          },
          {
            key: "couple_account4_bank",
            title: "Which bank or building society is the account with?",
            description: "For Post Office accounts write POST OFFICE",
            condition: conditions.toString('couple_account4_bank')
          },
          {
            key: "couple_account4_name",
            title: "Whose name is the account in?",
            type: "radios",
            condition: conditions.toString('couple_account4_name')
          },
          {
            key: "couple_account4_number",
            title: "What's the account number?",
            condition: conditions.toString('couple_account4_number'),
            description: $translate.instant('forms.type.HB_CTR_FSM.account_number_description')
          },
          {
            key: "couple_account4_balance",
            title: "What's the balance of this account?",
            type: "number",
            condition: conditions.toString('couple_account4_balance')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 36",
        title: "Savings, Investments and Property",
        page: 36,
        items: [
          {
            key: "applicant_capital",
            title: "Have you or any children you are claiming for sold or given away any capital or assets in the last six months?",
            type: "radios",
            condition: conditions.toString('applicant_capital')
          },
          {
            key: "applicant_capitaldetails",
            title: "Please give us details about these",
            condition: conditions.toString('applicant_capitaldetails')
          },
          {
            key: "applicant_nsc",
            title: "Do you have any National Savings Certificates?",
            type: "radios",
            condition: conditions.toString('applicant_nsc')
          },
          {
            key: "applicant_stocks",
            title: "Do you have any stocks or shares?",
            type: "radios",
            condition: conditions.toString('applicant_stocks')
          },
          {
            key: "applicant_stocks_number",
            title: "How many different companies do you hold stocks or shares with?",
            type: "number",
            condition: conditions.toString('applicant_stocks_number')
          },
          {
            key: "applicant_stocks_company1",
            title: "Company 1 name",
            condition: conditions.toString('applicant_stocks_company1')
          },
          {
            key: "applicant_stocks_no1",
            title: "How many do you have with this company?",
            condition: conditions.toString('applicant_stocks_no1')
          },
          {
            key: "applicant_stocks_company2",
            title: "Company 2 name",
            condition: conditions.toString('applicant_stocks_company2')
          },
          {
            key: "applicant_stocks_no2",
            title: "How many do you have with this company?",
            condition: conditions.toString('applicant_stocks_no2')
          },
          {
            key: "applicant_stocks_company3",
            title: "Company 3 name",
            condition: conditions.toString('applicant_stocks_company3')
          },
          {
            key: "applicant_stocks_no3",
            title: "How many do you have with this company?",
            condition: conditions.toString('applicant_stocks_no3')
          },
          {
            key: "applicant_stocks_company4",
            title: "Company 4 name",
            condition: conditions.toString('applicant_stocks_company4')
          },
          {
            key: "applicant_stocks_no4",
            title: "How many do you have with this company?",
            condition: conditions.toString('applicant_stocks_no4')
          },
          {
            key: "couple_capital",
            title: "Have you, your partner or any children you are claiming for sold or given away any capital or assets in the last six months?",
            type: "radios",
            condition: conditions.toString('couple_capital')
          },
          {
            key: "couple_capitaldetails",
            title: "Please give us details about these",
            condition: conditions.toString('couple_capitaldetails')
          },
          {
            key: "couple_nsc",
            title: "Do you or your partner have any National Savings Certificates?",
            type: "radios",
            condition: conditions.toString('couple_nsc')
          },
          {
            key: "couple_stocks",
            title: "Do you or your partner have any stocks or shares?",
            type: "radios",
            condition: conditions.toString('couple_stocks')
          },
          {
            key: "couple_stocks_number",
            title: "How many different companies do you and your partner hold stocks or shares with?",
            condition: conditions.toString('couple_stocks_number')
          },
          {
            key: "couple_stocks_company1",
            title: "Company 1 name",
            condition: conditions.toString('couple_stocks_company1')
          },
          {
            key: "couple_stocks_no1",
            title: "How many do you or your partner have with this company?",
            condition: conditions.toString('couple_stocks_no1')
          },
          {
            key: "couple_stocks_company2",
            title: "Company 2 name",
            condition: conditions.toString('couple_stocks_company2')
          },
          {
            key: "couple_stocks_no2",
            title: "How many do you or your partner have with this company?",
            condition: conditions.toString('couple_stocks_no2')
          },
          {
            key: "couple_stocks_company3",
            title: "Company 3 name",
            condition: conditions.toString('couple_stocks_company3')
          },
          {
            key: "couple_stocks_no3",
            title: "How many do you or your partner have with this company?",
            condition: conditions.toString('couple_stocks_no3')
          },
          {
            key: "couple_stocks_company4",
            title: "Company 4 name",
            condition: conditions.toString('couple_stocks_company4')
          },
          {
            key: "couple_stocks_no4",
            title: "How many do you or your partner have with this company?",
            condition: conditions.toString('couple_stocks_no4')
          },
          {
            key: "applicant_savings",
            title: "Do you have any Savings Investment Accounts?",
            type: "radios",
            condition: conditions.toString('applicant_savings')
          },
          {
            key: "applicant_savingsno",
            title: "How many Savings Investment Accounts do you have?",
            condition: conditions.toString('applicant_savingsno')
          },
          {
            key: "applicant_savingsvalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_savingsvalue')
          },
          {
            key: "applicant_premiumbonds",
            title: "Do you have any Premium Bonds?",
            type: "radios",
            condition: conditions.toString('applicant_premiumbonds')
          },
          {
            key: "applicant_premiumbondsno",
            title: "How many Premium Bonds do you have?",
            condition: conditions.toString('applicant_premiumbondsno')
          },
          {
            key: "applicant_premiumbondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_premiumbondsvalue')
          },
          {
            key: "applicant_isa",
            title: "Do you have any Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs?",
            type: "radios",
            condition: conditions.toString('applicant_isa')
          },
          {
            key: "applicant_isano",
            title: "How many Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs do you have?",
            condition: conditions.toString('applicant_isano')
          },
          {
            key: "applicant_isavalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_isavalue')
          },
          {
            key: "applicant_incomebonds",
            title: "Do you have any Income bonds or Capital bonds?",
            type: "radios",
            condition: conditions.toString('applicant_incomebonds')
          },
          {
            key: "applicant_incomebondsno",
            title: "How many Income bonds or Capital bonds do you have?",
            condition: conditions.toString('applicant_incomebondsno')
          },
          {
            key: "applicant_incomebondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_incomebondsvalue')
          },
          {
            key: "applicant_trust",
            title: "Do you have any Money or Property held in trust?",
            type: "radios",
            condition: conditions.toString('applicant_trust')
          },
          {
            key: "applicant_trust_property",
            title: "How many properties held in trust do you have?",
            condition: conditions.toString('applicant_trust_property')
          },
          {
            key: "applicant_trust_property_value",
            title: "What's the total value of the property?",
            condition: conditions.toString('applicant_trust_property_value')
          },
          {
            key: "applicant_trust_money",
            title: "How much Money do you have in trust?",
            condition: conditions.toString('applicant_trust_money')
          },
          {
            key: "applicant_cash",
            title: "Do you have any savings held as cash?",
            type: "radios",
            condition: conditions.toString('applicant_cash')
          },
          {
            key: "applicant_cashamount",
            title: "What's the total amount?",
            condition: conditions.toString('applicant_cashamount')
          },
          {
            key: "applicant_savingsother",
            title: "Do you have any other savings or investments?",
            type: "radios",
            condition: conditions.toString('applicant_savingsother')
          },
          {
            key: "applicant_savingsotherdetails",
            title: "What are these savings or investments?",
            condition: conditions.toString('applicant_savingsotherdetails')
          },
          {
            key: "applicant_savingsothervalue",
            title: "What's the total value?",
            condition: conditions.toString('applicant_savingsothervalue')
          },
          {
            key: "applicant_savingsinclude",
            title: "Do any of your savings or investments include money from the sale of a house, or money from a charity?",
            type: "radios",
            condition: conditions.toString('applicant_savingsinclude')
          },
          {
            key: "applicant_property",
            title: "Do you own another property or land in the United Kingdom or abroad? If it is still on a mortgage or loan, answer 'Yes'",
            type: "select",
            condition: conditions.toString('applicant_property'),
            description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HB_CTR_FSM/templates/propertyDescription.html')
          },
          {
            key: "couple_savings",
            title: "Do you or your partner have any Savings Investment Accounts?",
            type: "radios",
            condition: conditions.toString('couple_savings')
          },
          {
            key: "couple_savingsno",
            title: "How many Savings Investment Accounts do you and your partner have?",
            condition: conditions.toString('couple_savingsno')
          },
          {
            key: "couple_savingsvalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_savingsvalue')
          },
          {
            key: "couple_premiumbonds",
            title: "Do you or your partner have any Premium Bonds?",
            type: "radios",
            condition: conditions.toString('couple_premiumbonds')
          },
          {
            key: "couple_premiumbondsno",
            title: "How many Premium Bonds do you and your partner have?",
            condition: conditions.toString('couple_premiumbondsno')
          },
          {
            key: "couple_premiumbondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_premiumbondsvalue')
          },
          {
            key: "couple_isa",
            title: "Do you or your partner have any Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs?",
            type: "radios",
            condition: conditions.toString('couple_isa')
          },
          {
            key: "couple_isano",
            title: "How many Unit trusts, OEICs, Stocks and Shares ISAs or Cash ISAs do you and your partner have?",
            condition: conditions.toString('couple_isano')
          },
          {
            key: "couple_isavalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_isavalue')
          },
          {
            key: "couple_incomebonds",
            title: "Do you or your partner have any Income bonds or Capital bonds?",
            type: "radios",
            condition: conditions.toString('couple_incomebonds')
          },
          {
            key: "couple_incomebondsno",
            title: "How many Income bonds or Capital bonds do you and your partner have?",
            condition: conditions.toString('couple_incomebondsno')
          },
          {
            key: "couple_incomebondsvalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_incomebondsvalue')
          },
          {
            key: "couple_trust",
            title: "Do you or your partner have any Money or Property held in trust?",
            type: "radios",
            condition: conditions.toString('couple_trust')
          },
          {
            key: "couple_trust_property",
            title: "How many properties held in trust do you and your partner have?",
            condition: conditions.toString('couple_trust_property')
          },
          {
            key: "couple_trust_property_trust",
            title: "What's the total value of the property?",
            condition: conditions.toString('couple_trust_property_trust')
          },
          {
            key: "couple_trust_money",
            title: "How much Money do you and your partner have in trust?",
            condition: conditions.toString('couple_trust_money')
          },
          {
            key: "couple_cash",
            title: "Do you or your partner have any savings held as cash?",
            type: "radios",
            condition: conditions.toString('couple_cash')
          },
          {
            key: "couple_cashamount",
            title: "What's the total amount?",
            condition: conditions.toString('couple_cashamount')
          },
          {
            key: "couple_savingsother",
            title: "Do you or your partner have any other savings or investments?",
            type: "radios",
            condition: conditions.toString('couple_savingsother')
          },
          {
            key: "couple_savingsotherdetails",
            title: "What are these savings or investments?",
            condition: conditions.toString('couple_savingsotherdetails')
          },
          {
            key: "couple_savingsothervalue",
            title: "What's the total value?",
            condition: conditions.toString('couple_savingsothervalue')
          },
          {
            key: "couple_savingsinclude",
            title: "Do any of your or your partner's savings/investments include money from the sale of a house or from a charity?",
            type: "radios",
            condition: conditions.toString('couple_savingsinclude')
          },
          {
            key: "couple_property",
            title: "Do you own another property or land in the United Kingdom or abroad? If the property or land is still on a mortgage or loan, answer 'Yes'",
            type: "select",
            description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/HB_CTR_FSM/templates/propertyDescription.html'),
            condition: conditions.toString('couple_property')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 37",
        title: "About where you live",
        page: 37,
        items: [
          {
            key: "household_applicant_ct_responsibility",
            title: "Who has to pay the Council Tax bill for your home?",
            type: "radios",
            condition: conditions.toString('household_applicant_ct_responsibility')
          },
          {
            key: "household_couple_ct_responsibility",
            title: "Who has to pay the Council Tax bill for your home?",
            type: "radios",
            condition: conditions.toString('household_couple_ct_responsibility')
          },
          {
            key: "household_ct_responsibility_someone_else",
            title: "If someone else pays all or part of the Council Tax, who is it?",
            type: "radios",
            condition: conditions.toString('household_ct_responsibility_someone_else')
          },
          {
            key: "household_ct_responsibility_who",
            title: "What is their name?",
            condition: conditions.toString('household_ct_responsibility_who')
          },
          {
            key: "household_ct_responsibility_who_residence",
            title: "Do they live in your home?",
            type: "radios",
            condition: conditions.toString('household_ct_responsibility_who_residence')
          },
          {
            key: "household_ct_responsibility_who_residence_addressline1",
            title: "What's their address? Address Line 1",
            condition: conditions.toString('household_ct_responsibility_who_residence_addressline1')
          },
          {
            key: "household_ct_responsibility_who_residence_addressline2",
            title: "Address Line 2",
            condition: conditions.toString('household_ct_responsibility_who_residence_addressline2')
          },
          {
            key: "household_ct_responsibility_who_residence_addressline3",
            title: "Address Line 3",
            condition: conditions.toString('household_ct_responsibility_who_residence_addressline3')
          },
          {
            key: "household_ct_responsibility_who_residence_postcode",
            title: "Postcode",
            condition: conditions.toString('household_ct_responsibility_who_residence_postcode')
          },
          {
            key: "household_owned_mortgaged",
            title: "Do you own your home or have a mortgage?",
            type: "radios"
          },
          {
            key: "household_shared_co_owned",
            title: "Is your home part of a shared or co-ownership scheme?",
            type: "radios",
            description: "Shared ownership means that you part own and part rent your home. Co-ownership means you rent your home and, subject to qualifying conditions, will receive a payment based on the value of your home when you move out."
          },
          {
            key: "household_shared_co_owned_which",
            title: "Tell us which:",
            condition: conditions.toString('household_shared_co_owned_which')
          },
          {
            key: "household_owned_mortgaged_joint_someone_else",
            title: "Do you jointly own your home, or share the mortgage, with someone else who is not your partner?",
            type: "radios",
            condition: conditions.toString('household_owned_mortgaged_joint_someone_else')
          },
          {
            key: "household_owned_mortgaged_joint_someone_else_who",
            title: "What is their name?",
            condition: conditions.toString('household_owned_mortgaged_joint_someone_else_who')
          },
          {
            key: "household_owned_mortgaged_joint_someone_else_residence",
            title: "Do they live in your home?",
            type: "radios",
            condition: conditions.toString('household_owned_mortgaged_joint_someone_else_residence')
          },
          {
            key: "household_someoneelse_residence_addressline1",
            title: "What's their address? Address Line 1",
            condition: conditions.toString('household_someoneelse_residence_addressline1')
          },
          {
            key: "household_someoneelse_residence_addressline2",
            title: "Address Line 2",
            condition: conditions.toString('household_someoneelse_residence_addressline2')
          },
          {
            key: "household_someoneelse_residence_addressline3",
            title: "Address Line 3",
            condition: conditions.toString('household_someoneelse_residence_addressline3')
          },
          {
            key: "household_someoneelse_residence_postcode",
            title: "Postcode",
            condition: conditions.toString('household_someoneelse_residence_postcode')
          },
          {
            key: "household_applicant_previously_owned",
            title: "Did you previously own this property?",
            type: "radios",
            condition: conditions.toString('household_applicant_previously_owned')
          },
          {
            key: "household_couple_previously_owned",
            title: "Did you or your partner previously own this property?",
            type: "radios",
            condition: conditions.toString('household_couple_previously_owned')
          },
          {
            key: "household_main_home_elsewhere",
            title: "Do you have a main home somewhere else?",
            type: "radios",
          },
          {
            type: 'help',
            helpvalue: '<div class="alert alert-info">If you have a main home somewhere else, please enter the address below</div>',
            condition: conditions.toString('household_couple_previously_owned_addressline1')
          },
          {
            key: "household_couple_previously_owned_addressline1",
            title: "Address Line 1",
            condition: conditions.toString('household_couple_previously_owned_addressline1')
          },
          {
            key: "household_couple_previously_owned_addressline2",
            title: "Address Line 2",
            condition: conditions.toString('household_couple_previously_owned_addressline2')
          },
          {
            key: "household_couple_previously_owned_addressline3",
            title: "Address Line 3",
            condition: conditions.toString('household_couple_previously_owned_addressline3')
          },
          {
            key: "household_couple_previously_owned_postcode",
            title: "Postcode",
            condition: conditions.toString('household_couple_previously_owned_postcode')
          },
          {
            key: "household_business_use",
            title: "Do you use your home for business?",
            type: "radios"
          },
          {
            key: "household_applicant_charged_rent",
            title: "Are you charged rent for your home?",
            type: "radios",
            condition: conditions.toString('household_applicant_charged_rent'),
            description: 'If you would pay rent but you already get Housing Benefit, you should still choose "Yes".'
          },
          {
            key: "household_couple_charged_rent",
            title: "Are you or your partner charged rent for your home?",
            type: "radios",
            condition: conditions.toString('household_couple_charged_rent'),
            description: 'If you would pay rent but you already get Housing Benefit, you should still choose "Yes".'
          },
          {
            key: "household_rent_charger",
            title: "Who charges the rent for your home?",
            type: "radios",
            condition: conditions.toString('household_rent_charger')
          },
          {
            key: "household_perm_temp_accommodation",
            title: "Do you live in permanent or in temporary accommodation?",
            type: "radios",
            condition: conditions.toString('household_perm_temp_accommodation')
          },
          {
            key: "household_landlord_residence",
            title: "Does your landlord live in the property with you?",
            type: "radios",
            condition: conditions.toString('household_landlord_residence')
          },
          {
            key: "household_building_type",
            title: "What sort of building do you live in?",
            type: "radios",
            condition: conditions.toString('household_building_type')
          },
          {
            key: "household_building_type_houseboat",
            title: "If houseboat please specify length in feet",
            condition: conditions.toString('household_building_type_houseboat')
          },
          {
            key: "household_building_type_other",
            title: "If other please specify",
            condition: conditions.toString('household_building_type_other')
          },
          {
            key: "household_building_facilities",
            title: "Does your home have any of these?",
            type: "checkboxes",
            condition: conditions.toString('household_building_facilities')
          },
          {
            key: "household_building_decorating_responsibilities_applicant",
            title: "Who is responsible for decorating your home?",
            type: "radios",
            condition: conditions.toString('household_building_decorating_responsibilities_applicant')
          },
          {
            key: "household_building_decorating_responsibilities_couple",
            title: "Who is responsible for decorating your home?",
            type: "radios",
            condition: conditions.toString('household_building_decorating_responsibilities_couple')
          },
          {
            key: "household_building_furnishings",
            title: "Is there furniture provided by your landlord?",
            type: "radios",
            condition: conditions.toString('household_building_furnishings')
          },
          {
            key: "household_building_floors",
            title: "How many floors are there in the whole building?",
            condition: conditions.toString('household_building_floors')
          },
          {
            key: "household_building_floor_occupancy",
            title: "Which floors do you live on?",
            type: "checkboxes",
            condition: conditions.toString('household_building_floor_occupancy')
          },
          {
            key: "household_building_floor_occupancy_other",
            title: "If other please specify which floor number",
            condition: conditions.toString('household_building_floor_occupancy_other')
          },
          {
            key: "household_building_sole_use",
            title: "Do you and your household have sole use of the building?",
            type: "radios",
            condition: conditions.toString('household_building_sole_use')
          },
          {
            key: "household_building_location_use",
            title: "As you look at the building from the street, where in the building do you live?",
            type: "radios",
            condition: conditions.toString('household_building_location_use')
          },
          {
            key: "household_livingrooms_wholebuilding",
            title: "How many living rooms are there in the whole building?",
            condition: conditions.toString('household_livingrooms_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_livingrooms_soleyapplicantshousehold",
            title: "How many living rooms are just for you and your household?",
            condition: conditions.toString('household_livingrooms_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_livingrooms_shared",
            title: "How many living rooms do you share with other people?",
            condition: conditions.toString('household_livingrooms_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_diningrooms_wholebuilding",
            title: "How many dining rooms are there in the whole building?",
            condition: conditions.toString('household_diningrooms_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_diningrooms_soleyapplicantshousehold",
            title: "How many dining rooms are just for you and your household?",
            condition: conditions.toString('household_diningrooms_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_diningrooms_shared",
            title: "How many dining rooms do you share with other people?",
            condition: conditions.toString('household_diningrooms_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_bedsittingrooms_wholebuilding",
            title: "How many bedsitting rooms are there in the whole building?",
            condition: conditions.toString('household_bedsittingrooms_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_bedsittingrooms_soleyapplicantshousehold",
            title: "How many bedsitting rooms are just for you and your household?",
            condition: conditions.toString('household_bedsittingrooms_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_bedsittingrooms_shared",
            title: "How many bedsitting rooms do you share with other people?",
            condition: conditions.toString('household_bedsittingrooms_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_bedrooms_wholebuilding",
            title: "How many bedrooms are there in the whole building?",
            condition: conditions.toString('household_bedrooms_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_bedrooms_soleyapplicantshousehold",
            title: "How many bedrooms are just for you and your household?",
            condition: conditions.toString('household_bedrooms_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_bedrooms_shared",
            title: "How many bedrooms do you share with other people?",
            condition: conditions.toString('household_bedrooms_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_bathrooms_wholebuilding",
            title: "How many bathrooms or shower rooms are there in the whole building?",
            condition: conditions.toString('household_bathrooms_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_bathrooms_soleyapplicantshousehold",
            title: "How many bathrooms or shower rooms are just for you and your household?",
            condition: conditions.toString('household_bathrooms_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_bathrooms_shared",
            title: "How many bathrooms or shower rooms do you share with other people?",
            condition: conditions.toString('household_bathrooms_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_WC_wholebuilding",
            title: "How many separate toilets are there in the whole building?",
            condition: conditions.toString('household_WC_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_WC_soleyapplicantshousehold",
            title: "How many separate toilets are just for you and your household?",
            condition: conditions.toString('household_WC_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_WC_shared",
            title: "How many separate toilets do you share with other people?",
            condition: conditions.toString('household_WC_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_kitchens_wholebuilding",
            title: "How many kitchens are there in the whole building?",
            condition: conditions.toString('household_kitchens_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_kitchens_soleyapplicantshousehold",
            title: "How many kitchens are just for you and your household?",
            condition: conditions.toString('household_kitchens_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_kitchens_shared",
            title: "How many kitchens do you share with other people?",
            condition: conditions.toString('household_kitchens_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_otherrooms_wholebuilding",
            title: "How many other rooms are there in the whole building?",
            condition: conditions.toString('household_otherrooms_wholebuilding'),
            description: "Please complete. If there are no rooms of this particular type, you should enter 0."
          },
          {
            key: "household_otherrooms_soleyapplicantshousehold",
            title: "How many other rooms are just for you and your household?",
            condition: conditions.toString('household_otherrooms_soleyapplicantshousehold'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_otherrooms_shared",
            title: "How many other rooms do you share with other people?",
            condition: conditions.toString('household_otherrooms_shared'),
            description: "Please complete. If there are no rooms of this particular type, you can leave blank."
          },
          {
            key: "household_otherrooms_described",
            title: "Please describe what these 'other rooms' are:",
            condition: conditions.toString('household_otherrooms_described')
          },
          {
            key: "household_bedroom_nonliveincarer",
            title: "Are you entitled to an extra room, as described in Social Sector Size Criteria?",
            type: "radios",
            condition: conditions.toString('household_bedroom_nonliveincarer')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 38",
        title: "Your Landlord",
        page: 38,
        items: [
          {
            type: "template",
            condition: conditions.toString('template_applicant_landlord_address_lookup'),
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/applicantLandlordAddressLookup.html"
          },
          {
            key: "applicant_landlordsname",
            title: "Full name",
            description: "By landlord we mean the person or organisation who <b>owns the property</b> you live in.",
            condition: conditions.toString('applicant_landlordsname')
          },
          {
            key: "applicant_landlordsaddressline1",
            title: "Address Line 1",
            condition: conditions.toString('applicant_landlordsaddressline1')
          },
          {
            key: "applicant_landlordsaddressline2",
            title: "Address Line 2",
            condition: conditions.toString('applicant_landlordsaddressline2')
          },
          {
            key: "applicant_landlordsaddressline3",
            title: "Address Line 3",
            condition: conditions.toString('applicant_landlordsaddressline3')
          },
          {
            key: "applicant_landlordspostcode",
            title: "Postcode",
            condition: conditions.toString('applicant_landlordspostcode')
          },
          {
            key: "applicant_landlordsphonenumber",
            title: "Telephone number",
            condition: conditions.toString('applicant_landlordsphonenumber')
          },
          {
            key: "applicant_agent",
            title: "Does your landlord have an agent?",
            type: "radios",
            description: "By agent we mean the person or organisation who manages the property and who you actually pay the rent to.",
            condition: conditions.toString('applicant_agent')
          },
          {
            key: "applicant_agentname",
            title: "Tell us their full name",
            condition: conditions.toString('applicant_agentname')
          },
          {
            key: "applicant_agentaddress1",
            title: "What is the agent's address? Address Line 1",
            condition: conditions.toString('applicant_agentaddress1')
          },
          {
            key: "applicant_agentaddress2",
            title: "Address Line 2",
            condition: conditions.toString('applicant_agentaddress2')
          },
          {
            key: "applicant_agentaddress3",
            title: "Address Line 3",
            condition: conditions.toString('applicant_agentaddress3')
          },
          {
            key: "applicant_agentpostcode",
            title: "Postcode",
            condition: conditions.toString('applicant_agentpostcode')
          },
          {
            key: "applicant_agentphonenumber",
            title: "What is the agent's telephone number",
            condition: conditions.toString('applicant_agentphonenumber')
          },
          {
            key: "applicant_landlord_related",
            title: "Are you or any of your children related to your landlord / agent or your landlord's / agent's partner in any way?",
            type: "radios",
            condition: conditions.toString('applicant_landlord_related'),
            description: "Related includes related through marriage or civil partnership, even if it has ended. For example, ex-wife, ex-husband, ex-civil partner, aunt, brother, daughter, father, grandson, son-in-law, step daughter or partner of any such relative."
          },
          {
            key: "couple_landlord_related",
            title: "Are you, your partner or any of your children related to your landlord / agent or your landlord's / agent's partner in any way?",
            type: "radios",
            condition: conditions.toString('couple_landlord_related'),
            description: "Related includes related through marriage or civil partnership, even if it has ended. For example, ex-wife, ex-husband, ex-civil partner, aunt, brother, daughter, father, grandson, son-in-law, step daughter or partner of any such relative."
          },
          {
            key: "applicant_landlord_relatedname",
            title: "Name of the person who is related to the landlord or agent",
            condition: conditions.toString('applicant_landlord_relatedname')
          },
          {
            key: "applicant_landlord_relationship",
            title: "What is their relationship to the landlord or agent?",
            condition: conditions.toString('applicant_landlord_relationship')
          },
          {
            key: "applicant_landlord_relatedto",
            title: "Who are they related to?",
            type: "radios",
            condition: conditions.toString('applicant_landlord_relatedto')
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 39",
        page: 39,
        title: "About Rent",
        items: [
          {
            key: "applicant_rent_startdate",
            title: "When did you start renting your home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_rent_startdate')
          },
          {
            key: "couple_rent_startdate",
            title: "When did you or your partner start renting your home?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('couple_rent_startdate')
          },
          {
            key: "applicant_rentcharge_startdate",
            title: "On what date did you start being charged rent?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_rentcharge_startdate')
          },
          {
            key: "applicant_rent_tenancytype",
            type: "selectWithInput",
            title: "What sort of tenancy do you have?",
            options: ["Shorthold Assured", "Assured", "Secured",
              "Tied Rent", "Other"
            ],
            radios: true,
            other: "Other",
            comment: "If other please tell us what sort it is",
            condition: conditions.toString('applicant_rent_tenancytype')
          },
          {
            key: "applicant_rent_tenancyenddate",
            title: "If your tenancy agreement has an end date, what is it?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            condition: conditions.toString('applicant_rent_tenancyenddate')
          },
          {
            key: "applicant_rent_howmuch",
            title: "How much rent are you charged?",
            condition: conditions.toString('applicant_rent_howmuch')
          },
          {
            key: "couple_rent_howmuch",
            title: "How much rent are you and your partner charged?",
            condition: conditions.toString('couple_rent_howmuch')
          },
          {
            key: "applicant_rent_frequency",
            title: "How often are you charged rent?",
            type: "radios",
            condition: conditions.toString('applicant_rent_frequency')
          },
          {
            key: "applicant_rent_increase_due",
            title: "When is your next rent increase due?",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            description: "If you do not know the exact date please enter an approximate date or leave blank",
            condition: conditions.toString('applicant_rent_increase_due')
          },
          {
            key: "applicant_rent_fair_rent_registered",
            title: "Has your rent been registered as a fair rent by a rent officer?",
            type: "radios",
            condition: conditions.toString('applicant_rent_fair_rent_registered')
          },
          {
            key: "applicant_rent_weeks_not_due",
            title: "Do you have any weeks where you don't have to pay rent?",
            type: "radios",
            condition: conditions.toString('applicant_rent_weeks_not_due')
          },
          {
            key: "applicant_rent_weeks_not_due_number",
            title: "How many weeks in a year?",
            condition: conditions.toString('applicant_rent_weeks_not_due_number')
          },
          {
            key: "applicant_rent_arrears",
            title: "Are you behind with your rent?",
            type: "radios",
            condition: conditions.toString('applicant_rent_arrears')

          },
          {
            key: "applicant_rent_arrears_weeks",
            title: "By how many weeks?",
            condition: conditions.toString('applicant_rent_arrears_weeks')
          },
          {
            key: "applicant_rent_affordable_initally",
            title: "Were you able to afford the rent when you moved in?",
            type: "radios",
            condition: conditions.toString('applicant_rent_affordable_initally')
          },
          {
            key: "applicant_rent_responsibility_shared",
            title: "Does anyone else share the rent with you?",
            type: "radios",
            condition: conditions.toString('applicant_rent_responsibility_shared'),
            description: "Include joint tenants"
          },
          {
            key: "couple_rent_responsibility_shared",
            title: "Does anyone else share the rent with you and your partner?",
            type: "radios",
            condition: conditions.toString('couple_rent_responsibility_shared'),
            description: "Include joint tenants"
          },
          {
            key: "applicant_rent_responsibility_shared_names",
            title: "Tell us their names and their relationship to you",
            condition: conditions.toString('applicant_rent_responsibility_shared_names')
          },
          {
            key: "applicant_rent_shared_amount",
            title: "How much of the rent do they pay?",
            condition: conditions.toString('applicant_rent_shared_amount')
          },
          {
            key: "applicant_rent_shared_frequency",
            title: "How often do they pay?",
            type: "radios",
            condition: conditions.toString('applicant_rent_shared_frequency')
          },
          {
            key: "applicant_water_bill_seperate",
            title: "Do you receive a separate bill for water charges?",
            type: "radios",
            condition: conditions.toString('applicant_water_bill_seperate')
          },
          {
            key: "applicant_rent_heating_charges",
            title: "Does your rent include charges for heating services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_heating_charges')
          },
          {
            key: "applicant_rent_heating_charges_price",
            title: "How much money is included in your rent for heating services?",
            condition: conditions.toString('applicant_rent_heating_charges_price')
          },
          {
            key: "applicant_rent_hotwater_charges",
            title: "Does your rent include charges for hot water services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_hotwater_charges')
          },
          {
            key: "applicant_rent_hotwater_charges_price",
            title: "How much money is included in your rent for hot water services?",
            condition: conditions.toString('applicant_rent_hotwater_charges_price')
          },
          {
            key: "applicant_rent_cookingfuel_charges",
            title: "Does your rent include charges for fuel for cooking services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_cookingfuel_charges')
          },
          {
            key: "applicant_rent_cookingfuel_charges_price",
            title: "How much money is included in your rent for fuel for cooking services?",
            condition: conditions.toString('applicant_rent_cookingfuel_charges_price')
          },
          {
            key: "applicant_rent_cleaningsharedareas_charges",
            title: "Does your rent include charges for cleaning of shared areas services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_cleaningsharedareas_charges')

          },
          {
            key: "applicant_rent_cleaningsharedareas_charges_price",
            title: "How much money is included in your rent for cleaning of shared areas services?",
            condition: conditions.toString('applicant_rent_cleaningsharedareas_charges_price')
          },
          {
            key: "applicant_rent_personalcaresupport_charges",
            title: "Does your rent include charges for personal care and support services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_personalcaresupport_charges')
          },
          {
            key: "applicant_rent_personalcaresupport_charges_price",
            title: "How much money is included in your rent for personal care and support services?",
            condition: conditions.toString('applicant_rent_personalcaresupport_charges_price')
          },
          {
            key: "applicant_rent_warden_charges",
            title: "Does your rent include charges for warden services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_warden_charges')
          },
          {
            key: "applicant_rent_warden_charges_price",
            title: "How much money is included in your rent for warden services?",
            condition: conditions.toString('applicant_rent_warden_charges_price')
          },
          {
            key: "applicant_rent_meals_charges",
            title: "Does your rent include charges for meal services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_meals_charges')
          },
          {
            key: "applicant_rent_meals_charges_price",
            title: "How much money is included in your rent for meal services?",
            condition: conditions.toString('applicant_rent_meals_charges_price')
          },
          {
            key: "applicant_rent_meals_included",
            type: "checkboxes",
            title: "Which meals are included?",
            condition: conditions.toString('applicant_rent_meals_included')
          },
          {
            key: "applicant_rent_garageparking_charges",
            title: "Does your rent include charges for garage or parking space services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_garageparking_charges')
          },
          {
            key: "applicant_rent_garageparking_charges_price",
            title: "How much money is included in your rent for garage or parking space services?",
            condition: conditions.toString('applicant_rent_garageparking_charges_price')
          },
          {
            key: "applicant_rent_garageparking_tenancyagreement",
            title: "Do you have to rent the garage as part of your tenancy agreement? ",
            type: "radios",
            condition: conditions.toString('applicant_rent_garageparking_tenancyagreement')
          },
          {
            key: "applicant_rent_lighting_charges",
            title: "Does your rent include charges for lighting services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_lighting_charges')
          },
          {
            key: "applicant_rent_lighting_charges_price",
            title: "How much money is included in your rent for lighting services?",
            condition: conditions.toString('applicant_rent_lighting_charges_price')
          },
          {
            key: "applicant_rent_electricty_charges",
            title: "Does your rent include charges for electricity services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_electricty_charges')
          },
          {
            key: "applicant_rent_electricty_charges_price",
            title: "How much money is included in your rent for electricity services?",
            condition: conditions.toString('applicant_rent_electricty_charges_price')
          },
          {
            key: "applicant_rent_laundry_charges",
            title: "Does your rent include charges for laundry services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_laundry_charges')
          },
          {
            key: "applicant_rent_laundry_charges_price",
            title: "How much money is included in your rent for laundry services?",
            condition: conditions.toString('applicant_rent_laundry_charges_price')
          },
          {
            key: "applicant_rent_gardeningsharedareas_charges",
            title: "Does your rent include charges for gardening of shared areas services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_gardeningsharedareas_charges')
          },
          {
            key: "applicant_rent_gardeningsharedareas_charges_price",
            title: "How much money is included in your rent for gardening of shared areas services?",
            condition: conditions.toString('applicant_rent_gardeningsharedareas_charges_price')
          },
          {
            key: "applicant_rent_emergencyalarm_charges",
            title: "Does your rent include charges for emergency alarm services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_emergencyalarm_charges')
          },
          {
            key: "applicant_rent_emergencyalarm_charges_price",
            title: "How much money is included in your rent for emergency alarm services?",
            condition: conditions.toString('applicant_rent_emergencyalarm_charges_price')
          },
          {
            key: "applicant_rent_otherservices_charges",
            title: "Does your rent include charges for any other services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_otherservices_charges')
          },
          {
            key: "applicant_rent_otherservices_charges_price",
            title: "How much money is included in your rent for other services?",
            condition: conditions.toString('applicant_rent_otherservices_charges_price')
          },
          {
            key: "applicant_rent_otherservices_description",
            title: "What are these services? ",
            condition: conditions.toString('applicant_rent_otherservices_description')
          },
          {
            key: "applicant_rent_seperate_charges",
            title: "Do you pay any services charges separate from your rent?",
            type: "radios",
            condition: conditions.toString('applicant_rent_seperate_charges'),
            description: "For example, for cleaning and lighting in shared areas, a warden, meals or lift maintenance."
          },
          {
            key: "applicant_rent_seperate_charges_price",
            title: "How much do you pay for these services?",
            condition: conditions.toString('applicant_rent_seperate_charges_price')
          },
          {
            key: "applicant_rent_seperate_charges_frequency",
            title: "How often do you pay for these services?",
            type: "radios",
            condition: conditions.toString('applicant_rent_seperate_charges_frequency')
          },
          {
            key: "applicant_rent_seperate_charges_description",
            title: "What are these services? ",
            condition: conditions.toString('applicant_rent_seperate_charges_description')
          },
          {
            key: "applicant_rent_securitysupport",
            title: "Does your landlord provide or arrange for someone else to provide you with an alarm system, general counselling, support or supervision?",
            type: "radios",
            condition: conditions.toString('applicant_rent_securitysupport')
          },
          {
            key: "applicant_rent_securitysupport_responsibility",
            title: "Who pays for it?",
            condition: conditions.toString('applicant_rent_securitysupport_responsibility')
          },
          {
            key: "applicant_rent_securitysupport_price",
            title: "How much?",
            condition: conditions.toString('applicant_rent_securitysupport_price')
          },
          {
            key: "applicant_rent_securitysupport_frequency",
            title: "How often",
            type: "radios",
            condition: conditions.toString('applicant_rent_securitysupport_frequency')
          }]

      },
      {
        type: "fieldset",
        condition: "model.page === 40",
        page: 40,
        title: "About how you will be paid and the choices you have",

        items: [
          {
            condition: conditions.toString('rent_charge'),
            type: "help",
            helpvalue: htmlService.interpolateTemplate("modules/forms/scripts/services/forms/HB_CTR_FSM/templates/paymentOptions.html")
          }
        ]
      },
      {
        type: "fieldset",
        condition: "model.page === 41",
        page: 41,
        title: "About the account you want to use",
        items: [
          {
            key: "applicant_payment_option_private",
            title: "Who would you like your benefit to be paid to?",
            type: "radios",
            condition: conditions.toString('applicant_payment_option_private'),
            description: "<p>It is possible to have your benefit paid directly to your landlord, but only if</p><ul><li>you currently owe your landlord 8 weeks or more in rent arrears then we are required to pay your benefit directly to your landlord.</li><li>you have difficulty managing your finances, benefit can then be paid directly to your landlord</li></ul>"
          },
          {
            key: "applicant_payment_option_ha",
            title: "Who would you like your benefit to be paid to?",
            type: "radios",
            condition: conditions.toString('applicant_payment_option_ha')
          },
          {
            key: "applicant_paymenttype",
            title: "How would you like your benefit to be paid?",
            type: "radios",
            condition: conditions.toString('applicant_paymenttype'),
            description: "<p>If you don't have a bank account available, we can pay your benefit by cheque until one is opened.</p><p>If you would like your benefit to be paid into a Bank or Building society account, please contact us once you have set up an account.</p>"
          },
          {
            key: "applicant_payment_accountname",
            title: "Whose name or names is the account in?",
            condition: conditions.toString('applicant_payment_accountname'),
            description: "Please write the names as they appear on the cheque book, passbook or statement."
          },
          {
            key: "applicant_payment_accountnumber",
            title: "Account number",
            condition: conditions.toString('applicant_payment_accountnumber'),
            description: "If it is a Lloyds TSB account number that is only 7 digits long please put a 0 at the beginning."
          },
          {
            key: "applicant_payment_accountsortcode",
            title: "Sort Code",
            condition: conditions.toString('applicant_payment_accountsortcode'),
            description: "Enter the sort code without spaces, for example, 123456"
          },
          {
            key: "applicant_payment_bank",
            title: "Name of bank, building society or other account provider",
            condition: conditions.toString('applicant_payment_bank')
          },
          {
            key: "applicant_payment_bankaddressline1",
            title: "Address of bank, building society or other account provider Address Line 1",
            condition: conditions.toString('applicant_payment_bankaddressline1')
          },
          {
            key: "applicant_payment_bankaddressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_payment_bankaddressline2')
          },
          {
            key: "applicant_payment_bankaddressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_payment_bankaddressline3')
          },
          {
            key: "applicant_payment_bankpostcode",
            title: "Postcode",
            condition: conditions.toString('applicant_payment_bankpostcode')
          },
          {
            key: "applicant_payment_rollnumber",
            title: "If it is a building society account, what is the building society roll or reference number?",
            condition: conditions.toString('applicant_payment_rollnumber'),
            description: "If your building society uses a roll or reference number, you will find this on the passbook."
          },
          {
            key: "applicant_payment_difficulties_reason",
            title: "Reason for Kirklees Council to make payment directly to your landlord?",
            description: htmlService.interpolateTemplate("modules/forms/scripts/services/forms/HB_CTR_FSM/templates/directLandlordPayment.html"),
            condition: conditions.toString('applicant_payment_difficulties_reason')
          },
          {
            key: "applicant_paymenttype_inarrears_privatedifficulties",
            title: "Do you know your landlord's account details?",
            type: "radios",
            condition: conditions.toString('applicant_paymenttype_inarrears_privatedifficulties')
          },
          {
            key: "applicant_paymenttype_inarrears",
            title: "Do you know your landlord's account details?",
            type: "radios",
            condition: conditions.toString('applicant_paymenttype_inarrears')
          },
          {
            key: "applicant_payment_inarrears_accountname",
            title: "What name is your landlord's bank account in?",
            condition: conditions.toString('applicant_payment_inarrears_accountname'),
            description: "Please write the names as they appear on the cheque book, passbook or statement."
          },
          {
            key: "applicant_payment_inarrears_accountnumber",
            title: "Account number",
            condition: conditions.toString('applicant_payment_inarrears_accountnumber'),
            description: "If it is a Lloyds TSB account number that is only 7 digits long please put a 0 at the beginning."
          },
          {
            key: "applicant_payment_inarrears_accountsortcode",
            title: "Sort Code",
            condition: conditions.toString('applicant_payment_inarrears_accountsortcode'),
            description: "Enter the sort code without spaces, for example, 123456"
          },
          {
            key: "applicant_payment_inarrears_bank",
            title: "Name of bank, building society or other account provider",
            condition: conditions.toString('applicant_payment_inarrears_bank')
          },
          {
            key: "applicant_payment_inarrears_bankaddressline1",
            title: "Address line 1 of bank, building society or other account provider",
            condition: conditions.toString('applicant_payment_inarrears_bankaddressline1')
          },
          {
            key: "applicant_payment_inarrears_bankaddressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_payment_inarrears_bankaddressline2')
          },
          {
            key: "applicant_payment_inarrears_bankaddressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_payment_inarrears_bankaddressline3')
          },
          {
            key: "applicant_payment_inarrears_postcode",
            title: "Postcode",
            condition: conditions.toString('applicant_payment_inarrears_postcode')
          },
          {
            key: "applicant_payment_inarrears_rollnumber",
            title: "If it is a building society account, what is the building society roll or reference number?",
            condition: conditions.toString('applicant_payment_inarrears_rollnumber'),
            description: "If your building society uses a roll or reference number, you will find this on the passbook."
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 42",
        title: "Anything else that you need to tell us?",
        page: 43,
        items: [
          {
            key: "applicant_anythingelse",
            type: "textarea",
            title: "Please use this space to tell us anything else you think we should know about."
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 43",
        page: 44,
        title: "Backdating",
        items: [
          {
            type: "help",
            helpvalue: "We can usually award benefit from the Monday after the day we receive your claim. Sometimes we can pay benefit from an earlier date if you have a good reason for not claiming earlier. lf you want us to consider paying your benefit from an earlier date, tell us when you want benefit from and why you did not claim earlier."
          },
          {
            key: "applicant_backdating",
            title: "Do you wish to backdate your claim?",
            type: "radios",
            description: "<strong>Please note:</strong> Legislation only allows Housing Benefit claims to be backdated for 1 month and Council Tax Reduction claims to be backdated for 6 months for Working Age claimants. Pension Age claims will normally automatically be backdated for 3 months, this depends on your circumstances.",
          },
          {
            key: "applicant_backdating_date",
            type: "kerpdate",
            dateFormat: "YYYY-MM-DD",
            title: "Date you want to claim benefit from",
            condition: conditions.toString('applicant_backdating_date')
          },
          {
            key: "applicant_backdating_changes",
            title: "If there has been any change in your circumstances between that date and now, what has changed?",
            condition: conditions.toString('applicant_backdating_changes')
          },
          {
            key: "applicant_backdating_claimearlier",
            title: "Why did you not claim earlier?",
            condition: conditions.toString('applicant_backdating_claimearlier')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 44",
        title: "Filling in the form on someone else's behalf",
        page: 45,
        items: [
          {
            type: "help",
            helpvalue: "If this form has been filled in by someone other than the person claiming, please tell us about this below"
          },
          {
            key: "applicant_completedby",
            title: "Has this form been filled in by someone other than the person claiming?",
            type: "radios"
          },
          {
            key: "applicant_completedby_reason",
            title: "Please tell us why",
            condition: conditions.toString('applicant_completedby_reason')
          },
          {
            key: "applicant_completedby_forename",
            title: "What is the first name of the person who filled in this form?",
            condition: conditions.toString('applicant_completedby_forename')
          },
          {
            key: "applicant_completedby_lastname",
            title: "What is the surname of the person who filled in this form?",
            condition: conditions.toString('applicant_completedby_lastname')
          },
          {
            key: "applicant_completedby_addressline1",
            title: "What's their address? Address Line 1",
            condition: conditions.toString('applicant_completedby_addressline1')
          },
          {
            key: "applicant_completedby_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_completedby_addressline2')
          },
          {
            key: "applicant_completedby_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_completedby_addressline3')
          },
          {
            key: "applicant_completedby_postcode",
            title: "Postcode of the person who filled in this form",
            condition: conditions.toString('applicant_completedby_postcode')
          },
          {
            key: "applicant_completedby_tel",
            title: "Daytime telephone number",
            condition: conditions.toString('applicant_completedby_tel')
          },
          {
            key: "applicant_completedby_relationship",
            title: "Relationship to the person claiming",
            condition: conditions.toString('applicant_completedby_relationship')
          },
          {
            key: "applicant_completedby_declaration",
            condition: conditions.toString('applicant_completedby_declaration')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 45",
        title: "Letter of Authority",
        page: 46,
        items: [
          {
            type: "help",
            helpvalue: "<p>If you want a relative, friend, neighbour or outside agency to be able to discuss your claim on your behalf, please tell us about this below.<br><br>You can cancel this letter of authority at any time by writing to Kirklees Council, Customer and Exchequer Services.</p>"
          },
          {
            key: "applicant_letterofauthority",
            title: "Do you want to give permission for someone else to discuss your claim on your behalf?",
            type: "radios"

          },
          {
            key: "applicant_letterofauthority_name",
            title: "Name of nominated person or agency",
            condition: conditions.toString('applicant_letterofauthority_name')
          },
          {
            key: "applicant_letterofauthority_addressline1",
            title: "Address line 1",
            condition: conditions.toString('applicant_letterofauthority_addressline1')
          },
          {
            key: "applicant_letterofauthority_addressline2",
            title: "Address line 2",
            condition: conditions.toString('applicant_letterofauthority_addressline2')
          },
          {
            key: "applicant_letterofauthority_addressline3",
            title: "Address line 3",
            condition: conditions.toString('applicant_letterofauthority_addressline3')
          },
          {
            key: "applicant_letterofauthority_postcode",
            title: "Their Postcode",
            condition: conditions.toString('applicant_letterofauthority_postcode')
          },
          {
            key: "applicant_letterofauthority_tel",
            title: "Nominee's daytime telephone number",
            condition: conditions.toString('applicant_letterofauthority_tel')
          },
          {
            key: "applicant_letterofauthority_relationship",
            title: "Nominee's relationship to you",
            condition: conditions.toString('applicant_letterofauthority_relationship')
          },
          {
            key: "applicant_letterofauthority_declaration",
            condition: conditions.toString('applicant_letterofauthority_declaration')
          }]
      },
      {
        type: "fieldset",
        condition: "model.page === 46",
        title: "Declaration",
        page: 47,
        items: [
          {
            type: "template",
            templateUrl: "modules/forms/scripts/services/forms/HB_CTR_FSM/templates/declaration.html"
          },
          "applicant_declaration",
          {
            key: "partner_declaration",
            condition: conditions.toString('partner_declaration')
          }]
      },
      {
        type: "section",
        condition: "areFormNavigationButtonsVisible()",
        items: [
          {
            type: "actions",
            htmlClass: "formPaginationButtons",
            items: [
              {
                type: "button",
                style: "btn-default",
                title: "Previous",
                onClick: "prev()"
              },
              {
                type: "button",
                style: "btn-primary",
                title: "Next",
                onClick: "next()"
              }]
          }]
      }];

    formUI.setForm(form);

    var mergedFormAndSchema = schemaFormHelperService.mergeFormAndSchema(form, $injector.get('HB_CTR_FSM_SCHEMA').getSchema());

    function getHiddenPages(model) {
      var hiddenPages = [];

      // the last element of the form is the section that with the prev/next buttons rather than a page
      var formLength = form.length - 1;

      for (var pageIndex = 0; pageIndex < formLength; pageIndex++) {
        if (schemaFormHelperService.isPageHidden(model, conditions, mergedFormAndSchema[pageIndex])) {
          var hiddenPageNumber = pageIndex + 1;
          hiddenPages.push(hiddenPageNumber);
        }
      }

      return hiddenPages;
    }

    // Retrieving the hidden pages is an expensive operation, that often returns the same results (because it is called
    // with the same model). As an optimisation, memoize the results, using a hash of the model as the memoization cache key
    formUI.getHiddenPages = _.memoize(getHiddenPages, function (model) {
      return formModelService.formFieldsHashCode(model);
    });

    return formUI;

  }
]);

'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'COV_FCA_FORM',
    [
      'FormUI',
      'coventryCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'COV_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        conditions,
        $window
      ) {

        var constants = $window.kerpCfa.getConstants('COV_FCA');

        var formUI = new FormUI();

        function periodicPaymentField(fieldName, options) {
          return {
            type: 'section',
            htmlClass: 'row',
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentField(fieldName, options, condition) {
          condition = condition || 'true';

          return {
            type: 'section',
            htmlClass: 'row',
            condition: condition,
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-7 col-sm-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-3 col-sm-4"
              }
            ]
          };
        }

        function paymentField(fieldName, overrides) {
          return angular.extend({
            key: fieldName,
            feedback: false
          }, overrides);
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var withholdFinancialDetailsAndPayFullCost = intro.provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = intro.provideFinancialDetails === 'arrangeMyOwn';
          var excludeNonResidentialCaretypes = intro.excludeNonResidentialCaretypes === true;
          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9];
          } else if (withholdFinancialDetailsAndArrangeOwnCare) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9, 10, 11];
          } else {
            hiddenPages = [];
          }

          if (excludeNonResidentialCaretypes) {
            hiddenPages = hiddenPages.concat([9]);
          }

          return hiddenPages;
        };

        formUI.setForm([
          {
            type: "section",
            condition: 'model.page === 1',
            page: 1,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html"
                  },
                ]
              },
              {
                type: "fieldset",
                title: 'People acting on your behalf',
                htmlClass: "col-md-12",
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/COV_FCA/templates/agent.html"
                  },
                  'agent.hasAppointee',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship for State Benefits gives a person the right to deal with the benefits of someone who cannot manage their own affairs because they\'re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasAppointee',
                    key: 'agent.appointeeDetails'
                  },
                  'agent.hasDeputy',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>You are someone’s deputy if they \'lack mental capacity\'. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasDeputy',
                    key: 'agent.deputyDetails'
                  },
                  'agent.hasPowerOfAttorney',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasPowerOfAttorney',
                    key: 'agent.powerOfAttorneyDetails'
                  },
                  'agent.isCompletedByAgent',
                  {
                    condition: 'model.agent.isCompletedByAgent',
                    key: 'agent.completedByDetails'
                  }
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: ['clientID']
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About the person receiving the service',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you_exclude-partner.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/COV_FCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved into a care home please provide your address BEFORE moving to residential care."
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  'person.details.nationalInsuranceNumber',
                  'person.details.nhsNumber',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  }
                ]
              },
              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails',

                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerStatus',
                    condition: 'model.person.intro.hasPartner',
                  },
                  {
                    key: 'person.intro.carersAllowanceIsEntitled',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/carersAllowanceEntitlement.html')
                  }
                ]
              },

              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                items: [
                  'partner.intro.alreadyReceivingCare',
                  {
                    key: 'partner.details.partnerReceivesResidentialCare',
                    condition: 'model.person.intro.partnerStatus === "spouse"',
                  },
                  'partner.details.sameAddress',
                  {
                    type: 'fieldset',
                    condition: 'model.partner.intro.alreadyReceivingCare',
                    items: [
                      'partner.details.title',
                      'partner.details.firstName',
                      'partner.details.middleName',
                      'partner.details.lastName',
                      'partner.details.homePhoneNumber',
                      'partner.details.mobilePhoneNumber',
                      'partner.details.email',
                      'partner.details.nationalInsuranceNumber',
                      'partner.details.nhsNumber',
                      'partner.details.dob',
                      {
                        key: 'partner.details.address',
                        condition: 'model.partner.details.sameAddress === false'
                      }
                    ]
                  },
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            title: 'People living with you',
            items: [
              {
                key: 'peopleLivingWithYou.hasDependentChildren',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/dependentChildrenDescription.html')
              },
              {
                key: 'peopleLivingWithYou.dependentChildren',
                condition: 'model.peopleLivingWithYou.hasDependentChildren',
                add: "Add Child",
                title: 'Dependent children details',
                validationMessage: 'Complete all required fields for at least one child'
              },
              {
                key: 'peopleLivingWithYou.hasOtherPeople',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/COV_FCA/templates/nonDependentsDescription.html')
              },
              {
                key: 'peopleLivingWithYou.otherPeople',
                condition: 'model.peopleLivingWithYou.hasOtherPeople',
                add: "Add Person",
                title: 'Details of other people living with you',
                validationMessage: 'Complete all required fields for at least one person'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro_excludes-partner.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },
                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?'
                      },
                      {
                        key: 'person.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      },

                      periodicPaymentField('person.income.retirementPension'),
                      periodicPaymentField('person.income.incomeSupportPayment'),
                      periodicPaymentField('person.income.incapacityBenefit'),
                      periodicPaymentField('person.income.employmentSupport'),
                      {
                        key: 'person.income.hasESAEnhancedDisabilityPremium',
                        condition: 'model.person.income.employmentSupport',
                        description: 'If you receive Enhanced Disability Premium you will find this listed as part of the breakdown within your most recent letter from the D.W.P. concerning your Employment Support Allowance payment. If you are not sure if you receive the Enhanced Disability Premium please answer Don\'t know. We can check this with the D.W.P. for you and amend your financial assessment appropriately.',
                      },
                      periodicPaymentField('person.income.severeDisablementAllowance'),
                      periodicPaymentField('person.income.jobSeekersAllowance'),
                      periodicPaymentField('person.income.industrialInjuriesBenefit'),
                      periodicPaymentField('person.income.rentalIncome'),
                      periodicPaymentField('person.income.workingTaxCredit'),
                      periodicPaymentField('person.income.childTaxCredit'),
                      periodicPaymentField('person.income.maintenance'),
                      periodicPaymentField('person.income.warPension', {
                        description: 'A War Pension payable to a former member of the armed forces will be fully disregarded.'
                      }),
                      periodicPaymentField('person.income.warWidowsPension', {
                        description: 'A disregard of £10 will be applied to this payment.'
                      }),
                      periodicPaymentField('person.income.warSpecialPayment', {
                        description: 'The supplementary element of a War Widow(er) Pension will be fully disregarded. The current amount from 7 April 2025 is £121.26.'
                      }),
                      periodicPaymentField('person.income.childBenefit'),
                      periodicPaymentField('person.income.carersAllowance'),

                      paymentField('person.income.attendanceAllowance', {
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),
                      paymentField('person.income.dlaCare', {
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      paymentField('person.income.pipDailyLiving', {
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      paymentField('person.income.mobilityAllowance'),
                      periodicPaymentField('person.income.pensionGuaranteeCredit'),
                      periodicPaymentField('person.income.pensionSavingsCredit'),
                      periodicPaymentField('person.income.charitableIncome'),
                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                      'person.income.ongoingApplications',
                      {
                        key: 'person.income.ongoingApplicationsInformation',
                        condition: 'model.person.income.ongoingApplications'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your capital',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro_excludes-partner.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in ALL accounts (see guidance below) where you are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint capital",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in ALL accounts (see guidance below) where you and someone else are both named account holders?',
                        description: 'Only include joint accounts where the capital is owned by more than one person. Accounts with additional names for access-only reasons are treated as single owner accounts.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 6',
            page: 6,
            title: 'Property/Land you own or rent',
            items: [
              'person.property.ownership',
              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              {
                key: 'person.property.value',
                description: "Input approximate value of the property if applying for residential/nursing care funding. If applying for a non-residential care service put 0 for the value.",
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you other than your main home.',
                validationMessage: 'Enter details of at least one property'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Sale or Transfer of property or capital',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
              },
              'deprivationOfAssets.hasDisposedOfAssets',
              {
                type: 'fieldset',
                items: [
                  'deprivationOfAssets.disposedAssets.assetValue',
                  'deprivationOfAssets.disposedAssets.dateOfDisposal',
                  'deprivationOfAssets.disposedAssets.assetsDisposed',
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12a_capital_transfers-gifting-description.html'
                  },
                  'deprivationOfAssets.disposedAssets.reasonForDisposal',
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12b_capital_transfers-gifting-reason.html'
                  },
                ],
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              'person.intro.excludeNonResidentialCaretypes',
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Household Expenditure',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro_excludes-partner.html'
              },
              fullWidthPeriodicPaymentField('person.expenses.mortgageLessHousing', {}, '!model.person.income.hasUniversalCredit'),
              fullWidthPeriodicPaymentField('person.expenses.mortgage', {}, 'model.person.income.hasUniversalCredit'),
              fullWidthPeriodicPaymentField('person.expenses.rentLessHousing', {}, '!model.person.income.hasUniversalCredit'),
              fullWidthPeriodicPaymentField('person.expenses.rent', {}, 'model.person.income.hasUniversalCredit'),
              fullWidthPeriodicPaymentField('person.expenses.ongoingPropertyExpenses', {
                description: "Ongoing property expenses include service charges, maintenance charges or ground rent not covered by benefits. These amounts will not automatically be included in the on-line calculation but will be considered by the Financial Assessments Team before the final client contribution is determined."
              }, '!model.person.income.hasUniversalCredit'),

              paymentField('person.expenses.councilTax'),
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/COV_FCA/templates/councilTaxDescription.html'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Disability-Related Expenses',
            items: [
              {
                type: 'fieldset',
                condition: 'model.person.intro.excludeNonResidentialCaretypes === false',
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/COV_FCA/templates/disabilityRelatedExpenditure.html"
                  },

                  'person.intro.propertyType',
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.fuel', {
                    description: 'Enter the cost of gas, electricity or oil used to heat your home. This will be compared with the average for the type of property you live in, with the average for the West Midlands. An allowance will be made where your actual cost exceeds the West Midlands average cost.'
                  }),
                  paymentField('person.expenses.disabilityRelated.gardening', {
                    description: 'Enter the cost of basic maintenance if you live alone and you are unable to maintain your garden yourself.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.domesticHelp', {
                    description: 'Enter the cost of professional help for cleaning, cooking or shopping services, where evidence exists of you receiving these service and no one else in the household can perform these tasks for you. Payments to close relatives and partners are not allowed.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.transport', {
                    description: 'Enter any transport costs which are needed because of your illness or disability. We will only consider these costs if you are not receiving Disability Living Allowance or Personal Independence  Payment Mobility Component.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.windowCleaner', {
                    description: 'Enter the cost of privately arranged window cleaning not provided by relatives.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.chiropodist'),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.waterRates', {
                    description: 'Enter the cost of metered water only. This will be compared with the average for the type of property you live in, with the average for Severn Trent customers. An allowance will be made where your actual cost exceeds the average for Severn Trent customers.'
                  }),
                  paymentField('person.expenses.disabilityRelated.washingPerWeek'),
                  paymentField('person.expenses.disabilityRelated.poweredBed', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),

                  paymentField('person.expenses.disabilityRelated.bedTurning', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.mobilityScooter', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),

                  paymentField('person.expenses.disabilityRelated.stairLift', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.hoistPurchase', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.manualWheelchairPurchase', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.poweredWheelchairPurchase', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.manualWheelchairHire', {
                    description: 'Enter the cost of any privately hired items that are for your sole use only.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.poweredWheelchairHire', {
                    description: 'Enter the cost of any privately hired items that are for your sole use only.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.specialClothes', {
                    description: 'Enter additional cost of clothing purchased due to weight loss/gain or excessive wear and tear caused by illness or disability. Include any additional cost of specialist footwear when compared to non-specialist.'
                  }),
                  {
                    key: 'person.expenses.disabilityRelated.specialClothesDescription',
                    condition: 'model.person.expenses.disabilityRelated.specialClothes',
                    description: 'Enter details of any specialist clothing or footwear'
                  },

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.equipment'),
                  {
                    key: 'person.expenses.disabilityRelated.equipmentDescription',
                    condition: 'model.person.expenses.disabilityRelated.equipment',
                    description: 'Enter details of any specialist equipment maintenance, repair, or insurance costs'
                  },

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.alarm', {
                    description: 'Enter the cost you pay unless it is included in your Housing Benefit payment or it is a Tele-care service provided by your Local Authority/Council.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.incontinenceAids', {
                    description: 'Enter the amount you spend on non NHS products.',
                  }),
                  {
                    key: 'person.expenses.disabilityRelated.incontinenceAidsDescription',
                    condition: 'model.person.expenses.disabilityRelated.incontinenceAids',
                    description: 'Enter any incontinence aids used'
                  },

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.additionalBedding', {
                    description: 'Enter the amount you spend on extra bedding due to wear and tear or incontinence, over and above anything provided to you by the NHS'
                  }),

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.creamsAndWipes', {
                    description: 'Enter the amount you spend on products required because of health condition.'
                  }),
                  {
                    key: 'person.expenses.disabilityRelated.creamsAndWipesDescription',
                    condition: 'model.person.expenses.disabilityRelated.creamsAndWipes',
                    description: 'Enter details of any creams and wipes used'
                  },

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.dietary', {
                    description: 'Enter the additional cost of specialist products (if higher than standard products) needed because of your health condition or disability, where medical evidence exists for your need.'
                  }),
                  {
                    key: 'person.expenses.disabilityRelated.dietaryDescription',
                    condition: 'model.person.expenses.disabilityRelated.dietary',
                    description: 'Enter details of special dietary costs'
                  },

                  paymentField('person.expenses.disabilityRelated.communicationEquipment', {
                    description: 'Enter the purchase price for any specialist communication products that are not provided to you by the NHS and they are needed due to a health condition or disability.'
                  }),

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.internetAccess', {
                    description: 'Enter the additional cost incurred due to a health condition or disability.',
                  }),

                  paymentField('person.expenses.disabilityRelated.holidayCarer', {
                    description: 'Enter the annual amount you pay for a carer if you are unable to go on holiday without assistance.'
                  }),

                  paymentField('person.expenses.disabilityRelated.framesAndWalkers', {
                    description: 'Enter the amount you paid for any items you have purchased directly yourself that were not provided to you by the NHS.'
                  }),

                  paymentField('person.expenses.disabilityRelated.poweredRecliningChair', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  'person.expenses.disabilityRelated.hasOtherDRE',
                  {
                    key: 'person.expenses.disabilityRelated.otherDRE',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/COV_FCA/templates/declaration.html"
              },
              'declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/COV_FCA/templates/costOfCareIntro.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('person.intro.excludeNonResidentialCaretypes'),
                    items: [
                      {
                        key: 'careAssessment.nonResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'COV_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.nonResidential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Respite',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('person.intro.excludeNonResidentialCaretypes'),
                    items: [
                      {
                        key: 'careAssessment.respite.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'COV_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.respite,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum respite care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.respite.workings']
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Residential, first 4 weeks",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'careAssessment.week1Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'COV_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.week1Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.week1Residential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Residential, weeks 5-12",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'careAssessment.week5Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'COV_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.week5Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.week5Residential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Residential, weeks 13 onward",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'careAssessment.week13Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'COV_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.week13Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.week13Residential.workings']
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);

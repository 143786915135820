'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'SOT_FCA_FORM',
    [
      'FormUI',
      'southamptonCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'fieldDefinitionService',
      'SOT_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        fieldDefinitionService,
        conditions,
        $window
      ) {

        var constants = $window.kerpCfa.getConstants('SOT_FCA');

        var formUI = new FormUI();

        function periodicPaymentField(fieldName, options) {
          return {
            type: 'section',
            htmlClass: 'row',
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentField(fieldName, options, condition) {
          condition = condition || 'true';

          return {
            type: 'section',
            htmlClass: 'row',
            condition: condition,
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-7 col-sm-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-3 col-sm-4"
              }
            ]
          };
        }

        function paymentField(fieldName, overrides) {
          return angular.extend({
            key: fieldName,
            feedback: false
          }, overrides);
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var income = (model.person || {}).income || {};
          var withholdFinancialDetailsAndPayFullCost = intro.provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = intro.provideFinancialDetails === 'arrangeMyOwn';

          var notInRecieptOfDiabilityBenefits = [income.attendanceAllowance, income.dlaCare, income.pipDailyLiving].every((level) => {
            return !(['high', 'medium', 'low'].includes(level));
          });

          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost) {
            hiddenPages = [4, 5, 6, 7, 8, 9, 10];
          } else if (withholdFinancialDetailsAndArrangeOwnCare) {
            hiddenPages = [4, 5, 6, 7, 8, 9, 10, 11, 12];
          } else {
            hiddenPages = [];
            if (notInRecieptOfDiabilityBenefits) {
              hiddenPages = [10];
            }
          }

          return hiddenPages;
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: 'row',
            items: [
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                items: [
                  {
                    key: 'hasClientID',
                    title: 'Do you have a Client ID number?',
                    description: 'If you have been asked to complete this form by Southampton City Council, your correspondence will show a reference number',
                  },
                  {
                    key: 'clientID',
                    condition: 'model.hasClientID'
                  },
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/nonSubmission.html',
                    condition: 'model.hasClientID === false'
                  },
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: 'row',
            items: [
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html'
                  },
                ]
              },
              {
                type: 'fieldset',
                title: 'People acting on your behalf',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/agentVideoGuidance.html'
                  },

                  'agent.hasAgent',
                  {
                    type: 'fieldset',
                    condition: 'model.agent.hasAgent',
                    items: [
                      'agent.hasAppointee',
                      {
                        type: "help",
                        helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship for State Benefits gives a person the right to deal with the benefits of someone who cannot manage their own affairs because they\'re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                      },
                      {
                        condition: 'model.agent.hasAppointee',
                        key: 'agent.appointeeDetails'
                      },
                      'agent.hasDeputy',
                      {
                        type: "help",
                        helpvalue: "<div class='alert alert-info html-view-remove'>You are someone’s deputy if they \'lack mental capacity\'. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                      },
                      {
                        condition: 'model.agent.hasDeputy',
                        key: 'agent.deputyDetails'
                      },
                      'agent.hasPowerOfAttorney',
                      {
                        type: "help",
                        helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                      },
                      {
                        condition: 'model.agent.hasPowerOfAttorney',
                        key: 'agent.powerOfAttorneyDetails'
                      },
                      {
                        key: 'agent.isCompletedByAgent',
                        condition: '!model.agent.hasAppointee && !model.agent.hasDeputy && !model.agent.hasPowerOfAttorney'
                      },
                      {
                        condition: 'model.agent.isCompletedByAgent',
                        key: 'agent.completedByDetails'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 3',
            page: 3,
            htmlClass: "row",
            items: [
              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About you',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved into a care home please put the address that you were living at before your move."
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.address.movedIn',
                  {
                    key: 'person.details.address.previousAddresses',
                    condition: conditions.toString('previousAddresses')
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  'person.details.nhsNumber',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  }
                ]
              },
              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails',
                  {
                    key: 'person.details.nationalInsuranceNumber',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },

                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SOT_FCA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerStatus',
                    condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.otherRecievesCarersAllowance',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"'
                  }
                ]
              },

              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                items: [
                  'partner.intro.alreadyReceivingCare',
                  {
                    key: 'partner.details.partnerReceivesResidentialCare',
                    condition: 'model.person.intro.partnerStatus === "spouse"',
                  },
                  'partner.details.sameAddress',
                  {
                    type: 'fieldset',
                    condition: 'model.partner.details.sameAddress === true',
                    items: [
                      'partner.details.firstName',
                      'partner.details.middleName',
                      'partner.details.lastName',
                      'partner.details.homePhoneNumber',
                      'partner.details.mobilePhoneNumber',
                      'partner.details.email',
                      'partner.details.dob',
                      {
                        key: 'partner.intro.discloseFinances',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DO_FCA/templates/partnersFinancesDescription.html')
                      },
                      {
                        key: 'partner.details.nationalInsuranceNumber',
                        condition: 'model.partner.intro.discloseFinances'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            title: 'People living with you',
            items: [
              'livesAlone',
              {
                type: 'fieldset',
                condition: 'model.livesAlone === false',
                items: [
                  {
                    key: 'peopleLivingWithYou.hasDependentChildren',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SOT_FCA/templates/dependentChildrenDescription.html')
                  },
                  {
                    key: 'peopleLivingWithYou.dependentChildren',
                    condition: 'model.peopleLivingWithYou.hasDependentChildren',
                    add: "Add Child",
                    title: 'Dependent children details',
                    validationMessage: 'Complete all required fields for at least one child'
                  },
                  {
                    key: 'peopleLivingWithYou.hasOtherPeople',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/SOT_FCA/templates/nonDependentsDescription.html')
                  },
                  {
                    key: 'peopleLivingWithYou.otherPeople',
                    condition: 'model.peopleLivingWithYou.hasOtherPeople',
                    add: "Add Person",
                    title: 'Details of other people living with you',
                    validationMessage: 'Complete all required fields for at least one person'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      periodicPaymentField('person.income.retirementPension'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.incomeSupportPayment' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.incapacityBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.employmentSupport' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.jobSeekersAllowance' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.universalCredit' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      {
                        type: 'template',
                        templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/universalCredit.html"
                      },

                      periodicPaymentField('person.income.industrialInjuriesBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.rentalIncome' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.workingTaxCredit' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.childTaxCredit' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.maintenance'),
                      periodicPaymentField('person.income.warPension', {
                        description: 'A War Pension payable to a former member of the armed forces will be fully disregarded.'
                      }),
                      periodicPaymentField('person.income.warWidowsPension'),
                      periodicPaymentField('person.income.warSpecialPayment', {
                        description: 'The supplementary element of a War Widow(er) Pension will be fully disregarded. The current amount from 7 April 2025 is £121.26.'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.childBenefit' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.carersAllowance'),

                      {
                        type: 'template',
                        templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/permCareDisabilityBenefits.html',
                      },

                      paymentField('person.income.attendanceAllowance', {
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),
                      paymentField('person.income.dlaCare', {
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      paymentField('person.income.pipDailyLiving', {
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      paymentField('person.income.mobilityAllowance'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.pensionGuaranteeCredit' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({ key: 'person.income.pensionSavingsCredit' }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.charitableIncome'),
                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                      'person.details.inEducation',
                      {
                        key: 'person.details.educationDetails',
                        condition: 'model.person.details.inEducation'
                      },
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income and benefits",
                    htmlClass: "col-md-6",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    items: [
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'partner.income.hasWage',
                        title: 'Is your partner currently employed?'
                      },
                      {
                        key: 'partner.income.wages',
                        condition: 'model.partner.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'partner.income.hasPrivatePension',
                        title: 'Is your partner in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'partner.income.privatePensions',
                        condition: 'model.partner.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      periodicPaymentField('partner.income.retirementPension'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.incomeSupportPayment',
                        condition: '!model.person.income.incomeSupportPaymentIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.incapacityBenefit'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.employmentSupport',
                        condition: '!model.person.income.employmentSupportIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.jobSeekersAllowance',
                        condition: '!model.person.income.jobSeekersAllowanceIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.universalCredit',
                        condition: '!model.person.income.universalCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),


                      periodicPaymentField('partner.income.industrialInjuriesBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.rentalIncome',
                        condition: '!model.person.income.rentalIncomeIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.workingTaxCredit',
                        condition: '!model.person.income.workingTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.childTaxCredit',
                        condition: '!model.person.income.childTaxCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.maintenance'),
                      periodicPaymentField('partner.income.warPension'),
                      periodicPaymentField('partner.income.warWidowsPension'),
                      periodicPaymentField('partner.income.warSpecialPayment'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.childBenefit',
                        condition: '!model.person.income.childBenefitIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.carersAllowance'),

                      paymentField('partner.income.attendanceAllowance', {
                        condition: conditions.toString('partner.income.attendanceAllowance')
                      }),
                      paymentField('partner.income.dlaCare', {
                        condition: conditions.toString('partner.income.dlaCare')
                      }),
                      paymentField('partner.income.pipDailyLiving', {
                        condition: conditions.toString('partner.income.pipDailyLiving')
                      }),

                      paymentField('partner.income.mobilityAllowance'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionGuaranteeCredit',
                        condition: '!model.person.income.pensionGuaranteeCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({
                        key: 'partner.income.pensionSavingsCredit',
                        condition: '!model.person.income.pensionSavingsCreditIsJoint'
                      }, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.charitableIncome'),

                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {

            type: 'section',
            condition: 'model.page === 6',
            page: 6,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your money or investments',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasSavingsInvestments',
                        title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?',
                        description: 'Only include joint accounts where the amount is owned by more than one person. Accounts with additional names for access-only reasons are treated as single owner accounts.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsInvestments',
                        add: "Add account",
                        title: "Give details of your partner's savings and investments",
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                condition: '!(model.person.capital.hasSavingsInvestments || model.partner.capital.hasSavingsInvestments || model.joint.capital.hasSavingsInvestments)',
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-12",
                    items: [
                      'person.bankAccount.benefitsPaidIntoBankAccount',
                      {
                        type: "fieldset",
                        condition: 'model.person.bankAccount.benefitsPaidIntoBankAccount === true',
                        items: [
                          'person.bankAccount.name',
                          'person.bankAccount.accountNo',
                          'person.bankAccount.accountHolder',
                          'person.bankAccount.relationship',
                          'person.bankAccount.amount',
                        ]
                      },
                      {
                        type: "fieldset",
                        condition: 'model.person.bankAccount.benefitsPaidIntoBankAccount === false',
                        items: [
                          {
                            key: 'person.bankAccount.benefitsPaidOther',
                            description: "If you indicate you don't have a bank or building society account, we need to know how you receive any income or benefit payments - for example if a family member receives them on your behalf."
                          }
                        ]
                      }
                    ]
                  }
                ]

              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasShareholdings',
                        title: 'Is your partner the sole owner of any company shares?'
                      },
                      {
                        key: 'partner.capital.shareholdings',
                        condition: 'model.partner.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: "Give details of your partner's shareholdings",
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              },
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Property/Land you own or rent',
            items: [
              'person.property.ownership',
              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              'person.property.propertyType',
              {
                key: 'person.property.value',
                description: "Input approximate value of the property if applying for residential/nursing care funding. If applying for a non-residential care service put 0 for the value.",
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you in this country or abroad other than your main home.',
                validationMessage: 'Enter details of at least one property'
              },
              'person.property.additionalInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Sale or Transfer of property, money or investments',
            items: [
              {
                type: 'template',
                htmlClass: 'divTitle',
                templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_12_capital_transfers-gifting-intro.html'
              },
              'deprivationOfAssets.hasDisposedOfAssets',
              {
                key: 'deprivationOfAssets.disposedAssets.assetValue',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.dateOfDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.assetsDisposed',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/deprivationOfAssetsDescription.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.reasonForDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/deprivationOfAssetsReason.html',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Household Expenditure',
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-8",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro.html'
                      },
                      fullWidthPeriodicPaymentField('person.expenses.mortgageLessHousing', {}, '!model.person.income.universalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.mortgage', {}, 'model.person.income.universalCredit'),

                      fullWidthPeriodicPaymentField('person.expenses.rentLessHousing', {}, '!model.person.income.universalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.rent', {}, 'model.person.income.universalCredit'),

                      paymentField('person.expenses.councilTax'),
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/councilTaxDescription.html'
                      },

                      fullWidthPeriodicPaymentField('person.expenses.endowmentInsurance'),

                      fullWidthPeriodicPaymentField('person.expenses.waterRates'),

                      fullWidthPeriodicPaymentField('person.expenses.gas'),

                      fullWidthPeriodicPaymentField('person.expenses.electric'),

                      'person.expenses.landlineTelephone',

                      fullWidthPeriodicPaymentField('person.expenses.serviceCharge'),

                      fullWidthPeriodicPaymentField('person.expenses.groundRent'),

                      fullWidthPeriodicPaymentField('person.expenses.homeInsurance'),

                      fullWidthPeriodicPaymentField('person.expenses.contentsInsurance'),

                      fullWidthPeriodicPaymentField('person.expenses.compulsoryCourtPayments'),

                      fullWidthPeriodicPaymentField('person.expenses.communityAlarm'),

                      'person.expenses.hasAdditionalExpenses',

                      {
                        condition: 'model.person.expenses.hasAdditionalExpenses',
                        key: 'person.expenses.otherExpenses'
                      }

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Disability-Related Expenses',
            items: [
              'person.intro.excludeNonResidentialCaretypes',
              {
                type: 'fieldset',
                condition: 'model.person.intro.excludeNonResidentialCaretypes === false',
                items: [

                  {
                    type: 'fieldset',
                    title: 'Care alarm / Telecare',
                    items: [
                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.alarm', {
                        description: 'Please do not include the cost of call-outs or services covered by Housing Benefit. You will need to supply invoices if your service is not provided by Southampton City Council.'
                      }),
                    ]
                  },

                  {
                    type: 'fieldset',
                    title: 'Chiropody',
                    items: [
                      {
                        key: 'person.expenses.disabilityRelated.chiropodySessions',
                        description: 'Please enter the number of chiropody sessions you have in a year'
                      },
                      {
                        key: 'person.expenses.disabilityRelated.chiropodyCost',
                        description: 'Please enter the cost of these sessions. If the cost varies per session, please enter the lower value.',
                        condition: 'model.person.expenses.disabilityRelated.chiropodySessions > 0'
                      }
                    ]
                  },

                  {
                    type: 'fieldset',
                    title: 'Help around the home',
                    items: [

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.domesticHelp', {
                        description: 'Enter the cost of professional help for cleaning where evidence exists of you receiving these services and no one else in the household can perform these tasks for you. Payments to close relatives and partners are not allowed.'
                      }),

                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.shoppingHelp', {
                        description: 'Enter the cost of professional help for shopping where evidence exists of you receiving these services and no one else in the household can perform these tasks for you. Payments to close relatives and partners are not allowed.'
                      }),

                      paymentField('person.expenses.disabilityRelated.gardening', {
                        description: 'Gardening expenses are intended to cover basic tidying/clearing only, to ensure the garden is accessible and safe. Please do not include gardening expenses if you live in a Southampton City Council housing complex where garden maintenance is provided without additional charge.'
                      }),
                    ]
                  },

                  {
                    type: 'fieldset',
                    title: 'Energy',
                    items: [
                      fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.fuel', {
                        description: 'Enter the cost of gas, electricity or oil used to heat your home. We will compare your heating costs with regional averages, based on your household, and consider the difference as a disability related expense.'
                      }),
                    ]
                  },

                  {
                    type: 'fieldset',
                    title: 'Laundry',
                    items: [
                      paymentField('person.expenses.disabilityRelated.washingPerWeek', {
                        description: 'Please do not include laundry loads for other members of the household'
                      }),

                    ]
                  },

                  {
                    type: 'fieldset',
                    title: 'Equipment Weekly Costs ',
                    items: [

                      'person.expenses.disabilityRelated.manualWheelChairHireOrPurchase',

                      paymentField('person.expenses.disabilityRelated.manualWheelchairPurchase', {
                        description: 'Enter the cost of any privately purchased items that are for your sole use only.',
                        condition: 'model.person.expenses.disabilityRelated.manualWheelChairHireOrPurchase === "purchase"'
                      }),

                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.manualWheelChairHireOrPurchase === "hire"',
                        items: [
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.manualWheelchairHire', {
                            description: 'Enter the cost of any privately hired items that are for your sole use only.',
                          }),
                        ]
                      },

                      'person.expenses.disabilityRelated.poweredWheelChairHireOrPurchase',

                      paymentField('person.expenses.disabilityRelated.poweredWheelchairPurchase', {
                        description: 'Enter the cost of any privately purchased items that are for your sole use only.',
                        condition: 'model.person.expenses.disabilityRelated.poweredWheelChairHireOrPurchase === "purchase"'
                      }),

                      {
                        type: 'fieldset',
                        condition: 'model.person.expenses.disabilityRelated.poweredWheelChairHireOrPurchase === "hire"',
                        items: [
                          fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.poweredWheelchairHire', {
                            description: 'Enter the cost of any privately hired items that are for your sole use only.',
                          }),
                        ]
                      }

                    ]
                  },

                  {
                    type: 'fieldset',
                    title: 'Other Disability-Related Expenses',
                    items: [
                      {
                        key: 'person.expenses.disabilityRelated.hasOtherDRE',
                        description: 'Other disability-related expenses may include additional equipment, installation and maintenance costs, or other expenses incurred in order for you to live independently.'
                      },

                      {
                        key: 'person.expenses.disabilityRelated.otherDRE',
                        condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                      },

                    ]
                  },

                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/declaration-intro.html"
              },
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/declaration-financial-assessment.html"
              },
              'declarations.financialAssessmentDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/declaration-changes.html"
              },
              'declarations.financialChangesDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/declaration-failure-to-pay.html"
              },
              'declarations.failureToPayDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/declaration-deprivation.html"
              },
              'declarations.deprivationDeclaration',
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/SOT_FCA/templates/declaration-evidence.html"
              },
              'declarations.declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 13',
            page: 13,
            title: 'Cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/costOfCareIntro.html'
              },

              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/SOT_FCA/templates/partnerFinances.html',
                condition: 'model.partner.intro.discloseFinances'
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('person.intro.excludeNonResidentialCaretypes'),
                    items: [
                      {
                        key: 'careAssessment.nonResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SOT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.nonResidential.workings']
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Short Stay Residential",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.shortStayResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SOT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.shortStayResidential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.shortStayResidential.workings']
                      }
                    ]
                  },
                ],
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Permanent Residential Weeks 1-4",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.permanentResidentialWeek1.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SOT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.permanentResidentialWeek1,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.permanentResidentialWeek1.workings']
                      }
                    ]
                  },
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Permanent Residential Weeks 5-12",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.permanentResidentialWeek5.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SOT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.permanentResidentialWeek5,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.permanentResidentialWeek5.workings']
                      }
                    ]
                  },
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: "Permanent Residential Weeks 13+",
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.permanentResidentialWeek13.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'SOT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.permanentResidentialWeek13,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum residential care contribution'
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.permanentResidentialWeek13.workings']
                      }
                    ]
                  },
                ]
              }
            ]
          },
          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
